export default {
  getSeatingUpdatePayload: (state) => () => {
    const blocked = state.draftSeating.blocked.map((seat) => ({ action: 'block', seat }));
    const unblocked = state.draftSeating.unblocked.map((seat) => ({ action: 'remove', seat }));
    const categorised = state.draftSeating.categorised.map((seat) => ({
      action: 'set_block_category',
      seat: seat.seatId,
      block_category: seat.catId || null,
    }));

    const assigned = state.draftSeating.pendingAssignments
      .filter((assignment) => assignment.newSeatId !== assignment.seatId && assignment.newSeatId)
      .map((assignment) => ({
        action: 'assign',
        seat: assignment.newSeatId,
        privilege_id: assignment.privilegeId,
      }));
    const unassigned = state.draftSeating.pendingAssignments
      .filter((assignment) => assignment.newSeatId !== assignment.seatId && assignment.seatId)
      .map((assignment) => ({ action: 'remove', seat: assignment.seatId }));
    return unblocked.concat(unassigned).concat(blocked).concat(assigned).concat(categorised);
  },
  areSeatingChanges: (state) =>
    state.draftSeating.unblocked.length > 0 ||
    state.draftSeating.blocked.length > 0 ||
    state.draftSeating.categorised.length > 0 ||
    state.draftSeating.pendingAssignments.filter((a) => a.seatId !== a.newSeatId).length > 0,
  isSeatingChangeValid: (state) =>
    !Object.values(state.draftSeating.pTypes).some((pt) => pt.sourceBlocks + pt.blocksChange > pt.maxBlocks),
  seatingMap: (state) => {
    if (!state.seating) return {};

    return Object.entries(state.seating.sections).reduce((seatingMap, [_, sec]) => {
      if (sec.type === 'seat_section') {
        sec.rows.forEach((row) => {
          row.seats.forEach((seat) => {
            if (seat.id) {
              seatingMap[seat.id] = seat;
            }
          });
        });
      }
      return seatingMap;
    }, {});
  },
};
