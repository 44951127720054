import EventsOverview from '@/router/events/EventsOverview';
import Create from '@/router/events/Create';
import Event from '@/router/events/event/index';

const Events = () => import('@/views/events/index.vue');

export default {
  path: '/events',
  component: Events,
  children: [EventsOverview, Event, Create],
};
