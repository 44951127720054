import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { parseRequestQueryParams } from '@getprotocollab/get-pal';
import { pageLimit } from '@/helpers/constants';
import helpers from '@/helpers';

function actions(app) {
  return {
    async getEventsBatch(
      _,
      {
        search = undefined,
        page = 1,
        ordering = undefined,
        publish_state = undefined,
        is_template = false,
        before = undefined,
        after = undefined,
        organization = undefined,
        template = undefined,
        attendee = undefined,
      } = {},
    ) {
      const q = parseRequestQueryParams({
        search,
        offset: (page - 1) * pageLimit,
        before: before === 'now' ? moment().toISOString() : undefined,
        after: after === 'now' ? moment().toISOString() : undefined,
        publish_state,
        limit: pageLimit,
        ordering,
        is_template,
        organization,
        template,
        attendee,
      });
      const { data: { results, count, next } = {} } = await app.config.globalProperties.axios.get(`events/${q}`);
      return { results, count, next };
    },
    async getEventSales({ commit }, { eventId, eventSlug, shop }) {
      const query = parseRequestQueryParams({
        event_id: eventId,
        event_slug: eventSlug,
        shop,
      });
      const { data: sales } = await app.config.globalProperties.axios.get(`reports/event-sales/${query}`);
      commit('store_event_sales', sales);
      return sales;
    },
    async getEventCheckins({ commit, state }) {
      const { id } = state.current_event;
      const query = parseRequestQueryParams({
        event_id: id,
      });
      const { data: checkins } = await app.config.globalProperties.axios.get(`reports/event-checkins/${query}`);
      commit('store_event_checkins', checkins);
    },
    async getEventData({ commit }, slug) {
      const eventResponse = await app.config.globalProperties.axios.get(`events/${slug}/`);
      const event = eventResponse.data;
      commit('set_current_event', event);
    },

    async getTemplateStatus({ commit }, slug) {
      const response = await app.config.globalProperties.axios.get(`/events/${slug}/sync/`);
      commit('set_publish_state_event', response.data.publish_state);
      return response.data;
    },

    async getEventTiers({ commit }, eventId) {
      const query = parseRequestQueryParams({
        event_id: eventId,
      });
      const { data } = await app.config.globalProperties.axios.get(`privilege-type-tiers/${query}`);
      commit('store_event_tiers', data.results);
    },

    async getPrivilegeTypes({ state, commit }) {
      const { id } = state.current_event;
      const query = parseRequestQueryParams({
        event_id: id,
      });
      const privileges = await helpers.getAllPages(app.config.globalProperties.axios, `privilege-types/${query}`);
      commit('store_event_privtypes', privileges);
    },

    async executePrivilegeTypes(_, payload) {
      await app.config.globalProperties.axios.post('/privileges/execute/', { privilege_ids: payload });
    },

    async unExecutePrivilegeTypes(_, payload) {
      await app.config.globalProperties.axios.post('/privileges/unexecute/', { privilege_ids: payload });
    },

    async createPrivilegeType({ dispatch }, data) {
      await app.config.globalProperties.axios.post('privilege-types/', data);
      await dispatch('getPrivilegeTypes');
    },

    async updateCurrentEvent({ commit }, event) {
      const response = await app.config.globalProperties.axios.patch(`/events/${event.id}/`, event);
      commit('set_current_event', response.data);
      return response;
    },

    async getEventScanning(_, slug) {
      const response = await app.config.globalProperties.axios.get(`/events/${slug}/scanning/`);
      return response;
    },
    async updateEventScanning(_, { slug, profiles }) {
      const response = await app.config.globalProperties.axios.patch(`/events/${slug}/scanning/`, {
        scanning_profiles: profiles,
      });
      return response;
    },
    async getEventPrivilegeTypesMinified({ state }) {
      const { id } = state.current_event;
      const query = parseRequestQueryParams({
        event_id: id,
        payload: 'minimal',
      });
      const ptypes = await helpers.getAllPages(app.config.globalProperties.axios, `privilege-types/${query}`);
      return ptypes;
    },
  };
}

export default actions;
