export default {
  ticketeersById: (state) =>
    state.ticketeers.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {}),
  getPermission: (state) => (permission) => {
    if (!state.current_organization) return null;
    return state.current_organization.permissions.find((p) => p === permission);
  },
  getTicketeersPermission: (state) => (permission) => {
    if (!state.ticketeers) return null;
    return state.ticketeers.some((ticketeer) => ticketeer.permissions.find((p) => p === permission));
  },
  getTicketeerPermission: (_, getters) => (ticketeerId, permission) => {
    const ticketeer = getters.ticketeersById[ticketeerId];
    if (!ticketeer) return false;
    return !!ticketeer.permissions.find((p) => p === permission);
  },
  currentEventOrganization: (state, _, rootState) => {
    const currentEvent = rootState.Events.current_event;
    if (!currentEvent) return null;
    return state.current_event_organization;
  },
};
