const Templates = () => import('@/views/templates/index.vue');
const TemplatesOverview = () => import('@/views/templates/TemplatesOverview.vue');

export default {
  path: '/templates',
  component: Templates,
  children: [
    {
      path: '',
      name: 'templates-overview',
      component: TemplatesOverview,
      meta: { auth: true, org: true },
    },
  ],
};
