const enhanceSeating = (seating) => {
  seating.sections = seating.sections.reduce((sections, sec) => {
    sec.subsections.forEach((sub) => {
      if (sub.type === 'seat_section') {
        sub.rows.forEach((row) => {
          row.seats.forEach((seat) => {
            if (seat.type === 'seat') {
              seat.inDraft = false;
              seat.row = row.number;
              seat.initCategory = seat.block_category ? { ...seat.block_category } : null;
              seat.section = sec.name;
              seat.subsection = sub.name;
              seat.seat = seat.number;
            }
          });
        });
      }
      sections[`${sec.name}-${sub.name}`] = { ...sub, section_name: sec.name };
    });
    return sections;
  }, {});
  return seating;
};

function actions(app) {
  return {
    async refreshSeating({ dispatch }) {
      await Promise.all([
        dispatch('Events/getPrivilegeTypes', null, { root: true }),
        dispatch('getSeating'),
        dispatch('initializeDraftSeating'),
      ]);
    },
    async getSeating({ commit, rootState }) {
      const { id } = rootState.Events.current_event.seating || {};
      if (!id) return;
      const response = await app.config.globalProperties.axios.get(`/seating/${id}/`);

      commit('set_seating', enhanceSeating(response.data));
    },
    async getFilteredSeating(
      { commit, rootState },
      {
        filter_privilege_types,
        seats_in_local_cart,
        allowed_to_sell_from_blocks,
        edited_seats = [],
        edited_block_category = null,
        edited_pool = '',
      },
    ) {
      const { id } = rootState.Events.current_event.seating || {};

      if (!id) return;
      const response = await app.config.globalProperties.axios.get(`/seating/${id}/`);

      // Filter out any sections that don't have seats that contain the filter_privilege_types,
      // filter_privilege_types is an array of ids
      const filteredSections = response.data.sections.filter((section) =>
        section.subsections.some((subsection) => {
          if (!subsection.rows) return false;
          return subsection.rows.some((row) =>
            row.seats.some((seat) => filter_privilege_types.includes(seat.rank_ptype_id)),
          );
        }),
      );

      // Filter out subsections that don't have seats that contain the filter_privilege_types
      filteredSections.forEach((section) => {
        section.subsections = section.subsections.filter(
          (subsection) =>
            subsection.type !== 'standing_section' &&
            subsection.rows.some((row) =>
              row.seats.some((seat) => filter_privilege_types.includes(seat.rank_ptype_id)),
            ),
        );
      });

      // Parser adds properties used in cashier view
      const parsedSections = filteredSections.map((section) => {
        const parsedSubsections = section.subsections.map((subsection) => {
          if (!subsection.rows) return subsection;
          const parsedRows = subsection.rows.map((row) => {
            const parsedSeats = row.seats.map((seat) => {
              // disable seats with irrelevant privilege types
              if (!filter_privilege_types.includes(seat.rank_ptype_id)) {
                seat.disabled = true;
                return seat;
              }

              // set property for seats that are in the local cart
              if (seats_in_local_cart.includes(seat.id)) seat.in_local_cart = true;

              // filters for editing seats in the cashier chart
              if (edited_seats.length) {
                // disable seats that are NOT the same category as the edited_seats so they can not be selected
                if (
                  // edited seat is from available so disable all blocked seats
                  edited_pool === 'available' &&
                  !(seat.state === 'available' && seat.blocked === false && seat.block_category == null)
                ) {
                  seat.disabled = true;
                } else if (
                  // edited seat is in UNCATEGORISED block, disable everything else but UNCATEGORISED
                  edited_pool === 'locked' &&
                  !(seat.state === 'blocked' && seat.blocked === true && seat.block_category === null)
                ) {
                  seat.disabled = true;
                } else if (
                  // edited seat is in block category, disable everything else but this category
                  edited_pool !== 'available' &&
                  edited_pool !== 'locked' &&
                  !(seat.block_category !== null && seat.block_category.id === edited_block_category)
                ) {
                  seat.disabled = true;
                }
                if (edited_seats.includes(seat.id)) {
                  seat.disabled = false;
                  // state property is changed here so edited seats can use the same styling as in seat picker
                  seat.state = edited_pool === 'available' ? 'available' : 'blocked';
                  seat.is_edited = true;
                }
              }

              // disable locked seats if the user does not have the permission to sell from blocks
              if (seat.blocked && !seat.in_local_cart && !allowed_to_sell_from_blocks) {
                seat.disabled = true;
                return seat;
              }

              return seat;
            });
            row.seats = parsedSeats;
            return row;
          });
          subsection.rows = parsedRows;
          return subsection;
        });
        section.subsections = parsedSubsections;
        return section;
      });

      commit('set_seating', enhanceSeating({ ...response.data, sections: parsedSections }));
    },
    cleanUpSeating({ commit }) {
      commit('reset_draftSeating');
      commit('store_draftSeating_pTypes', {});
      commit('set_seating', null);
      commit('set_mode', 'default');
    },
    initializeDraftSeating({ commit, rootGetters }) {
      const mappedPTypes = rootGetters['Events/seatedPTypesArray'].reduce(
        (acc, { id, stats: { total, blocks, available }, name }) => {
          acc[id] = {
            total,
            blocksChange: 0,
            sourceBlocks: blocks,
            maxBlocks: available + blocks,
            name,
          };
          return acc;
        },
        {},
      );
      commit('reset_draftSeating');
      commit('store_draftSeating_pTypes', mappedPTypes);
    },
    async applyDraftSeating({ getters, rootState }) {
      const { id } = rootState.Events.current_event.seating || {};
      if (!id) return;
      const payload = getters.getSeatingUpdatePayload();
      if (!payload.length) return;
      await app.config.globalProperties.axios.post(`/seating/${id}/batch-edit/`, payload);
    },
  };
}

export default actions;
