export default {
  set_tasks(state, tasks) {
    state.pending_tasks = { ...tasks };
  },
  remove_task(state, uuid) {
    const tasks = { ...state.pending_tasks };
    if (tasks[uuid]) delete tasks[uuid];
    state.pending_tasks = tasks;
  },
  add_task(state, task) {
    const tasks = { ...state.pending_tasks };
    tasks[task.uuid] = task;
    state.pending_tasks = tasks;
  },
};
