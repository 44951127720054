{
  "countries": {
    "selectCountry": "Select country",
    "AF": "Afghanistan",
    "AX": "Åland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia (Plurinational State of)",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Cabo Verde",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo (Democratic Republic of the)",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Côte d'Ivoire",
    "HR": "Croatia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and McDonald Islands",
    "VA": "Holy See",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran (Islamic Republic of)",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea (Democratic People's Republic of)",
    "KR": "Korea (Republic of)",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "Macedonia (the former Yugoslav Republic of)",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia (Federated States of)",
    "MD": "Moldova (Republic of)",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestine, State of",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Romania",
    "RU": "Russian Federation",
    "RW": "Rwanda",
    "BL": "Saint Barthélemy",
    "SH": "Saint Helena, Ascension and Tristan da Cunha",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin (French part)",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten (Dutch part)",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan, Province of China",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom of Great Britain and Northern Ireland",
    "US": "United States of America",
    "UM": "United States Minor Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela (Bolivarian Republic of)",
    "VN": "Viet Nam",
    "VG": "Virgin Islands (British)",
    "VI": "Virgin Islands (U.S.)",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  },
  "placeholders": {
    "dateTime": "dd-mm-yyyy hh:mm",
    "date": "dd-mm-yyyy",
    "time": "hh:mm",
    "search": "검색...",
    "searchCountryCode": "Search for country code",
    "loading": "로딩...",
    "email": "example{'@'}mail.org",
    "uploadImagePlaceholder": "사진 파일을 선택하거나 이곳에 드래그하여 놓으세요.",
    "dropImagePlaceholder": "사진을 이곳에 드래그하여 놓으세요",
    "selectOrganisationMessage": "행사업체를 선택하십시오",
    "selectGate": "Search or select gate...",
    "selectRole": "Select role",
    "selectLocale": "Select language",
    "selectTicketeer": "Select ticketeer",
    "phoneNumber": "e.g. +141566573212"
  },
  "intervals": {
    "hour": "Per hour",
    "day": "Per day",
    "week": "Per week",
    "month": "Per month",
    "year": "Per year"
  },
  "permissions": {
    "noPermissions": "적절한 권한이 없습니다.",
    "contactAdministrator": "권한을 받기 위해 관리자에게 요청하세요.",
    "insufficientPermissions": "권한이 부족합니다."
  },
  "notifications": {
    "saveSuccess": "저장 성공",
    "savePublishSuccess": "저장과 게시 성공",
    "removeSuccess": "삭제 성공",
    "saveFail": "저장 실패",
    "removeFail": "삭제 실패",
    "cashierCartExpired": "Your cart has expired due to inactivity. Please reload the page to start over.",
    "cartUpdate": {
      "edit": {
        "success": "The cart has been updated successfully.",
        "fail": "Failed to update the cart."
      },
      "add": {
        "success": "The ticket(s) have been added successfully.",
        "fail": "Failed to add ticket(s)."
      },
      "remove": {
        "success": "The ticket(s) have been removed successfully.",
        "fail": "Failed to remove ticket(s)."
      }
    },
    "genericError": "에러",
    "successUpdate": "Successfully updated",
    "successEmail": "Email sent",
    "successShared": "Ticket shared",
    "successDownload": "File downloaded",
    "successPassword": "Password is successfully reset",
    "copiedToClipboard": "클립보드에 복사되었습니다.",
    "copyFailed": "Failed to copy to your clipboard",
    "invitationSent": "Invitation sent",
    "notEnoughPrivs": "오류: 티켓 수량이 부족하여 티켓을 차단하지 못하였습니다. 티켓 수량을 늘려 보세요.",
    "unSavedAlert": {
      "title": "저장된 내용을 변경하지 않음",
      "description": "변경된 내용을 저장하지 않고 종료하시겠습니까?"
    },
    "TicketKindExists": "티켓 풀 안에 티켓이 있기 때문에 해당 티켓 풀을 제거할 수 없습니다.",
    "TicketKindExistsInShop": "샵 안에 티켓이 있기 때문에 해당 샵을 제거할 수 없습니다.",
    "anonymizeUserSuccess": "The account data has been anonymized.",
    "anonymizeUserFail": "An error occurred while removing the account, the user is probably in the process of purchasing a ticket. Please try again later.",
    "feature_flag": "This feature is available for users with 'Tester' permissions only.",
    "tasks": {
      "taskLabel": "Task:",
      "stateLabel": "State:",
      "states": {
        "processing": "Processing",
        "not_started": "Not started",
        "failed": "Failed",
        "completed": "Completed"
      },
      "event_copy": {
        "title": "Copy event",
        "taskDescription": "Copying {title}",
        "link": "Go to the new event"
      },
      "shop_copy": {
        "title": "Copy Shop",
        "taskDescription": "Copying Shop {title}"
      },
      "template_generate": {
        "title": "Generating event",
        "taskDescription": "Generating from template:",
        "link": "Go to the new event"
      },
      "template_copy": {
        "title": "Copy template",
        "taskDescription": "Copying Template {title}"
      }
    }
  },
  "navigation": {
    "home": "홈",
    "events": "이벤트",
    "customers": "Customers",
    "upcoming": "다가오는",
    "past": "과거의",
    "draft": "초안",
    "reports": "리포트",
    "checkIns": "체크인",
    "sales": "판매",
    "exports": "Exports",
    "manage": "관리",
    "blocks": "차단",
    "general": "기본 세부정보의 관리",
    "eventMarketing": "마케팅",
    "eventAdvanced": "Advanced",
    "eventBlockchain": "Blockchain",
    "resaleFees": "Resale fees",
    "scanning": "스캐닝",
    "seating": "좌석",
    "external": "외부",
    "demographics": "통계",
    "pools": "풀",
    "shops": "샵",
    "prices": "Prices",
    "gates": "Gates",
    "templates": "Templates",
    "schedules": "Scheduler",
    "sync": "Sync changes",
    "organizations": "Organisations",
    "checkin": "Check-in",
    "orders": "Orders",
    "reservations": "Reservations"
  },
  "actions": {
    "accept": "Accept",
    "seeAll": "See all",
    "ok": "OK",
    "close": "종료",
    "logout": "로그아웃",
    "login": "로그인",
    "signup": "Sign up",
    "publish": "게시",
    "savePublish": "저장 후 게시",
    "confirm": "확정",
    "confirmAll": "Confirm all",
    "saveChanges": "Save changes",
    "invalidate": "무효화",
    "reset": "리셋",
    "export": "데이터베이스 다운로드",
    "loadMore": "좀 더 보여주세요",
    "cancel": "취소",
    "search": "검색",
    "copy": "복사",
    "apply": "저장",
    "edit": "편집",
    "back": "Back",
    "save": "저장",
    "delete": "삭제",
    "preview": "프리뷰",
    "next": "다음",
    "uploadImage": "이미지 업로드",
    "checkin": "입장",
    "undoCheckin": "Undo check-in",
    "showAll": "모두 보여주기",
    "clear": "리셋",
    "solve": "자동 배치",
    "finalize": "최종확인",
    "assign": "배치함",
    "printTickets": "티켓인쇄 | 티켓인쇄",
    "printPrivileges": "Print privilege | Print privileges",
    "printAllPrivilegesforCustomer": "Print all tickets",
    "print": "Print",
    "block": "차단",
    "unblock": "배포",
    "addRow": "열 추가",
    "email": "이메일 보내기",
    "sendEmail": "Send Email",
    "resend": "Resend",
    "revoke": "Revoke",
    "editPhoneNumber": "Edit Phone Number",
    "deleteAccount": "Delete Account",
    "sellTickets": "Sell Tickets",
    "batchAction": "일괄 실행",
    "import": "가져오기",
    "browse": "둘러보기",
    "selectTimezone": "시간대를 선택하세요",
    "chooseFile": "Choose a file or drop it here",
    "linkEmbed": "Link/embed",
    "view": "보기",
    "click": "click here",
    "add": "Add",
    "remove": "Remove",
    "removeAll": "Remove all",
    "change": "Change",
    "activate": "Activate",
    "deactivate": "Deactivate",
    "toDashboard": "Continue to dashboard",
    "switchOrganization": "Switch organization",
    "allOrganizations": "Show from all my organisations",
    "goToEvents": "Go to my events",
    "clearFile": "Clear file",
    "download": "Download",
    "help": "Help",
    "multiNoteTicket": "Multiple notes. Click the ticket for details.",
    "multiSeatingTicket": "Multiple seatings. Click the ticket for details.",
    "createGroup": "그룹 생성하기",
    "select": "Select",
    "issue": "Issue",
    "issueNow": "Issue now",
    "createReservation": "Create Reservation",
    "update": "Update",
    "distributeTickets": "Distribute tickets",
    "editNotes": "Edit notes",
    "addNotes": "Add notes"
  },
  "labels": {
    "en": "English",
    "nl": "Nederlands",
    "de": "German",
    "it": "Italiano",
    "ko": "한국어",
    "es": "Español",
    "locale": "Language",
    "yes": "Yes",
    "no": "No",
    "name": "티켓명칭",
    "shortName": "Short name",
    "date": "날짜",
    "time": "시간",
    "id": "ID",
    "checkinOptions": {
      "all": "All",
      "blocked": "차단됨",
      "assigned": "배정됨"
    },
    "amountSpecifier": "of",
    "ticketeer": "Ticketeer",
    "currentOrg": "Current organisation",
    "events": "이벤트 | 이벤트",
    "eventTitle": "이벤트 명칭 | 이벤트 명칭",
    "order": "Order | Orders",
    "reservation": "Reservation",
    "created": "만들어짐",
    "expiry": "Expiry",
    "expired": "Expired",
    "claimed": "Claimed",
    "open": "Open",
    "canceled": "Canceled",
    "totalValue": "Total Value",
    "paidValue": "Paid value",
    "completed": "Completed",
    "actions": "Actions",
    "payment": "Payment | Payments",
    "method": "Method | Methods",
    "orderId": "Order-ID {id}",
    "paymentId": "Payment-ID {id}",
    "template": "Template | Templates",
    "templateTitle": "Template name",
    "organization": "Organisation",
    "section": "Section",
    "enabled": "enabled",
    "disabled": "disabled",
    "state": "State",
    "success": "Success!",
    "danger": "Error",
    "credits": "Credits",
    "summary": "Summary",
    "paymentMethod": "Payment Method | Payment methods",
    "pspId": "Psp ID",
    "type": "Type",
    "left": "Left",
    "max": "Max",
    "label": "{options} and {length} more",
    "notAvailableShort": "N/A",
    "availability": "Availability",
    "privilege": "Privilege | Privileges",
    "category": "Category | Categories",
    "yourOrders": "Your orders",
    "yourCustomers": "Your customers",
    "yourShops": "Your shops",
    "contactName": "Contact name",
    "contactEmail": "Contact email",
    "contactPhone": "Contact phone",
    "email": "Email address",
    "createdOn": "Created on",
    "createdBy": "Created by",
    "yourReservations": "Your reservations",
    "reservationId": "Reservation-ID {id}",
    "invitations": "Invitation | Invitations",
    "recipient": "Recipient",
    "claimedBy": "Claimed by",
    "expirationDate": "Expiration date",
    "customMessageContent": "Custom message content",
    "dates": {
      "today": "Today",
      "yesterday": "Yesterday",
      "last7Days": "Last 7 days",
      "last4Weeks": "Last 4 weeks",
      "thisMonth": "This month",
      "thisYear": "This year",
      "custom": "Custom",
      "allTime": "All time",
      "endDate": "End date",
      "startDate": "Start date",
      "fromDate": "From date",
      "untilDate": "Until date",
      "years": "year | years",
      "am": "AM",
      "pm": "PM",
      "timezone": "Time zone",
      "validFrom": "Valid from",
      "validUntil": "Valid until",
      "relativeTime": "Relative time",
      "dateOptions": {
        "dateAndTime": "Date & time",
        "specificDate": "on a specific day and time",
        "whenShopOpens": "when the shop opens",
        "relativeDateEnds": "on relative time before the event ends",
        "relativeDateStarts": " on relative time before the event starts",
        "whenEventEnds": "when the event ends",
        "whenDoorsOpen": "when the doors open",
        "doorsOpenWarning": "Your event starts on {eventDateTime}. The customers' tickets QR code will become available in their app 3 hours before the doors open.",
        "immediately": "Immediately",
        "absoluteTimeWarning": "Warning: Created events from a template, or copied events, will use this specific date, even when the newly created event's date is different.",
        "relativeTimeWarningStart": {
          "cta": "Enter the amount of days before the event starts and the time on that day to use this relative date.",
          "warning": "If your event starts on {eventStarts} the actual date would be the {selectedDateTime}"
        },
        "relativeTimeWarningEnd": {
          "cta": "Enter the amount of days before the event ends and the time on that day to use this relative date.",
          "warning": "If your event ends on {eventEnds} the actual date would be the {selectedDateTime}"
        },
        "relativeTimeInfo": "Enter the amount of days before the event ends and the time on that day to use this relative date.",
        "relativeTimeExample": "If your event ends on {eventEnds} the actual date would be the {datetime}.",
        "ticketRelativeTimeFromInfo": "Enter the amount of days before the event ends and the time on that day to set the moment when these tickets become available at the shop.",
        "ticketRelativeTimeToInfo": "Enter the amount of days before the event ends and the time on that day to set the moment when these tickets become unavailable at the shop.",
        "daysBeforeEventEnds": "Days before the event ends",
        "daysBeforeEventStarts": "Days before the event starts",
        "dayTime": "Time on that day",
        "never": "Never"
      }
    },
    "profile": {
      "anonymous": "Anonymous",
      "firstName": "이름",
      "lastName": "성",
      "phoneNumber": "스마트폰 번호",
      "mobileNumber": "Mobile number",
      "email": "이메일 주소",
      "language": "Language",
      "confirmEmail": "Confirm email",
      "zipcode": "우편번호",
      "birthdate": "생년월일",
      "gender": "성별",
      "ageGroups": "나이대",
      "role": "Role",
      "m": "남",
      "f": "여",
      "o": "기타",
      "lastPurchase": "마지막 구매",
      "contact": "Contact information",
      "addressInformation": "Address information",
      "basicInformation": "Basic information",
      "address": "Street name & house number",
      "addressShort": "주소",
      "city": "City",
      "country": "Country",
      "state": "State",
      "nft_wallet_address": "NFT wallet address",
      "gusuuid": "GUS uuid",
      "publicTicketEngineUrl": "Wallet link"
    },
    "event": {
      "shortName": "Short name",
      "seated": "좌석배정됨",
      "nonSeated": "좌석배정 안됨",
      "livestream": "Livestream",
      "title": "제목",
      "subtitle": "부제목",
      "location": "장소",
      "sublocation": "세부장소",
      "city": "도시",
      "country": "국가",
      "address": "주소",
      "datesTitle": "Dates & Times",
      "doorsOpen": "Opening time",
      "startDate": "Date the event starts",
      "daysRunning": "Duration in days",
      "when": "시작시간",
      "ends": "Closing time on the final day",
      "endDateTime": "End date and time",
      "saleStart": "판매 시작 시간",
      "saleEnds": "판매 종료 시간",
      "url": "이벤트의 웹페이지 주소",
      "slug": "슬러그(Slug)",
      "draft": "초안",
      "timezone": "Timezone where the event is taking place",
      "capacity": "Capacity"
    },
    "ticketStatus": {
      "sold": "판매됨",
      "returned": "환불",
      "resold": "재판매",
      "forsale": "판매용",
      "being-resold": "판매용 및 장바구니",
      "created": "만들어짐",
      "invalidated": "삭제됨",
      "executed": "사용인증됨",
      "partialCheckedIn": "Checked-in",
      "inCart": "장바구니",
      "notForSale": "비판매용",
      "notExecuted": "체크인 안함",
      "unassigned": "배정되지 않음",
      "blocked": "차단됨",
      "blocks": "차단됨",
      "assigned": "배정됨",
      "external": "외부",
      "free": "무료",
      "released": "Released",
      "transferred": "Reservation Claimed"
    },
    "orderStatus": {
      "all": "All",
      "created": "Created",
      "pending": "Pending",
      "canceled": "Canceled",
      "timeout": "Timeout",
      "completed": "Completed",
      "failed": "Failed"
    },
    "reservationStatus": {
      "label": "티켓의 상황",
      "all": "All",
      "confirmed": "Confirmed",
      "distribution_started": "Distribution started",
      "awaiting_confirmation": "Awaiting confirmation",
      "awaiting_distribution": "Awaiting distribution",
      "invalidated": "Invalidated",
      "checked_in": "Checked-in",
      "partially_checked_in": "Checked-in",
      "invited": "Invited",
      "expired": "Expired",
      "canceled": "Canceled",
      "completed": "Completed"
    },
    "reservationInvitationStatus": {
      "label": "Status",
      "invited": "Invited",
      "claimed": "Accepted",
      "revoked": "Revoked",
      "created": "Created"
    },
    "paymentStatus": {
      "pending": "Pending",
      "paid": "Paid",
      "duplicated": "Duplicated",
      "expired": "Expired",
      "failed": "Failed",
      "canceled": "Canceled"
    },
    "refundStatus": {
      "pending": "Pending",
      "success": "Success",
      "failed": "Failed"
    },
    "orderType": {
      "normal": "Online",
      "cashier": "Cashier",
      "reservation": "Reservation",
      "claimed": "Claimed"
    },
    "seat": {
      "rows": "열 | 열",
      "rowsAbbreviation": "Rw",
      "sections": "섹션 | 섹션",
      "sectionsAbbreviation": "Sec",
      "subsections": "세부 섹션 | 세부 섹션",
      "subsectionsAbbreviation": "Sub-s",
      "entrances": "입구 | 입구",
      "entrancesAbbreviation": "Ent",
      "ranks": "좌석등급 | 좌석등급",
      "tiers": "등급 | 등급",
      "seats": "좌석 | 좌석",
      "seatsAbbreviation": "St",
      "category": "카테고리",
      "cashierSeating": {
        "auto": "Seated automatically",
        "manual": "Seated manually",
        "not_applicable": "Not applicable"
      }
    },
    "ticketTypes": {
      "upsell": "추가판매",
      "access": "Entrance",
      "checked_in": "Checked-in"
    },
    "ticketType": "티켓 타입",
    "eventOrganizer": "관리자",
    "tickets": "티켓 | 티켓들",
    "types": "Type | Types",
    "totals": "총수량 | 총수량",
    "total": "총수량",
    "from": "부터",
    "until": "까지",
    "description": "설명",
    "available": "판매가능함",
    "forSale": "판매용",
    "locks": "차단(block) | 차단(block)",
    "guests": "게스트 | 게스트들",
    "upsells": "업셀링 | 업셀링",
    "variants": "변형 | 변형",
    "combiTicket": "콤비-티켓을 만드세요",
    "guestlist": "게스트 리스트",
    "price": "가격",
    "free": "무료",
    "status": "티켓의 상황",
    "lastStatus": "Last status",
    "onDate": "on {date}",
    "groups": "그룹 | 그룹",
    "notes": "비고 | 비고",
    "sales": "판매",
    "checkIns": "체크인",
    "seating": "좌석",
    "privileges": "Privileges",
    "accessPrivileges": "Entrance Privilege | Entrance Privileges",
    "ownedBy": "Owned by",
    "noResults": {
      "default": "No results found...",
      "orders": "No orders found...",
      "customers": "No customers found..."
    },
    "results": "results",
    "step": "제 {count} 단계",
    "amount": "숫자",
    "amountFinance": "Amount",
    "refundedAmount": "Refunded amount",
    "amountRefunded": "Amount refunded to {currency}",
    "pricePerTicket": "Price per ticket",
    "numberOfTickets": "Number of tickets",
    "subtotal": "Subtotal",
    "paidInFiat": "Paid in {currency}",
    "paidWithCredits": "Paid with Credits",
    "refundedToCredits": "Refunded to Credits",
    "dateCreated": "Date created",
    "dateUpdated": "Last updated",
    "totalDeducted": "Total deducted",
    "referral_id": "Referral ID",
    "primary": "Primary",
    "secondary": "Secondary",
    "unknown": "Unknown",
    "note": "노트",
    "privilegeNote": "Privilege note",
    "ticketNote": "Ticket note",
    "optional": "(선택사항)",
    "mandatory": "(mandatory)",
    "localTime": "현지 시간",
    "other": "기타",
    "pools": "풀",
    "availableTicketsTitle": "Available tickets",
    "availableTickets": "Available",
    "allocated": "할당된",
    "blocks": "차단",
    "refundType": {
      "name": "Refund method",
      "auto": "Automatic",
      "manual": "Manual",
      "credits": "Credits",
      "no_refund": "No refund"
    },
    "shops": "샵 | 샵",
    "details": "세부사항",
    "priceType": "Price type",
    "priceSelect": "Select a price",
    "customPrice": "Custom price",
    "globalPrice": "Global price",
    "totalPrice": "Total price",
    "example": "(example)",
    "users": "Users",
    "currency": "Currency",
    "paidEur": "Paid with {currency}",
    "paidCredits": "Paid with Credits",
    "refundedEur": "Refunded to {currency}",
    "refundedCredits": "Refunded to Credits",
    "grossRevenue": "Gross Revenue",
    "refunded": "Refunded",
    "inCurrency": "In {currency}",
    "toCredits": "To Credits",
    "market": "Market",
    "info": "Info",
    "paidWithCreditsPartially": "Partially paid with credits",
    "mandatoryField": "mandatory field",
    "collectibles": "Collectibles"
  },
  "validation": {
    "error": {
      "minFileSize": "파일은 {max}kb보다 커야 합니다.",
      "minValue": "값은 최소 {min}보다 높아야 합니다",
      "maxValue": "값은 최대 {max}보다 낮아야 합니다",
      "maxDecimals": "You can use up to {precision} decimals",
      "validateImageDeminsons": "해상도는 {width}x{height} 보다 높아야 합니다",
      "minDate": "이 {field}는 {minVal} 이후여야 합니다",
      "maxDate": "이 {field} 시간은 {maxVal} 이전이어야 합니다",
      "minOffset": "이 {field} 시간은 {maxVal} 이전이어야 합니다",
      "maxOffset": "The {field} must be after {minVal}",
      "required": "이 내용은 반드시 기입되어야 합니다",
      "richTextMaxLength": "글자수가 {maxLength} 보다 길어서는 안됩니다",
      "cover": "이 사진은 정확한 규격이 아닙니다",
      "maxSize": "총 크기가 {maxSize}kb보다 크면 안됩니다",
      "fileType": "이것은 정확한 파일 형식이 아닙니다",
      "maxLength": "내용은 {max}보다 길면 안됩니다",
      "maxCharLength": "내용은 {max} 글자수보다 길면 안됩니다",
      "combinedMaxLength": "각 내용은 {max} 글자수보다 길면 안됩니다",
      "decimal": "유효한 숫자여야 합니다",
      "alphaNum": "유효한 영문자 또는 숫자여야 합니다",
      "alphaNumDashUnderscore": "유효한 영문자 또는 숫자여야 합니다",
      "numeric": "유효한 숫자 값이어야 합니다",
      "phoneNumber": "이 휴대폰 번호는 유효하지 않습니다. (Country codes are required, i.e \"+82\")",
      "sameAsNumber": "The phone numbers do not match, please re-enter.",
      "sameAs": "The {field} entered do not match, please re-enter.",
      "url": "Please enter a valid url (https://example.com).",
      "email": "Please enter a valid email address",
      "integer": "Please enter a whole number",
      "minLength": "The {field} must be at least {length} characters long.",
      "nonNumeric": "The {field} cannot contain only numeric characters.",
      "wrongPass": "Please enter the correct password.",
      "phoneNumberValidation": "Always include plus sign (+) and country code!",
      "isNotUnicode": "Please make sure to use no unicode characters"
    }
  },
  "views": {
    "reports": {
      "title": "Your exports",
      "nav": {
        "exports": "Exports",
        "sales": "Sales"
      },
      "exports": {
        "tabs": {
          "revenue": "Revenue",
          "summary": "Summary",
          "orders": "Orders",
          "attendees": "Attendees",
          "blocks": "Blocks",
          "advanced": "Advanced",
          "groups": "Groups"
        },
        "type": {
          "group_sizes_report": "Groups",
          "revenue_report": "Revenue",
          "summary_report": "Summary",
          "orders_report": "Orders",
          "attendees_report": "Attendees",
          "blocks_report": "Blocks"
        },
        "exportHistory": {
          "title": "Your latest exports",
          "created": "Created {date}",
          "generating": "Generating export...",
          "exportGenerationSuccess": "Export successfully generated"
        },
        "selectEventsAction": "Add or remove events",
        "action": "Generate export",
        "reportsGenerationFail": "Generating export failed, please retry...",
        "configurationError": "No events belonging to your organisation in selected gate found...",
        "limitError": "You can generate a report for a maximum of {max} events! In case you want to create bigger exports, please contact our support.",
        "selectedEvents": "{selectedEvents} / {maxEvents} event selected | {selectedEvents} / {maxEvents} events selected",
        "gateInfo": "Note: only events belonging to your organisation in the selected gate will be included in the export.",
        "selectOrg": "Select the organisation to create export for",
        "options": {
          "tickets": "Options: tickets & upsells",
          "events": "Options: for events & shops"
        },
        "advanced": {
          "eventSales": "All Ledger Transactions",
          "secondaryMarket": "Resale Market Transactions",
          "billingSummary": "Billing Summary"
        },
        "attendees": {
          "privacy-policy": "Attendees opted-in on privacy policy (if applicable)",
          "user-privacy": "Only attendees that accepted your privacy-policy",
          "brand-privacy": "Only attendees that accepted the privacy-policy of {brand}",
          "all": "Include all attendees",
          "optional": "Include optional attendees",
          "non-owners": "Include attendees that don’t own tickets anymore (resold, invalidated)",
          "invited": "Include attendees invited by ticket-holders in a group",
          "warning": "Note: By European law you are allowed to use the data of attendees who have not accepted your privacy-policy for service necessities only. This excludes marketing."
        },
        "merge": {
          "pricetype": "Merge same price type",
          "hideItems": "Hide items with empty values"
        },
        "groupBy": {
          "events": "Group by event",
          "shop": "Group by shop"
        },
        "desiredFormat": "Desired format",
        "format": {
          "xlsx": "Excel (xlsx)",
          "csv": "Comma separated (csv)"
        },
        "includeEvents": "Include the following events",
        "eventOptions": {
          "all-events": "All events",
          "specific-gate": "Events in specific gate",
          "specific-events": "Select specific events"
        },
        "periodInclude": "Select period when revenue is received",
        "periodOptions": {
          "specific-period": "Specific period",
          "all-time": "All time"
        },
        "selectEventsModal": {
          "title": "Select events to include in export",
          "yourEvents": "Your events",
          "added": "Events added",
          "placeholder": "Search events",
          "addEvent": "Add {count} event | Add {count} events",
          "removeEvent": "Remove {count} event | Remove {count} events",
          "info": "To select multiple events, you can press shift or control (command on Mac) and select, or click and drag."
        }
      }
    },
    "organizations": {
      "organization": {
        "title": "Organisation",
        "name": "Organisation Identity",
        "legalName": "Organisation Name",
        "legalNameDescription": "The official name of the organisation as it will be shown to customers.",
        "privacyPolicy": "Privacy policy text and/or file (optional)",
        "privacyPolicyFileDescription": "Overwrite the default text for your privacy policy opt-in",
        "privacyPolicyPlaceholder": "I want to be kept informed of events and promotions and agree with the privacy statement of {orgName}.",
        "termsConditions": "Terms & conditions text and/or file (mandatory for attendee)",
        "termsConditionsFileDescription": "Overwite the default text for your terms & conditions opt-in",
        "termsConditionsPlaceHolder": "I agree with the terms and conditions of {orgName}.",
        "localeSelectPlaceholder": "Select default language for e-mails etc.",
        "pspSelectPlaceholder": "Select a payment service provider.",
        "demo": {
          "label": "Demo mode",
          "description": "Create organisation for demo purposes only",
          "info": "A demo organisation is limited to dummy payments, and cannot be used to sell real tickets.",
          "badge": "Demo",
          "alert": "This organisation is set to demo mode. Real payments are disabled. Events created under it are only to be used for demo or testing purposes."
        },
        "miscellaneous": {
          "title": "Miscellaneous",
          "refund": "Refund to credits",
          "expiration": "Expiration period",
          "expirationInfo": "This period can be overridden based on the role."
        },
        "paymentInfo": {
          "title": "Payments",
          "psp": {
            "label": "Payment Service Provider",
            "dummyInfo": "Note: Only use for demos or testing. Real payments are not possible.",
            "selectInfo": "Note: The selected currency determines which payment service providers are available.",
            "lockedInfo": "Note: You cannot switch PSP after your first ticket has been sold.",
            "options": {
              "mollie": "Mollie",
              "stripe": "Stripe",
              "dummy": "Dummy",
              "ticketeer_payments": "PSP of {brand}"
            }
          },
          "apiKey": {
            "testApiKeyLabel": "Test API key",
            "test_key": "You don't have permission to submit TEST API credentials",
            "label_apiKey_mollie": "Live API key",
            "label_apiKey_stripe": "Secret Key",
            "label_publicKey_stripe": "Publishable key",
            "label": "API key",
            "publicKey": "Public API key",
            "addInfo": "Add the API key provided by your payment service provider. When you set the API key correctly, approval is done immediately.",
            "existsInfo": "Note: You cannot change this API key after your first ticket has been sold.",
            "error": "This API key does not seem to be valid",
            "apiErrors": {
              "invalid_api_key": "Error: Invalid API key"
            }
          },
          "methods": {
            "title": "Payment methods",
            "overrideLabel": "Override payment methods",
            "overrideInfo": "The following payment methods are set according to your PSP account you selected. If you chose to override the payment methods, changes in your PSP account won’t reflect in these settings.",
            "overrideInfoStripe": "Important: The accepted payment methods need to exactly match your Stripe enabled payment methods, otherwise payments will not work! By default everything is enabled."
          }
        },
        "fileDescription": "PDF's only with a maximum filesize of 20mb",
        "previewFileLink": "View file",
        "usersAndRoles": {
          "navTitle": "Users & roles",
          "create": "Invite user",
          "active": "Active",
          "deactivated": "Deactivated",
          "search": "Search users",
          "activateUser": "Activate",
          "pendingInvitation": "{role} pending invitation",
          "expiredInvitation": "Invitation expired",
          "inviteUserModal": {
            "edit": "Edit user",
            "pending": "Pending invitation",
            "expired": "Expired invitation",
            "invite": "Invite user",
            "info": "An email will be sent to this email address to create an account or log-in and link the organisation to this user.",
            "emailAlreadyUsed": "This email address has already been invited to the dashboard. Please use a different email address.",
            "send": "Send invitation",
            "resend": "Resend invitation",
            "removeInvitation": "Remove invitation",
            "deactivate": "Deactivate"
          },
          "switchUserStateModal": {
            "activateTitle": "Activate user",
            "deactivateTitle": "Deactivate user",
            "description": "Are you sure you want to {action} {email}?"
          }
        },
        "attendeeInfo": {
          "title": "Attendee information",
          "description": "The following is the information attendees will have to fill in when buying tickets for your events. You can select if you want the information below to be mandatory, optional or hidden. The fields marked as always mandatory cannot be edited.",
          "states": {
            "always": "Always mandatory",
            "mandatory": "Mandatory",
            "optional": "Optional",
            "hide": "Hide"
          },
          "anonymize": {
            "button": "Remove account",
            "modal": {
              "title": "Are you sure?",
              "description": "Removing the account will result in the deletion of all data and tickets. It CANNOT be rolled back."
            }
          }
        },
        "floorplans": "Floorplans"
      },
      "placeholder": "Search organisations",
      "create": "Create organisation",
      "modal": {
        "title": "Create organisation",
        "selectCurrency": "Select currency"
      },
      "table": {
        "psp": "PSP",
        "edit": "Edit"
      }
    },
    "account": {
      "editTitle": "Edit account",
      "passwords": {
        "password": "Password | Passwords",
        "changePass": "Change password",
        "resetPass": "Reset password",
        "forgotPass": "Forgot password?",
        "currentPass": "Current password",
        "newPass": "New password",
        "confirmPass": "Confirm password",
        "apiErrors": {
          "weak_password": "Password can't be similar to your email, name or a weak passwords like \"admin1234\""
        },
        "passwordInfo": {
          "title": "We expect a strong password:",
          "minLength": "Minimum length of 9 characters and not just numbers",
          "similar": "Not similar to your email, or weak passwords like \"admin123\"."
        }
      },
      "login": {
        "title": "Login",
        "invalidCredentials": "Invalid credentials",
        "noAccount": "Don't have an account?",
        "username": "Username"
      },
      "signup": {
        "title": "Sign up",
        "hasAccount": "Already have an account?",
        "apiErrors": {
          "user_already_exists": "An account with this email already exists."
        }
      },
      "reset": {
        "description": "Enter your email address and we’ll send you a link to reset your password.",
        "requestedDescription": "If there is an account associated with this email address, you will receive a link to reset your password.",
        "expiredAlert": "This link has expired. You can request another password-reset.",
        "toLogin": "Go back to login"
      }
    },
    "invite": {
      "title": "Accept invitation",
      "expired": {
        "alert": "Your invitation is not valid anymore.",
        "info": "For more information or help, please contact {0}."
      },
      "noInvite": "This invite has already been accepted or doesn't exist. You can continue to go to dashboard.",
      "accept": {
        "title": "You have been invited to join: {org}",
        "inviteAccepted": "You now have access to: {org}",
        "info": "The invitation will be accepted under your current account: {email}. If you wish to accept using a different account, log in to this account first and then click the invitation link again."
      }
    },
    "attendees": {
      "attendee": {
        "edit": {
          "changeNumber": {
            "title": "Change number",
            "description": "You are about to change the account with mobile number {number}",
            "inputLabel": "New mobile number"
          },
          "confirmNumber": {
            "attendees": "attendees",
            "migrateConfirm": "If you want to migrate, please confirm the new mobile number.",
            "existsDescription": "There is already an account tied to this new mobile number. Are you sure this is correct?",
            "existsWithTickets": "This account has upcoming events, therefore you cannot migrate. Go back if you want to use a different mobile number.",
            "inputLabel": "Confirm the new mobile number"
          },
          "success": "Successfully updated mobile number",
          "error": "Error: Could not update mobile number"
        }
      }
    },
    "customers": {
      "title": "Your customers",
      "startSearch": "Search for customers across all your events by entering a (partial) name, email address or phone number. Easy peasy!",
      "loading": "Customer loading...",
      "groupsLoading": "Loading groups...",
      "tooltips": {
        "privilegesDescription": "The total number of entrance privileges allocated to a customer."
      },
      "tickets": {
        "ticketsTable": {
          "emptyText": "No tickets found, check orders for more information",
          "emptyTextShort": "No tickets found",
          "resoldBy": "by {customer}"
        },
        "checkin": {
          "title": "Check-in",
          "description": "Are you sure that you want to check-in this ticket? | Are you sure that you want to check-in these {count} tickets?"
        },
        "undoCheckin": {
          "title": "Undo Check-in",
          "description": "Are you sure that you want to undo check-in this ticket? | Are you sure that you want to undo check-in these {count} tickets?"
        },
        "invalidate": {
          "steps": {
            "options": "Refund options",
            "details": "Refund details"
          },
          "refundOptions": {
            "blockTickets": "Block the ticket that will be available again | Block the tickets that will be available again",
            "tooltipText": "If you don't select this option, this ticket will be up for sale again. | If you don't select this option, the tickets will be up for sale again.",
            "title": "Refund ticket | Refund tickets",
            "description": "Select if and how you want refund the ticket you are about to invalidate. | Select if and how you want refund the tickets you are about to invalidate.",
            "options": {
              "automatic": {
                "title": "Automatic refund",
                "description": "Automatically refund the amount paid for the tickets to the attendee. Select in the next step if the attendee or the organizer will be charged the service fee."
              },
              "manual": {
                "title": "Manual refund",
                "description": "Refund manually using a external payment method and adjust the reports accordingly. Select in the next step if the attendee or the organizer will be charged the service fee."
              },
              "credits": {
                "title": "Credits",
                "description": "Refund the amount and add these funds to account of the user to be redeemed later."
              },
              "noRefund": {
                "title": "No refund",
                "description": "Only invalidate the tickets, keep the original paid amount in the reports."
              }
            }
          },
          "refundDetails": {
            "warning": "This action cannot be undone. If you wish to continue, press confirm.",
            "title": {
              "auto": "Automatic refund",
              "manual": "Manual refund",
              "credits": "Credits",
              "noRefund": "No refund"
            },
            "howToRefund": "How would you like to refund the following tickets?",
            "fullRefund": "Full refund",
            "partialRefund": "Partial refund",
            "amountToDeduct": "Amount you wish to deduct per ticket",
            "creditsExpiration": "Credits expiration date",
            "summary": {
              "title": "Summary",
              "description": "The following ticket will be invalidated. | The following tickets will be invalidated.",
              "totalRefund": "Total refund: {formatedAmount}",
              "ticketBlockedOnCategory": "The ticket will be block on category: | The tickets will be block on category:"
            }
          }
        },
        "editNotes": {
          "title": "Edit note | Edit notes",
          "multiNotesDescription": "You're editing multiple notes, this change will reflect on all the items you selected.",
          "placeholder": "Note"
        }
      },
      "block_categories": {
        "uncategorized": "Uncategorized",
        "uncategorized_short": "UNC",
        "search": "Search a category..."
      },
      "privileges": {
        "privilegesTable": {
          "emptyText": "No privileges found"
        }
      },
      "orders": {
        "ordersOverviewTitle": "Your orders"
      },
      "refunds": {
        "refundsTable": {
          "emptyText": "No refunds found"
        }
      },
      "customer": {
        "noName": "Unnamed customer",
        "noAddress": "No address",
        "userQr": {
          "action": "User Qr",
          "title": "{name} user QR",
          "description": "Scan the following QR code to check-in the customer.",
          "error": "Could not load the QR code for this customer"
        },
        "edit": {
          "changeNumber": {
            "title": "Change number",
            "description": "You are about to change the account with mobile number {number}",
            "inputLabel": "New mobile number"
          },
          "confirmNumber": {
            "migrateInfo": "This mobile number can be changed. You can optionally check if this account already exists, by going to {customers}.",
            "customers": "customers",
            "migrateConfirm": "If you want to migrate, please confirm the new mobile number.",
            "existsDescription": "There is already an account tied to this new mobile number. Are you sure this is correct?",
            "existsWithTickets": "This account has upcoming events, therefore you cannot migrate. Go back if you want to use a different mobile number.",
            "inputLabel": "Confirm the new mobile number"
          },
          "success": "Successfully updated mobile number",
          "error": "Error: Could not update mobile number"
        }
      },
      "filters": {
        "pool": "Pool",
        "shop": "샵",
        "checkin": {
          "name": "Checked-in status",
          "fully": "Fully",
          "partially": "Partially",
          "not": "Not"
        }
      }
    },
    "events": {
      "create": {
        "title": "새로운 이벤트",
        "new": "New event",
        "fromTemplate": "Choose template"
      },
      "title": "Your events",
      "titleWithOrganization": "Your events for {org}",
      "reports": {
        "title": "Your sales",
        "titleWithOrganization": "Your sales for {org}",
        "revenue": "Revenue",
        "revenueBoxTitle": "NET revenue this period",
        "ticketSoldBoxTitle": "Tickets sold this period",
        "grossRevenue": "GROSS revenue",
        "invalidated": "Invalidated",
        "refunded": "Refunded",
        "upsellSoldBoxTitle": "Upsells sold this period",
        "credits": {
          "title": "Credits",
          "circulating": "Circulating",
          "info": "The amount of credits you refunded which haven't expired or been used yet"
        }
      },
      "eventCreate": {
        "title": "Create new {type}",
        "eventDescription": "Create a brand new event from scratch! These steps will help you create the event that matches your needs perfectly. First, you choose the core type of event. You can edit all other settings afterwards without a problem, except for this core event type. Of course, you can always start over, this will be saved as a draft until you decide it's good to go. No pressure, you've got this.",
        "templateDescription": "Create a new template from scratch! These steps will guide you to set up the minimal requirements for an event that you can use as your own template. You can edit all settings afterwards, except for the initial type of event chosen in this first step.",
        "templateInfo": "Once you have your template, you can automatically generate multiple events with the same set-up. All settings in the template will be copied and updated for all events. ",
        "setEventType": "Type of event",
        "selectType": {
          "label": "Select the type of event you want to create"
        },
        "selectVenue": "장소 선택",
        "eventDetails": "이벤트 세부사항",
        "ticketManagement": "티켓 관리",
        "modal": {
          "title": "새로운 이벤트를 프리뷰하세요",
          "content": "새로운 이벤트가 성공적으로 만들어져서 이벤트 초안에 저장되었습니다.<br><br>이제 이벤트를 게시하거나 아니면 내용을 수정하기 위해서 대시보드로 돌아가십시오.",
          "dashboardButton": "대시보드로 돌아가세요",
          "publishButton": "이벤트를 게시하세요"
        }
      },
      "eventCopy": {
        "action": "이벤트를 복사하세요",
        "description": "선택한 이벤트의 모든 설정들이(차단까지 포함해서) 복사됩니다.",
        "startDate": "시작 날짜"
      },
      "templateCopy": {
        "action": "Copy Template",
        "description": "All configurations of the template will be copied, along with the holds."
      },
      "event": {
        "savedAsDraft": {
          "title": "이벤트 초안이 저장되었습니다.",
          "draftInfo": "이벤트가 성공적으로 생성되었고 초안으로 저장되었습니다. 대시보드의 이벤트 초안 항목에서 이벤트 세부 내용을 수정할 수 있습니다.",
          "previewInfo": "이벤트를 먼저 프리뷰 하고 나중에 게시할 수 있습니다. 확인을 누르시면 대시보드로 돌아갑니다."
        },
        "demo": {
          "description": "Demo event: This is event has been created under a demo organisation. Real payments are disabled. Only use this event for demo or testing purposes."
        },
        "unlink": {
          "title": "This event is managed by a template.",
          "description": "Changes to shops, tickets, pools and other event settings can only be made and synced to all events at once in this event’s {0}.",
          "eventTemplate": "template",
          "shop": {
            "title": "This shop is managed by a template.",
            "description": "Changes to this shop, its tickets and groups can only be made and synced to all events at once in {0}.",
            "link": "this event's template shop"
          },
          "action": {
            "description": "If you want to only make changes in this event {0}",
            "unlink": "unlink from template."
          },
          "modal": {
            "title": "Are you sure you want to unlink this event?",
            "description": "When you unlink this event from its template, it will not be updated anymore when changes are made in this template and synced. You are able to manage it completely as a single event. This action cannot be undone!",
            "action": {
              "unlink": "unlink",
              "description": "Type {0} in the field below to confirm"
            },
            "unlinkEvent": "Unlink event"
          }
        },
        "manage": {
          "placeholders": {
            "title": "e.g. Maximum Music Festival",
            "subtitle": "e.g. 20th Aniversary",
            "shortName": "e.g. MaxMusic"
          },
          "shortNameExplanation": "Will be used for SMS notifications when issuing tickets through the dashboard",
          "generalTitle": "기본 세부정보 관리",
          "additionalTitle": "추가 세부정보 관리",
          "advancedTitle": "고급 설정관리",
          "saveValidateAlert": "필수 기입란이 비어 있거나 유효하지 않은 정보를 포함하고 있습니다.<br>위의 오류들을 확인하세요.",
          "marketing": "마케팅 & 업셀링",
          "scannerPassword": "스캐너 비밀번호",
          "scannerQr": "QR코드",
          "about": {
            "label": "귀하의 이벤트에 대해서",
            "description": "귀하의 이벤트에 대해서 서술하고, 링크로 다른 사람들과 공유하세요.",
            "help": "귀하가 원하시는 부분의 문장을 선택해서 스타일링할 수 있습니다."
          },
          "endDateSwitch": "This event ends after midnight on the next day",
          "endDateDescription": "If an event ends after midnight (e.g. 01:00) it technically ends a day later - early in the morning. Toggle this to communicate that this event actually ends on the previous day.",
          "templateStartDateInfo": "Uses the specified date of an event when it is generated.",
          "dateErrors": {
            "required": "All date and time fields are required. Please make sure to fill in all information.",
            "minOpen": "The opening time cannot be after the start time of the event.",
            "minOpenLivestream": "The opening time for livestream should be at least 15 min before the event start."
          },
          "coverImage": {
            "label": "표지 사진",
            "description": "고품질의 사진을 첨부할 것, 다양한, 디테일한 사진. 사진안에 텍스트나 로고를 삽입하는 것은 피할 것.",
            "help": "PNG 혹은 JPG 파일. 최저 해상도 640x480. 최대 파일크기 10Mb."
          },
          "floorplanImage": {
            "label": "평면도 혹은 공연장 배치도",
            "description": "Will be provided by the ticketeer in eps format.",
            "help": "SVG 혹은 PNG 파일 타입. 권장 해상도 375 픽셀 넓이."
          },
          "orderSuccessLink": {
            "label": "티겟구입 완료 후의 문구(선택)",
            "description": "티켓구입 완료 후, 귀하는 곧바로 짧은 텍스트와 링크를 표시할 수 있습니다."
          },
          "orderSuccessEmail": {
            "label": "E-mail: 티켓 확정 이메일의 문구 (선택)",
            "description": "짧고 강한 문장으로 표현! 수신자에게 감사 인사를 할 필요는 없습니다."
          },
          "saveAlert": {
            "title": "변경 저장",
            "description": "귀하가 작성한 변경사항들을 지금 게시된 이벤트에 대해 저장하시겠습니까? 이 변경사항들은 즉시 적용됩니다."
          },
          "publishAlert": {
            "title": "이벤트 게시",
            "description": "이 이벤트를 게시하고 공개하시겠습니까? 이벤트가 게시된 이후에는, 귀하는 이것을 <b>  </b> 초안에서 더이상 변경할 수 없습니다. 티켓들은 정해진 판매시작일부터 구입이 가능합니다.",
            "shopsTitle": "Your event and the following shops will become public",
            "cancel": "Not now",
            "confirm": "Publish now",
            "warning": "Publishing cannot be undone",
            "shop": "Shop",
            "defaultGate": "Default Gate",
            "opens": "Opens on",
            "immediately": "Immediately",
            "shopsFailed": "Failed to load shops, please try again",
            "tryAgain": "Try again",
            "publishError": "The event could not be published. Please make sure that the payment service provider configuration of your organisation is fully setup.",
            "orgSettings": "Go to settings for: {name}"
          },
          "livestream": {
            "typeSelect": {
              "label": "Select how you would like to configure your livestream (optional)",
              "internal": "Internal: Use your own embed codes",
              "external": "External: Use an external link to redirect to"
            },
            "videoEmbed": {
              "label": "Livestream video embed code (optional)",
              "placeholder": "<iframe src='https://player.vimeo.com/video/123456789/' frameborder=0 allow=autoplay; fullscreen allowfullscreen></iframe>"
            },
            "chatEmbed": {
              "label": "Livestream chat embed code (optional)",
              "placeholder": "<iframe src='https://vimeo.com/live-chat/123456789/' frameborder='0'></iframe>"
            },
            "externalUrl": {
              "label": "Livestream external URL (optional)",
              "placeholder": "https://livestream.com"
            }
          },
          "advanced": {
            "sharing": {
              "title": "Sharing",
              "label": "Ticket sharing between attendees (optional)",
              "strict": "Share via QR code",
              "strictInfo": "Attendees need to meet up in person and scan a QR code to share their tickets with each other",
              "easy": "Share via Link",
              "easyInfo": "Attendees can send a link to invite others to join",
              "disabled": "Not allowed",
              "disabledInfo": "Attendees can't share their tickets for this event"
            },
            "private": {
              "title": "Blockchain Visibility",
              "label": "It's possible to hide your event details on the blockchain. This includes event name, location, image and the shop URL",
              "checkbox": "Do not show event details on the blockchain"
            },
            "orderConfirmEmail": {
              "title": "Order confirmation email",
              "label": "Email address (optional)",
              "placeholder": "example{'@'}organiser.com",
              "info": "We'll send out an e-mail to this address with details on every order made."
            },
            "scanLeeway": {
              "title": "Offline scanning leeway",
              "label": "Set amount of time in minutes",
              "info": "Attendees can enter with a QR code that doesn't require an internet connection. This setting defines how long these offline QR codes are valid."
            }
          },
          "blockchain": {
            "publish": {
              "title": "Visibility",
              "label": "When you publish, your event & shop information will also be visible on the GET Protocol blockchain. You can (optionally) specify when that should happen:",
              "never": "Do not show event details on the blockchain",
              "public": "This event's information has already been made public on the blockchain. It is not reversible.",
              "defaultInfo": "By default this is set to the opening time of your default shop."
            },
            "contractAddress": {
              "title": "Event contract address",
              "placeholderDraft": "Your event contract address will be generated once the event is published.",
              "label": "Below is the event contract address for you to view and verify all transactions.",
              "placeholder": "Generating (refresh the page to update)"
            },
            "nft": {
              "title": "Default Collectible Artwork",
              "label": "Upload your default Collectible artwork here. This design will be used for all ticket's collectibles (unless specifically overwritten within each ticket's settings).",
              "guidelines_title": "General guide lines:",
              "line_1": "PNG, GIF or JPG file type",
              "line_2": "Portrait mode with aspect ratio of 3:4",
              "line_3": "10 MB max file size",
              "line_4": "You can <a href='{href}' download>our template</a> as a base",
              "note": "Note: If no artwork is provided, a default artwork is used by GET Protocol."
            }
          },
          "resale": {
            "title": "Resale Fees"
          },
          "previewModal": {
            "title": "Send preview email",
            "success": "Email has been sent successfully",
            "error": "An error occured sending the email"
          }
        },
        "reports": {
          "sales": {
            "turnover": "매출",
            "capacity": "전체 수량",
            "ticketsSold": "판매된 티켓들",
            "capacityFilled": "Capacity filled",
            "capacitySold": "판매된 수량",
            "tooltipTicketsSold": "판매된 티켓들",
            "availabilityTooltip": "Tickets can share pools",
            "percentageSold": "판매율",
            "revenue": "매출",
            "grossRevenue": "총 매출",
            "graph": {
              "range": {
                "placeholder": "범위를 설정하세요",
                "last_day": "지난 24시간",
                "last_month": "지난 31일",
                "all_time": "전체"
              },
              "title": {
                "last_day": "지난 24시간 동안 판매된 티켓",
                "last_month": "지난 1달 동안 판매된 티켓",
                "all_time": "전체 기간 동안 판매된 티켓"
              }
            }
          },
          "demographics": {
            "avgOrderSize": "평균 구매 수량",
            "totalBuyers": "Total number of buyers",
            "locations": "장소"
          },
          "execution": {
            "checkedInPercentage": "체크인 비율"
          }
        },
        "attendees": {
          "filters": {
            "checkin": {
              "label": "체크인 옵션들",
              "partialExecuted": "부분적으로 체크인 됨",
              "allExecuted": "모두 체크인 됨",
              "notExecuted": "아직 체크인 안됨",
              "all": "모두 보여주기"
            },
            "pools": "티켓 풀을 필터"
          },
          "guestlist": {
            "action": "게스트 추가",
            "addRow": "열 추가하기",
            "guestListName": "게스트리스트 이름",
            "createVariant": "새로운 변수를 만드세요 ",
            "selectTicket": "티켓을 선택하세요",
            "selectVariant": "게스트리스트를 선택하세요",
            "optionsTitle": "무료 게스트리스트에 추가하세요",
            "seating": "사용을 위해서 차단된 좌석들을 선택하세요",
            "selectPool": "어느 티켓 풀을 사용할지 선택하세요",
            "confirmTitle": "게스트리스트 확인"
          },
          "cashier": {
            "selectTickets": "Select tickets",
            "blockCategory": "Block category",
            "ticketsLeft": "Tickets left",
            "specifyAmount": "Please specify how many {ticketName} tickets you would like to add",
            "cart": "Cart ({count})",
            "emailField": "Send confirmation to (optional)",
            "notifyBySms": "Notify via SMS",
            "notifyByEmail": "Notify via email",
            "addTicket": "Add {ticketName} Ticket",
            "addTicketDescription": "From which <strong>availability</strong> do you want to select your tickets from?",
            "addTicketAssignment": "How would you like to assign the seats?",
            "addTicketHowMany": "How many <strong>{ticketName}</strong> tickets from <strong>{shopName}</strong> would you like to add?",
            "addToCart": "Add to cart",
            "emailTooltip": "If no email address is specified, no confirmation email will be send.",
            "ticketsLoading": "Retrieving available tickets, please wait...",
            "tickets": "티켓",
            "issueDigitallyCheck": "Send tickets to phone?",
            "yourOrder": "Your order {orderId} for {customerName}",
            "orderCreated": "Order {orderId} created",
            "issueMore": "Issue more tickets",
            "reserveMore": "Reserve more tickets",
            "bookPayment": "Book payment",
            "distributeTickets": "Distribute tickets",
            "checkinAll": "Check all tickets in now",
            "noTicketsSelected": "No tickets selected",
            "confirmGuestInfo": {
              "confirmTitle": "Confirm Tickets",
              "confirmMainMessage": "Tickets will be linked to the provided phone number(s) and can be accessed by the attendee via the ticket wallet.<br/>Attendees will receive an e-mail confirmation with the event & ticket info.",
              "total": "Total",
              "hasEmail": "Confirmation sent to: {email}",
              "noEmail": "No confirmation sent (no email address provided)"
            },
            "actionTooltip": "Tickets can only be issued when the event has been published",
            "action": "티켓판매",
            "optionsTitle": "티켓판매",
            "categories": {
              "title": "차단 카테고리 선택",
              "description": "다음 리스트는 모두 입석입니다. 티켓이 판매될 카테고리를 선택해 주세요. 카테고리에 충분한 좌석이 없다면 다음에서 수정해 주세요. {0}",
              "categoryLink": "여기."
            },
            "payment": {
              "label": "에 의해서 결제 완료",
              "placeholder": "결제방법"
            },
            "seatingMode": {
              "auto": "Assign seats automatically",
              "manual": "Pick seat(s) now",
              "autoseatInfo": "After event organiser finalises the seating"
            },
            "issue_now_modal": {
              "title": "What do you want to do next?",
              "choices": {
                "issue": "Issue tickets",
                "reservation": "Create reservation"
              }
            },
            "add_to_reservation_modal": {
              "title": "Add selection to reservation",
              "description": "You are about to add your selection to the reservation. Are you sure?"
            },
            "reservationForm": {
              "expiryDate": "Expiry Date",
              "details": "Details",
              "reservationName": "Reservation Name",
              "note": "Note",
              "customMessageContent": "Custom message content",
              "contactDetails": "Contact information",
              "firstName": "First Name",
              "lastName": "Last Name",
              "mobileNumber": "Mobile Number",
              "email": "Email",
              "notePlaceholder": "E.g. Team Building Event",
              "customMessageContentPlaceholder": "E.g. Please bring your ID"
            }
          },
          "blockCategories": {
            "notfound": "카테고리를 찾을 수 없습니다.",
            "createCategory": "카테고리 추가",
            "createCategoryTitle": "새로운 카테고리 추가",
            "deleteTooltip": "빈 카테고리만 제거할 수 있습니다.",
            "editCategory": "카테고리 수정",
            "categoryName": "카테고리 이름",
            "categoryNamePlaceholder": "기술자 등",
            "categoryShortNamePlaceholder": "ABC (max. 3)",
            "categoryShortName": "짧은 코드",
            "uniqueShortNameError": "짧은 코드는 유니크해야 합니다.",
            "uniqueNameError": "이 이름과 같은 카테고리가 이미 존재합니다.",
            "selected": "{total} 중 {selected} 선택됨",
            "unblocked": "차단이 성공적으로 제거되었습니다.",
            "moved": "차단이 성공적으로 카테고리로 이동되었습니다.",
            "printTickets": "PDF로 프린트",
            "movePrivs": "카테고리로 이동",
            "noCategory": "카테고리 없음",
            "editNote": "노트 편",
            "addNote": "Add note | Add notes",
            "floorplanLabel": "카테고리 차단",
            "addPrivs": "차단 추가",
            "addPrivsModal": {
              "title": "다음 카테고리에 차단 추가하기 \"{category}\"",
              "selectPool": "티켓 풀을 선택",
              "addSeated": "특정 좌석을 차단하시겠습니까? {0}",
              "addSeatedLink": "좌석배치도 보기"
            },
            "categorySearch": "카테고리를 선택하세요",
            "details": {
              "ticket": "등급 / 티켓",
              "filterClear": "필터 재설정",
              "filterTicket": {
                "empty": "등급 / 티켓 필터",
                "selected": "{name} 필터"
              },
              "filterSection": {
                "empty": "선택하여 필터",
                "selected": "다음으로 필터: {name}"
              },
              "filterClaimed": {
                "empty": "상태로 필터",
                "selected": "체크인 여부로 필터"
              }
            },
            "list": {
              "fields": {
                "name": "카테고리"
              }
            },
            "editTicketNote": {
              "titleEdit": "Edit note(s)",
              "titleAdd": "Add note(s)",
              "multipleNotes": "You're editing multiple notes, this change will reflect on all the items you selected.",
              "placeholder": "노트"
            },
            "unblockModal": {
              "title": "차단 해제하기",
              "description": "선택된 부분은 차단이 해제되어 판매가능한 상태가 됩니다.",
              "descriptionCheckedIn": "이 작업은 체크인된 차단에는 영향을 미치지 않고 단순히 카테고리 없음으로 분류됩니다."
            }
          },
          "externalCodes": {
            "title": "외부 티켓",
            "ticketInfo": {
              "ticketsImported": "가져오기 된 모든 티켓",
              "lastImport": "마지막 가져오기",
              "ticketsCheckedIn": "체크인 된 모든 티켓"
            },
            "importCSV": {
              "title": "CSV를 사용해서 티켓 가져오기",
              "description": "CSV 파일로 바코드 정보를 가져와 스캐너에서 외부 티켓까지 체크인하세요. <strong>csv</strong> 파일의 구조는 바코드 또는 'barcode'가 행 이름에 포함된 단일 행 형태여야 합니다.",
              "placeholder": "CSV 파일을 선택하거나 여기에 드래그 드롭해 주세요.",
              "deleteAndReplace": "기존 티켓을 대체합니다. 체크인 하지 않은 티켓은 삭제됩니다.",
              "parsingError": "바코드를 찾을 수 없습니다. 단일 행을 사용하거나 행 이름을 'barcode' 로 바꾼 뒤 시도해 보세요.",
              "uploadFailInvalidCSV": "제공된 파일은 유효한 CSV 파일이 아닙니다.",
              "uploadFailNoCodes": "제공된 파일엔 코드가 포함되어 있지 않습니다.",
              "uploadFail": "CSV 파일을 불러올 수 없습니다.",
              "uploadSuccess": "{nbTickets} 티켓 불러오기가 완료되었습니다. 불러온 티켓의 바코드 중 하나입니다: {barcodeExample}",
              "fileTooBig": "CSV 파일의 크기가 너무 큽니다.",
              "emptyFile": "CSV 파일이 비어 있습니다."
            }
          },
          "attendee": {
            "invalidateSuccess": "다음 티켓은 성공적으로 무효화되었습니다.",
            "invalidationFail": "티켓 무효화에 실패했습니다. 다시 시도해 주세요.",
            "invalidateTicketConfirm": {
              "manual": "Manual refund",
              "auto": "Automatic refund",
              "credits": "Refund as credits",
              "noRefund": "Invalidate without refund",
              "creditsExpiration": "Expiration date",
              "creditsConfirm": "These credits will expire on {0} after which they can not be used anymore",
              "titleWithoutRefund": "무효화 확인",
              "refundType": "다음 티켓에 대해 어떻게 환불처리 하시겠습니까?",
              "partialRefundInfos": "한 티켓 당 얼마를 공제할 것인지 입력하세요.",
              "deduct": "To deduct",
              "fullRefund": "전체 금액 환불",
              "partialRefund": "일부 금액 환불",
              "deducted": "제외됨",
              "subtitle": "다음 티켓은 무효화되고 환불처리 될 예정입니다. | 다음 티켓은 무효화되고 환불처리 될 예정입니다:",
              "subtitleWithoutRefund": "다음 티켓은 무효화될 예정입니다. | 다음 티켓은 무효화될 예정입니다:",
              "totalAmount": "총 수량",
              "refundAmount": "환불",
              "partialRefundErrorMessage": "값이 너무 높습니다.",
              "toolTipText": "이 옵션을 선택하지 않는다면 티켓은 다시 판매가능한 상태가 될 것입니다.",
              "selectBlock": "차단 카테고리 선택",
              "blockCheckbox": "차단된 티켓들이 다시 구매가능한 상태가 되었습니다.",
              "feeMessage": "수수료는 다음과 같이 청구됩니다:",
              "summaryMessage": "The following ticket(s) will be invalidated.",
              "category": "The tickets are blocked on category {0}",
              "actionUndoneInfo": "이 작업은 되돌릴 수 없습니다. 진행하시려면 확인을 눌러주세요.",
              "extraConfirmModal": {
                "extraConfirmMessage": "환불되는 티켓 수량은 {0} 입니다. 이 작업은 되돌릴 수 없습니다. 다음 티켓을 무효화하고 환불하는 것이 확실합니까?",
                "extraConfirmTitle": "무효화와 환불 절차 완결",
                "extraFinalizeMessage": {
                  "text": "확인을 위해 하단에 {0} 입력하세요",
                  "finalize": "확인"
                }
              }
            },
            "invalidateTicketOptions": {
              "title": "티켓 무효화하고 환불하기",
              "missingPaymentMethod": "지불 방법 선택하기",
              "subtitle": "무효화 하고자 하는 티켓에 대해 환불 절차를 어떻게 진행할 것인지 선택하세요:",
              "tooltip": "환불 가능한 값이 구매한 값보다 적습니다. 이는 여러 방법으로 결제했거나 부분 결제 때문일 수 있습니다.",
              "automatic": {
                "title": "자동 환불 처리로 티켓 무효화하기",
                "description": "자동으로 결제한 금액 환불하기 입니다. 구매자나 행사 주최측이 수수료를 부담할 경우 다음 단계를 선택하세요."
              },
              "credits": {
                "title": "Refund as credits",
                "description": "Refund the amount and add these funds to account of the user to be redeemed later."
              },
              "manual": {
                "title": "수동 환불 처리로 티켓 무효화하기",
                "description": "다른 지불 수단으로 수동으로 환불 처리하고 통계 보고서에 반영합니다. 구매자나 행사 주최측이 수수료를 부담할 경우 다음 단계를 선택하세요."
              },
              "noRefund": {
                "title": "환불 없이 티켓 무효화하기",
                "description": "환불 없이 티켓 무효화를 선택하면 통계 보고서에는 제외되지 않고 처음 지불한 금액이 반영됩니다."
              }
            },
            "ticketBuyer": "구매자",
            "noGender": "성별 표시 안함",
            "noAge": "연령 표시 안함",
            "noZipcode": "주소 없음",
            "noCountry": "국가 없음",
            "noPhoneNumber": "No phone number",
            "noEmail": "이메일 없음",
            "noWalletLink": "No wallet link",
            "ticketOwner": "Owns tickets",
            "activeGroupMember": "Group member",
            "inactiveGroupMember": "Has left group",
            "customerTickets": "Tickets",
            "customerOrders": "Orders",
            "customerPayments": "Payments",
            "customerRefunds": "Refunds",
            "groupTickets": "Group tickets",
            "share": {
              "action": "티겟 공유",
              "label": "모든 티켓들을 다음 사람들과 공유함:",
              "description": "기존 혹은 신규 계정",
              "newUser": "신규 계정",
              "anonymousUser": "익명 계정",
              "alert": {
                "title": "익명 계정",
                "description": "귀하의 티켓들 을 <strong>{from}</strong>부터 <strong>{to}</strong>까지 함께 공유하시길 원하는게 확실합니까?",
                "newUserNotice": "신규 계정을 만듬",
                "userNotice": "티켓들은 다음 계정과 함께 공유된: <strong>{name}</strong>."
              }
            },
            "remove": {
              "title": "티켓 삭제 | 티켓 삭제",
              "description": "귀하는 이 티켓을 삭제하고자 하는게 확실합니까? | 귀하는 이 티켓들을 삭제하고자 하는게 확실합니까?"
            },
            "checkin": {
              "title": "Check-in",
              "description": "이 티켓들을 체크인하는게 확실합니까?",
              "combiTickets": "콤비 티켓들에서 체크인하고자 하는게 무엇인지 선택하세요:"
            },
            "undoCheckin": {
              "title": "Undo check-in",
              "description": "Are you sure that you want to undo check-ins for these privileges?",
              "combiTickets": "Confirm which privilege(s) you want to undo check-in for:"
            },
            "email": {
              "title": "이메일 참석자",
              "error": "이 이메일은 유효하지 않습니다!",
              "sendEmails": "이메일을 보냄",
              "orderEmail": "결제 확인",
              "seatingEmail": "좌석배치 정보"
            },
            "convert": {
              "selectOption": "전환 옵션을 선택하세요",
              "convertTo": "로 전환시킴",
              "selectPool": "어느 티켓 풀을 사용할지 선택하세요",
              "selectKind": "티켓을 선택하세요",
              "selectShop": "Select shop",
              "left": "잔여: {amount}",
              "paymentMethod": "에 의해서 금액이 수령됨",
              "paymentOptions": "{method} 혹은 {free}",
              "sendEmail": "고객에게 변경확정 이메일을 보내세요",
              "selectPayment": "지불방법",
              "selectPaymentError": "Select payment method",
              "total": "가격차이 총액:",
              "new": "새 것을 만드세요",
              "confirmConversion": "전환을 확정하세요"
            },
            "ticketDetails": {
              "id": "ID",
              "price": "Price",
              "order": "Order",
              "orderDate": "Order date",
              "shop": "Shop",
              "ownedBy": "Owned by",
              "status": "Status",
              "resoldBy": "Resold by",
              "on": "On",
              "onDate": "on {date}",
              "sellerPrice": "Seller Price",
              "buyerPrice": "Buyer Price",
              "buyerFees": "Buyer Fees",
              "sellerFee": "Seller Fees",
              "invalidatedBy": "Invalidated by",
              "refundMethod": {
                "cash": "Cash",
                "pin": "Pin",
                "digital": "Digital",
                "other": "Other"
              },
              "fees": "Fees",
              "payOut": "Payout",
              "resoldTo": "Resold to",
              "ticketId": "Ticket ID",
              "putForSaleBy": "Put for sale by",
              "privileges": "Privileges",
              "boughtBy": "Bought by",
              "boughtFrom": "Bought from",
              "note": "Note"
            }
          }
        },
        "seating": {
          "legend": "개요",
          "sectionList": "구역",
          "editNote": "수정",
          "standingSection": "입석",
          "capacity": "총 좌석 수",
          "overview": "전체보기",
          "notePlaceholder": "설명 추가",
          "notEnoughFree": "무료 없음",
          "changes": "Changes",
          "seatClaimed": "체크인 된 티켓들은 바꿀 수 없습니다.",
          "categoryInfo": "특정한 차단 카테고리에서 좌석을 배정하려면 다음 하단 리스트에서 카테고리를 선택하세요. 만약 선택하지 않는다면 선택된 좌석들은 카테고리 없음으로 분류됩니다.",
          "assign": {
            "title": "참석자를 선택함",
            "showAssigned": "배치를 표시함",
            "allTicketTypes": "모든 티켓 타입들",
            "onlyMultipleTicketTypes": "오직 복수 티켓 타입들"
          },
          "solve": {
            "title": "모든 참석자들을 자동배치함",
            "description": "자동 배치를 실시하면 모든 참석자들은 자기 그룹의 좌석등급에 따라서 자동적으로 최선의 좌석들이 배정됩니다. 별개의 그룹들을 함께 좌석배치 하기를 원하시나요? 그런 경우에는 옵션으로 귀하가 한 그룹안에 배치하고자 하는 계정들의 최대 3개의 스마트폰 번호들을 한 그룹안에 기입하세요.",
            "groupPlaceholder": "스마트폰 번호",
            "addGroup": "그룹 추가",
            "success": "Successfully solved!"
          },
          "finalize": {
            "title": "현재 좌석 배치를 최종적으로 확정하세요",
            "description": "최종 확정할까요? 최종 확정 후에는 더 이상 변경을 할 수 없습니다. 방문자들은 이메일과 문자메시지로 좌석 배치를 받게 됩니다.",
            "instructions": "최종 확정 하려면 아래 칸에 {0}을 입력하세요:",
            "success": "Successfully finalized!"
          },
          "clearSuccess": "Successfully cleared!"
        },
        "scanning": {
          "actions": {
            "create": "Create profile",
            "edit": "Edit profile",
            "cannotEdit": "This profile cannot be edited"
          },
          "creation": {
            "description": "Create a profile which, when loaded in the scanner app, will filter and only show tickets that use the specified pools."
          },
          "description": "{poolAmount} pool can be scanned with this scanning profile. Scan the following QR code to log in. | {poolAmount} pools can be scanned with this scanning profile. Scan the following QR code to log in.",
          "mainProfile": {
            "title": "Main",
            "description": "This is the main profile which allows scanning of all pools. Scan the following QR code to log in.",
            "warning": "Attention! This QR code only allows scanning of this specific event. If you want to be able to scan all your (future) events {0}."
          }
        },
        "shop": {
          "deleteShopModalTitle": "이 샵 삭제하기",
          "deleteShopMessage": "이 샵을 삭제하는 것이 확실합니까?",
          "deleteShopTooltip": "샵을 삭제하려면 모든 티켓을 삭제해야 합니다.",
          "copyInfo": "All tickets and shop layout will be copied to a new shop. Click Confirm if you wish to continue.",
          "notfound": "샵이 없습니다.",
          "title": "티켓 생성",
          "description": "행사에 티켓이나 콤비 티켓을 추가하세요.",
          "ticketModalTitle": "티켓을 생성하기 위해 하나의 풀을 선택하세요.",
          "vat": "부가세",
          "baseTicket": "일반 티켓",
          "deleteHelper": "티켓은 아직 판매되지 않은 경우에만 삭제할 수 있습니다.<br>귀하는 언제라도 티켓을 숨길 수 있습니다.",
          "newTicket": "새로운 티켓",
          "price": "총 가격",
          "tax": "부가세(포함)",
          "breakdown": "가격분표",
          "artwork": {
            "toggle": "Collectible Artwork",
            "error": "Your image could not be uploaded. Please try again later.",
            "title": "Upload your Collectible artwork for this ticket which will override any default Collectible artwork set under the event's Blockchain tab.<br><br>You can update this artwork at any point in the event lifecycle.",
            "template": "download template",
            "update": "Update art for NFT's already minted",
            "guidelines": {
              "title": "General guide lines",
              "line_1": "PNG, GIF or JPG filetype",
              "line_2": "Portrait mode with aspect ratio of 3:4",
              "line_3": "10 MB max file size",
              "line_4": "You can use <a href='{href}' download>our template</a> as a base"
            },
            "note": "Note: Unless you upload an image, your event NFT artwork will be used by default."
          },
          "availabilitySettings": "Availability settings",
          "advanced": "고급 설정",
          "maxTicketsLabel": "Ticket limit",
          "putForSale": "티켓 판매를 위해 내놓기",
          "from": "부터 구입가능",
          "till": "까지 구입가능",
          "defaultShopTooltip": "Use this shop by default when a user shares a link to this event.",
          "nameInfo": "Used in dashboard only",
          "ticketLimitInfo": "Maximum number of tickets a user can buy in this shop",
          "advancedEditPoolsLabel": "Set the number of privileges the user receives with this ticket (max 20)",
          "maxAmount": "Max amount",
          "pages": {
            "deletePageModalTitle": "Delete page",
            "deletePageMessage": "All the tickets in this page will be moved to the first page. Are you sure you want to delete this page?",
            "actions": {
              "moveToPage": "Move to page",
              "create": "Create page",
              "edit": "Edit page"
            }
          },
          "access": {
            "title": "Links & embed codes for shop - {name}",
            "linksTitle": "This shop is accessible through the following gates & links:",
            "description": "Gates can hold multiple shops across different events for the user to pick from. By default each shop has it's own single gate (link).",
            "draftAlert": "This event is not published yet. The gate(s) won't show this event until it's published.",
            "singleGate": "Single shop gate"
          },
          "availability": {
            "title": "Availability",
            "description": "By default tickets are available <strong>{from}</strong> until <strong>{ends}</strong>. The maximum number that can be ordered in this shop is <strong>{amount}</strong> tickets per account.",
            "immediately": "immediately",
            "eventEnds": "the event ends",
            "relative": "{days} days before the event ends, at {time}",
            "absoluteStart": "from {date}",
            "startLabel": "일반 티켓 판매 시작 시각",
            "endLabel": "글로벌 티켓 판매 종료 시각"
          },
          "accessControl": {
            "title": "Access to Shop",
            "description": "Access to the shop will be provided once a collectible out of the selected collection(s) is found in the customer's wallet. Select the OpenSea collection(s) below that can be used to grant access to the shop.",
            "linkLabel": "Link to OpenSea",
            "urlPlaceholder": "opensea.io/...",
            "collectionsListLabel": "Collections selected",
            "errors": {
              "collectionNotFound": "The collection was not found.",
              "invalidUrl": "This is not a valid OpenSea collection URL.",
              "duplicateUrl": "This collection is already added.",
              "wrongChain": "The chain where this collection is deployed is not supported. Currently, we only support Ethereum Mainnet, Polygon Mainnet and Polygon Mumbai Testnet.",
              "openseaError": "An error occurred while fetching the collection. Please try again later.",
              "invalidCollection": "This collection doesn't have an NFT contract.",
              "noCollections": "No collections selected, please add at least one collection."
            }
          },
          "empty": {
            "action": "귀하의 첫 티켓을 만드세요",
            "title": "티켓을 만듬"
          },
          "actions": {
            "createShop": "샵 생성하기",
            "copyShop": "Copy shop",
            "newShop": "New shop",
            "editShop": "샵 편집하기",
            "createGroup": "그룹 생성하기",
            "editGroup": "그룹 수정하기",
            "createNewPool": "풀 생성하기",
            "addTicket": "티켓 생성하기",
            "addRegular": "일반 티켓",
            "addCombi": "콤비 티켓",
            "addUpsell": "추가판매",
            "addGuestVariant": "게스트 리스트",
            "addGroup": "그룹 추가",
            "saveLayout": "레이아웃 저장",
            "saveDraft": "초안 저장하기",
            "addField": "항목을 추가하세요",
            "saveTemplate": "Save template"
          },
          "group": {
            "ticketPlaceholder": "그룹을 만들려면 여기로 티켓을 드래그하세요",
            "collapseCheckbox": "기본으로 축소하기",
            "messagePlaceholder": "티켓 리스트 그룹 메시지",
            "defaultTitle": "새로운 그룹"
          },
          "blocksHelper": {
            "title": "차단 수량 변경을 원하세요? {0}",
            "link": "차단 카테고리 가기"
          },
          "gates": {
            "title": "This shop is accessible through the following gates (links)",
            "description": "Gates can hold multiple shops across different events for the user to pick from. By default, each has its own (single) gate.",
            "localGate": "This shop",
            "defaultGateError": "Each shop is required to have a default gate. Please set a default gate by clicking on one of the stars.",
            "defaultTooltip": "Use this gate by default when linking to this shop"
          }
        },
        "checkin": {
          "title": "Self check-in",
          "description": "Scan the QR code to check-in attendees",
          "waiting": "Waiting for QR code...",
          "AllClaimed": "All claimed",
          "checkin": "Check-in {amount} ticket(s)",
          "success": "{amount} ticket(s) checked in succesfully!",
          "errors": {
            "noPrivileges": "This QR code does not contains tickets",
            "wrongEvent": "The QR code belongs to a different event"
          }
        },
        "pools": {
          "title": "풀",
          "addNew": "풀 생성하기",
          "notFound": "풀 찾을 수 없음",
          "poolSelection": "Select a pool or multiple pools to create a ticket. For upsell products, use the upsell pools. If you don't find the pool you are looking for, you can",
          "createNewPool": "create a new one.",
          "guestTicket": "This is a guest ticket",
          "toolTipEdititon": "이 풀은 수정할 수 없습니다.",
          "poolModal": {
            "ticketPrivilege": "ticket-privileges",
            "privilege": "privilege",
            "entrance": "Entrance",
            "upsell-text": "Upsell",
            "create": "새 풀 만들기",
            "description": "A privilege pool is used to determine the total number of so called {ticketPrivileges} (or {privilege} in short), that can be issued. A privilege is of the type {Entrance} or {Upsell}. 1x capacity equals 1 privilege.",
            "update": "풀 수정하기",
            "name": "이름",
            "validFrom": "Valid to be scanned from",
            "validTo": "Valid to be scanned until",
            "blocks": "차단 수 (전체에서 제외)",
            "totalAmoutOfTickets": "총 티켓 수량",
            "blockHelperText": "차단 수와 카테고리는 나중에 얼마든지 수정할 수 있습니다.",
            "poolNameHelperText": "For instance 'VIP' or 'T-Shirt'",
            "totalCapacityHelperText": "Maximum capacity to be issued",
            "upsell": "이것을 사용해서 추가 판매를 할 수 있습니다. 남은 티켓 수량은 추가 판매에 영향을 미치지 않습니다.",
            "deleteMessage": "이 풀을 삭제하길 원하십니까?",
            "deleteTitle": "삭제",
            "privilegeType": "Privilege type",
            "tellMeMore": "Tell me more",
            "listItem1": "Use to grant access to an event (or livestream)",
            "listItem2": "Total amount of tickets a user can get of this type can be limited per shop ",
            "listItem3": "Use for additional services/products (like drinks/merchandise)",
            "listItem4": "Does not count towards the users' amount of tickets limited per shop"
          },
          "details": {
            "sold": "팔림",
            "tickets": "티켓",
            "shop": "샵",
            "price": "가격"
          }
        }
      }
    },
    "reservations": {
      "addTickets": "Add tickets",
      "ticketsCount": "Ticket ({count}) | Tickets ({count})",
      "unclaimedTicketCount": "Unclaimed ({count})",
      "claimedTicketCount": "Claimed ({count})",
      "reservationsOverviewTitle": "Your reservations",
      "draftConfirmInfo": "Publish the event to enable confirming",
      "details": {
        "editTitle": "Edit reservation details"
      },
      "cancel": {
        "cancelTitle": "Cancel reservation",
        "cancelDescription": "Are you sure you want to cancel the reservation? This cannot be undone.",
        "yesCancelReservation": "Yes, cancel reservation",
        "blockTickets": "Block tickets that are available again",
        "blockTicketsDescription": "If you don't select this option, tickets will be up for sale again"
      },
      "confirm": {
        "title": "Confirm tickets",
        "descriptions": {
          "paidConfirmAll": "Are you sure you want to confirm the sale of all tickets in this reservation? Once confirmed, you'll be able to distribute the tickets. Please specify the payment method:",
          "freeConfirmAll": "Are you sure you want to confirm the sale of all tickets in this reservation? Once confirmed, you'll be able to distribute the tickets.",
          "paidConfirmPartial": "Are you sure you want to confirm the sale of the selected tickets in this reservation? Once confirmed, you'll be able to distribute the tickets. Please specify the payment method:",
          "freeConfirmPartial": "Are you sure you want to confirm the sale of the selected in this reservation? Once confirmed, you'll be able to distribute the tickets."
        }
      },
      "release": {
        "action": "Release",
        "title": "Release tickets",
        "description": "Are you sure you want to release the selected tickets from the reservation?"
      },
      "distributionModal": {
        "title": "Distribute tickets ({count})",
        "description": "Fill the email addresses of people to whom you want to distribute the selected tickets.",
        "allToOne": "All tickets to one person",
        "invitationEmail": "Invitation email address",
        "confirmText": "Send invitations"
      },
      "distribution": {
        "title": "Distribute Tickets",
        "emails": {
          "title": "Distribute tickets to the following email addresses",
          "info": "You can also enter multiple entries separated by a space, comma, or semi-colon.",
          "duplicates": {
            "title": "Duplicates",
            "message": "The following duplicate emails were not added to the list: <strong>{list}</strong>"
          },
          "errors": {
            "exceedsMax": "{count} out of {max} people added. Remove {remove_count} entries or reduce the number of tickets to distribute per person.",
            "validation": "One or more added email addresses are not valid, please correct them!"
          },
          "entriesLeft": "{count} entries left"
        },
        "tickets": {
          "title": "Each entry will receive the following tickets",
          "ticketLeft": "ticket left | tickets left"
        },
        "send": {
          "title": "Send invitations",
          "summary": {
            "title": "Summary",
            "description": "Each invitation contains the following ticket(s):"
          },
          "extraMessage": {
            "title": "Personal message",
            "description": "If you want you can add a short extra message to the ticket invitation email."
          },
          "sendAction": "Send invitations",
          "success": "Successfully created and sent out invitations!",
          "errors": {
            "create": "Failed to create invitations!",
            "send": "Failed to send invitations"
          }
        },
        "resend": {
          "title": "Resend invitation",
          "areYouSure": "Are you sure want to resend this invite?",
          "description": "We'll send another invitation to \"{email}\"",
          "error": "Failed to send invitation!",
          "success": "Succesfully sent out invitation!"
        },
        "revoke": {
          "title": "Revoke invitation",
          "areYouSure": "Are you sure want to revoke this invite?",
          "description": "After revoking, the tickets will be available again in the reservation to distribute.",
          "error": "Failed to revoke invitation!",
          "success": "Succesfully revoked invitation!"
        }
      }
    },
    "scanning": {
      "selectOrganisation": "Select an organisation to be able to see the scanning login information",
      "selectOrganisationMessage": "행사업체를 선택하십시오",
      "organisations": "Organisations",
      "scannerInfo": "Use the information below to login to the scanner. Keep in mind, this login information is shared across the organisation and it is not specific to an event.",
      "downloadInfo": "Download the GET In app on your smartphone:",
      "passwordInfo": "Use the password or scan the QR code to login",
      "noOrganisationSelected": "You haven't selected an organisation yet."
    },
    "navigation": {
      "eventPreview": {
        "label": "이벤트 초안을 프리뷰하세요",
        "title": "귀하의 이벤트를 프리뷰하세요",
        "content": "귀하의 이벤트를 살펴본 후에 모든 내용들이 정확하게 기입되었는지 테스트하기 위해서 테스트 티켓을 구입해 보세요.<br><br>이 프리뷰는, 실제적인 지불은 제외하고는, 방문자들의 구매 경험과 동일합니다. 테스트 보기에서 귀하가 테스트 티켓들을 구입할 수 있도록 테스트 결제를 사용합니다.<br><br>테스트 티켓들은 귀하의 계정에 그대로 남아 있지만, 실제적인 판매 및 숫자들에 영향을 미치지 않습니다."
      },
      "eventUrlInfo": "귀하의 이벤트는 게시된 후에 이 주소에서 접속할 수 있습니다",
      "back": "모든 행사들로 돌아감"
    },
    "prices": {
      "title": "Your prices",
      "addPrice": "Create price type",
      "editPrice": "Edit price",
      "editOverviewDescription": "The following events and tickets share this same price type. Editing this price type, will affect all of them. If you wish to continue click confirm.",
      "table": {
        "name": "Price type name",
        "amount": "Amount",
        "usedBy": "Used by"
      },
      "priceSelect": {
        "noAvailable": "There are no global price types available for this organisation."
      }
    },
    "gates": {
      "title": "Your gates",
      "open": "Open date",
      "close": "Close date",
      "slug": "Slug",
      "create": "Create gate",
      "gate": {
        "description": "This gate is accessible when the first shop opens <strong>{start}</strong> until the last event in this gate ends<strong>{ends}</strong>.",
        "descriptionPrequeue": "Prequeue is <strong>enabled</strong>.",
        "descriptionAccess": "Access links are <strong>enabled</strong>.",
        "gateLinks": {
          "title": "Link & embed codes of gate",
          "linkInfo": "Always use this link when sharing the URL - Do not copy it from the browser bar.",
          "link": "Direct link to this gate",
          "embed": "Embed code of this gate"
        },
        "accessLinks": {
          "nav": "Access links",
          "title": "Generate access links",
          "description": "Fill in the amount of access links you want and click Export as csv. The links below are <strong>example</strong> links.",
          "action": "Export as csv"
        }
      },
      "gateSelect": {
        "noAvailable": "There are no gates available for this organisation. You can create a new one."
      },
      "shops": {
        "pastLabel": "Show past events",
        "searchPlaceholder": "Search for events or shops...",
        "nonGateShopsTitle": "All events & shops",
        "gateShopsTitle": "Shops in this gate ({count})",
        "addToGate": "Add ({count}) shop | Add ({count}) shops",
        "removeFromGate": "Remove ({count}) shop | Remove ({count}) shops",
        "selectInfo": "To select multiple shops, you can press shift or control (command on Mac) and select, or click and drag."
      },
      "modal": {
        "create": "Create gate",
        "edit": "Edit gate",
        "optionalSlug": "Slug (optional)",
        "nameDescription": "This name is only used in dashboard as an identifier",
        "delete": {
          "title": "Delete gate",
          "description": "Are you sure you want to delete this gate? If yes, click confirm."
        },
        "display": {
          "title": "Display",
          "description": "Select how you want to display your events:",
          "eventList": "Show events in list",
          "calendar": "Show events in calendar",
          "override": "Override event information",
          "overrideDescription": "By default, this information is set automatically based on the events added to this gate."
        },
        "access": {
          "title": "Access",
          "accessLinks": "Requires access links",
          "accessLinksDescription": "This will make this gate accessible only for people with an access-link",
          "prequeue": "Enable prequeue",
          "prequeueDescription": "This allows users to register in the queue before the sale starts"
        }
      }
    },
    "templates": {
      "title": "Your templates",
      "createTemplate": "Create template",
      "selectTemplate": "Select template",
      "noAvailable": "No template available",
      "templateNotFound": "Template not found...",
      "status": {
        "syncing": "Syncing...",
        "unsynced": "Out of sync",
        "synced": "All synced"
      },
      "sync": {
        "title": "Sync template changes to upcoming events",
        "description": "You made changes to this template. In order to update all events using this template, you can sync your changes here. Keep in mind <b>all changes</b> made will be applied to <b>all upcoming events</b> created using this template.",
        "action": "Sync changes",
        "syncNow": "Sync now",
        "changesApplied": "Your changes will be applied to these {0} upcoming events",
        "changesApplying": "All {0} upcoming events are in sync!",
        "eventsSyncing": "Syncing of events in progress...",
        "SyncStarted": "Sync started on {date}",
        "lastSync": "Last synced on {date}",
        "errors": {
          "nothingToSync": "This template is already all synced",
          "beingSynced": "This template is already being synced"
        },
        "warningModal": {
          "description": "All changes in this template with be synced to all upcoming events managed by this template. There are a few rules that might be applied to avoid any errors:",
          "lowerCapacity": "If you have lowered the capacity of pools but event(s) already sold more tickets than the lowered capacity then the pool size for that specific event will be set to what is currently sold. For example: capacity changed from 100 to 50 - event already sold 60 - pool size for that specific event will be set to 60.",
          "removeTickets": "When you have removed tickets that are already sold for specific events, those tickets will not be removed, but hidden (in shops) instead.",
          "removeShop": "When you have removed a shop but specific events still have tickets in that specific shop then that shop will not be removed from that event but closed instead."
        }
      },
      "templateAlert": "Keep in mind! If you wish to make changes, all events related to this template will be updated, too.",
      "createFromTemplate": {
        "title": "Create event from template",
        "createInfo": "All template settings will be copied to this event.",
        "templateInfo": {
          "text": "You can create a new template {link}.",
          "link": "here"
        },
        "timeInfo": "Local time: {timezone}, {time}. The time of the event is configured from the template.",
        "errors": {
          "templateBeingSynced": "You cannot create an event from this template because it's currently being synced.",
          "templateNotSynced": "You cannot create an event from this template because it' not synced."
        }
      }
    },
    "schedules": {
      "title": "Your schedules",
      "create": {
        "title": "Create schedule"
      },
      "schedulePatterns": {
        "description": "The following templates will be used to generate multiple events for the period you specify.",
        "action": "Add template",
        "days": "Days",
        "exceptions": "Exceptions",
        "modal": {
          "addTemplate": "Select the template to add to the schedule",
          "edit": "Edit schedule pattern",
          "pattern": "On which days of the week do the events occur?",
          "exception": "Add exception date (optional)",
          "addException": "Add exception"
        }
      },
      "entries": {
        "startDate": "Startdate",
        "endDate": "Enddate",
        "description": "Generate new events from the templates by specifying the first and last day. Exception dates will be skipped.",
        "generate": "Generate events",
        "syncWarning": "Events can't be generated because one or more templates are out of sync! Please sync your templates first before generating new events.",
        "newItemStatus": "<strong>{patternName}</strong> events will be generated",
        "confirmDescription": "The following events will be generated and published:",
        "confirmContinue": "If you wish to continue click confirm.",
        "rangeAlert": "You can only generate maximum 1 year ahead at once. The enddate should be before {maxDate}.",
        "countAlert": "You are about to create {count} new events at once. Are you sure?",
        "tooltip": {
          "existing": "Existing",
          "generate": "To generate"
        }
      }
    },
    "cashier": {
      "inDraft": "This event is not published yet",
      "inDraftInfo": "You can only create reservations in draft events. Issuing tickets directly through the cashier or confirming/distributing reserved tickets can only be done once the event is published. If you want to get full access to the features, {0}.",
      "inDraftInfoCallToAction": "publish this event",
      "filters": {
        "available": "Available",
        "blocks": "Blocks",
        "fromBlocks": "From blocks",
        "price": "Price",
        "type": "Type",
        "ticket": "Ticket",
        "upsell": "Upsell",
        "shop": "Shop",
        "pool": "Pool",
        "lockedShop": "You can only select tickets from {shopName} shop. To select a different shop you will have to empty your cart first.",
        "lockedShopReservation": "You can only select tickets from {shopName} shop. to select a different shop you will have to create additional reservation."
      },
      "abortAction": {
        "title": "Cancel order",
        "description": "Are you sure you want to cancel this order? All tickets in the cart will be removed and you will not be able to undo this action.",
        "confirm": "Yes, cancel this order"
      },
      "removeTickets": {
        "title": "Remove {ticketName} tickets",
        "description": "How many {ticketName} tickets do you want to remove?",
        "max": "max"
      },
      "removeSeatedTickets": "Which tickets do you want to remove?",
      "removeAll": {
        "title": "Remove all",
        "description": "Do you want to remove all tickets from the cart?",
        "confirm": "Remove all"
      },
      "removeOne": {
        "title": "Remove ticket",
        "description": "Are you sure you want to remove this ticket?",
        "confirm": "Remove ticket"
      },
      "confirmationModal": {
        "titleOrder": "Create order",
        "titleReservation": "Create reservation",
        "description": {
          "detailsOnly": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will not receive any confirmation.",
          "detailsAndSMS": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS.",
          "detailsAndEmail": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive an email confirmation with the event & ticket information.",
          "detailsSMSAndEmail": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS and an email confirmation with the event & ticket information.",
          "onlyEmail": "Tickets are not linked to the customers account because no phone number was provided. If you want to give the customer the tickets, you need to print the order. The customer will not receive any confirmation.",
          "onlySMS": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS.",
          "onlyNumber": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will not receive any confirmation."
        },
        "notifySMS": "Notify via SMS",
        "notifyEmail": "Notify via email",
        "reservation": {
          "name": "Reservation name",
          "note": "Note",
          "expiry": "Expiry"
        }
      },
      "complete": "Complete order"
    }
  },
  "errors": {
    "not-found": {
      "title": "Can’t access this page",
      "description": "You either do not have permission to view this page or it simply does not exist.",
      "contactSupport": "If you believe this to be an error, contact the organisation you trying to access, or {0} for help.",
      "link": "contact support"
    }
  },
  "filter": {
    "filterBy": "Filter by {name}",
    "operators": {
      "e": "Equals to",
      "lte": "Less than or equal to",
      "gte": "Greater than or equal to",
      "lt": "Less than",
      "gt": "Greater than"
    },
    "timeOperators": {
      "last": "Is in the last",
      "equal": "Is equal to",
      "between": "Is between",
      "after": "Is after",
      "onafter": "Is on or after",
      "before": "Is before",
      "onbefore": "Is before or on"
    },
    "times": {
      "days": "Days",
      "weeks": "Weeks",
      "months": "Months",
      "years": "Years"
    },
    "aria": {
      "open": "Open filter menu",
      "close": "Close filter menu",
      "remove": "Remove filter",
      "add": "Add {name} filter",
      "openAdd": "Open add filter menu",
      "clearAll": "Clear all filters"
    },
    "addFilter": "Add filter",
    "clearAll": "Clear all",
    "select": "Select",
    "selectAll": "Select all",
    "between": "And"
  },
  "resaleFeeConfig": {
    "sellerTitle": "Seller fees",
    "buyerTitle": "Buyer fees",
    "fixedTitle": "Fixed fees",
    "fixedLabel": "Fixed fee per ticket",
    "percTitle": "Percent fees",
    "percLabel": "Percent fee per ticket",
    "totalLabel": "Total fee",
    "getFeeLabel": "Base - GET International",
    "partnerFeeLabel": "Ticketeer fee",
    "override": {
      "label": "Ticketeer fee override",
      "confirm": {
        "title": "Override Ticketeer default fees",
        "description": "Are you sure you want to override the resale market fees for this organization? Any changes to the resale market fee plans will only apply for newly published events. Events that are currently on sale will retain the current fee configuration."
      }
    }
  }
}
