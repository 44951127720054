import mutations from './mutations';
import actions from './actions';

const state = {
  cart: {
    tickets: [],
    isExpired: false,
  },
  issueInfo: {
    issueDigitally: true,
    paymentMethod: '',
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    sendSms: true,
    sendEmail: true,
    hasErrors: false,
    existingCustomer: false,
    isNameEditable: true,
  },
  reservationInfo: {
    ownerFirstName: '',
    ownerLastName: '',
    ownerPhone: '',
    ownerEmail: '',
    reservationExpired: undefined,
    reservationName: '',
    reservationNote: '',
    hasErrors: false,
  },
  order: null,
};

export default (app) => ({
  namespaced: true,
  state,
  mutations: mutations(app),
  actions: actions(app),
});
