/* eslint-disable max-classes-per-file */
import { AUTH_APP } from '@/helpers/constants';
import type { SharedStorage } from '@/plugins/sharedStorage';
import type { AxiosInstance } from 'axios';
import type { Router } from 'vue-router';

export interface AuthType {
  authenticate(token: string, refreshToken: string): void;
  logout(): void;
  authenticated(): boolean;
  tryUseRefresh(): Promise<void>;
  hasTriedToAuthenticate: boolean;
  redirect: any;
}

const REFRESH_TOKEN_KEY = 'refresh_token';

export class PromiseSubscriber<T> {
  private subscribers: Array<[(value: T) => void, (reason?: any) => void]>;

  private fn: () => Promise<T>;

  constructor(fn: () => Promise<T>) {
    this.subscribers = [];
    this.fn = fn;
  }

  subscribe(): Promise<T> {
    const p = new Promise<T>((resolve, reject) => {
      this.subscribers.push([resolve, reject]);
    });

    if (this.subscribers.length === 1) {
      this.fn()
        .then((result) => {
          this.subscribers.forEach(([rs]) => rs(result));
          this.subscribers = [];
        })
        .catch((e) => {
          this.subscribers.forEach(([, rj]) => rj(e));
          this.subscribers = [];
        });
    }

    return p;
  }
}

class Auth implements AuthType {
  private _storage: SharedStorage;

  private _axios: AxiosInstance;

  private _authenticated: boolean;

  public hasTriedToAuthenticate: boolean;

  public redirect: any;

  constructor(storage: SharedStorage, axios: AxiosInstance) {
    this._storage = storage;
    this._axios = axios;
    this._authenticated = false;
    this.hasTriedToAuthenticate = false;
    this.redirect = null;
  }

  private _getRefreshToken(): string | null {
    return this._storage.getItem(REFRESH_TOKEN_KEY) as string;
  }

  public async tryUseRefresh(): Promise<void> {
    const rt = this._getRefreshToken();
    if (!rt) {
      throw new Error('No refresh token!');
    }

    const {
      data: { refresh_token, jwt_token },
    } = await this._axios.post<{ refresh_token: string; jwt_token: string }>('api-token-refresh/', {
      refresh_token: rt,
      app: AUTH_APP,
    });

    this.authenticate(jwt_token, refresh_token);
  }

  public authenticate(token: string, refreshToken: string): void {
    this._storage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    this._axios.defaults.headers.common.Authorization = `JWT ${token}`;
    this._authenticated = true;
    this.hasTriedToAuthenticate = true;
  }

  public logout(): void {
    delete this._axios.defaults.headers.common.Authorization;
    this._storage.removeItem(REFRESH_TOKEN_KEY);
    this._authenticated = false;
    this.hasTriedToAuthenticate = false;
  }

  public authenticated(): boolean {
    return this._authenticated;
  }
}

function setupRefreshOnFailure(
  subscriber: PromiseSubscriber<void>,
  auth: Auth,
  axios: AxiosInstance,
  router: Router,
): void {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const status = error.response ? error.response.status : null;

      if (status === 401) {
        try {
          await subscriber.subscribe();
        } catch (e) {
          auth.logout();
          router.push({ name: 'login' });
          throw error;
        }
        error.config.headers.Authorization = axios.defaults.headers.common.Authorization;
        return axios.request(error.config);
      }

      throw error;
    },
  );
}

export default {
  install(
    app: any,
    { storage, axios, router }: { storage: SharedStorage; axios: AxiosInstance; router: Router },
  ): void {
    const auth = new Auth(storage, axios);
    const subscriber = new PromiseSubscriber<void>(auth.tryUseRefresh.bind(auth));

    setupRefreshOnFailure(subscriber, auth, axios, router);

    app.config.globalProperties.$auth = auth;

    app.provide('auth', auth);
  },
};
