<template>
  <div class="login-box">
    <div class="login-header">
      <h2 class="login-title">{{ $t('views.account.login.title') }}</h2>
    </div>
    <GForm class="form" @submit.prevent="tryLogin">
      <GFormGroup
        labelFor="username"
        :label="$t('views.account.login.username')"
        :state="v$.username.$error ? false : null"
        :invalid-feedback="validationErrorKeyToString(v$.username, { max: 64 })"
        size="lg"
      >
        <GInput
          ref="username"
          v-model="username"
          name="username"
          :placeholder="$t('views.account.login.username')"
          size="lg"
          tabindex="1"
        />
      </GFormGroup>

      <div class="password-label">
        <label>{{ $tc('views.account.passwords.password') }}</label>
        <router-link class="reset-link" :to="{ name: 'request-reset' }" tabindex="5">
          {{ $t('views.account.passwords.forgotPass') }}
        </router-link>
      </div>

      <PasswordInput
        :value="password"
        name="password"
        :validator="v$.password"
        :placeholder="$tc('views.account.passwords.password')"
        size="lg"
        autcomplete="on"
        tabindex="2"
        @input="handlePasswordInput"
      />
      <div class="login-footer">
        <div v-if="!!invite" class="no-account-text">
          {{ $t('views.account.login.noAccount') }}
          <router-link
            class="sign-up-link"
            tabindex="4"
            :to="{
              name: 'signup',
              query: { invite: invite, email: username },
            }"
          >
            {{ $t('actions.signup') }}
          </router-link>
        </div>
        <GButton
          type="submit"
          size="lg"
          variant="primary"
          class="login-button"
          :disabled="isLoading"
          tabindex="3"
          @submit="tryLogin"
        >
          {{ $t('actions.login') }}
        </GButton>
      </div>
    </GForm>
  </div>
</template>

<script lang="ts">
  import { useVuelidate } from '@vuelidate/core';
  import { requiredIf, maxLength } from '@vuelidate/validators';
  import { validationErrorKeyToString } from '@/helpers';
  import PasswordInput from '@/components/PasswordInput.vue';
  import { defineComponent, inject } from 'vue';
  import { toasterKey } from '@getprotocollab/get-prettui';

  type Data = {
    isLoading: boolean;
    username: string;
    password: string;
    redirectedFrom: any;
  };

  export default defineComponent({
    components: {
      PasswordInput,
    },
    setup() {
      const toaster = inject(toasterKey, {
        addToast: () => {},
      });
      return { v$: useVuelidate(), toaster };
    },
    data(): Data {
      return {
        isLoading: false,
        username: '',
        password: '',
        redirectedFrom: null,
      };
    },
    validations() {
      return {
        username: {
          maxLength: maxLength(64),
          required: requiredIf(() => this.v$.username.$dirty || this.v$.password.$dirty),
        },
        password: {
          required: requiredIf(() => this.v$.password.$dirty),
        },
      };
    },
    computed: {
      invite() {
        return this.$auth.redirectedFrom && this.$auth.redirectedFrom.name === 'invitations'
          ? this.$auth.redirectedFrom.params.uuid
          : null;
      },
    },
    mounted() {
      if (this.$auth.redirectedFrom) {
        this.username = this.$auth.redirectedFrom.query?.email || '';
      }
    },
    methods: {
      validationErrorKeyToString,
      handlePasswordInput(value: string) {
        this.password = value;
      },
      async tryLogin() {
        this.v$.$touch();
        if (this.v$.$invalid) return;
        this.isLoading = true;
        try {
          await this.$store.dispatch('Account/login', {
            username: this.username,
            password: this.password,
          });
        } catch (e: any) {
          if (e.response && e.response.status === 403) {
            this.toaster.addToast({
              message: this.$t('views.account.login.invalidCredentials').toString(),
              title: this.$t('labels.danger').toString(),
              variant: 'danger',
            });
            throw e;
          }
          this.toaster.addToast({
            message: this.$t('notifications.genericError').toString(),
            title: this.$t('labels.danger').toString(),
            variant: 'danger',
          });
          throw e;
        } finally {
          this.isLoading = false;
        }
        if (this.invite) {
          this.$router.push({
            name: 'invitations',
            params: { uuid: this.invite },
          });
          return;
        }
        if (this.$auth.redirectedFrom) {
          this.$router.replace(this.$auth.redirectedFrom);
          this.$auth.redirectedFrom = null;
        } else {
          this.$router.replace({ path: '/events' });
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  .login-box {
    width: 100%;
    max-width: 26rem;
    background-color: $color-white;
    border-radius: $radius-large;
    overflow: hidden;
  }

  .login-header {
    padding: 1rem;
    background: $color-gray-100;
    border-bottom: 1px solid $color-border;
  }

  .login-title {
    margin: 0;
    font-size: $size-large;
  }

  .form {
    padding: 1rem;
  }

  .login-button {
    display: block;
    margin-left: auto;
  }

  .login-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .no-account-text {
    color: $color-primary;
  }

  .sign-up-link {
    text-decoration: underline;
    font-weight: $weight-semibold;
  }

  .reset-link {
    text-decoration: underline;
  }

  .password-label {
    display: flex;
    justify-content: space-between;
  }
</style>
