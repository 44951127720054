import RouteManagement from '@/plugins/RouteManagement';
import type { AuthType } from '@/plugins/auth';
import { Router } from 'vue-router';
import { App } from 'vue';
import { Store } from 'vuex';

/**
 * Sets route manager and before route listener
 * Need to fetch the queue every time the route changes for the slug info
 * Reuse existing queue from store if it is the same
 */
export default function install(
  app: App,
  {
    router,
    store,
    auth,
  }: {
    router: Router;
    store: Store<any>;
    auth: AuthType;
  },
) {
  if (!router) {
    throw new Error('No router found');
  }
  const routerManagement = new RouteManagement(router);
  const { state, dispatch, commit } = store;

  router.beforeEach(async (to, from, next) => {
    const { meta } = to;
    const { Account } = state;
    try {
      if (meta.auth) {
        if (!auth.hasTriedToAuthenticate) {
          try {
            await auth.tryUseRefresh();
          } catch (e) {
            console.info(e); // eslint-disable-line no-console
          }
        }

        if (!auth.authenticated()) {
          auth.redirect = to;

          if (to.name === 'invitations') {
            const {
              query: { email },
              params: { uuid },
            } = to;
            next({ name: 'signup', query: { invite: uuid, email }, replace: true });
            return;
          }

          next({ name: 'login' });
          return;
        }
        try {
          if (!Account.self) await dispatch('Account/getSelf');
        } catch (e) {
          console.info(e); // eslint-disable-line no-console
        }
      }

      if (meta.org) {
        if (!state.Organizations.current_organization) {
          try {
            await Promise.all([
              dispatch('Organizations/initLastSeenOrganization', Account.self.id),
              dispatch('Organizations/getTicketeers'),
            ]);
          } catch (e) {
            commit('Account/set_user_settings', {
              ...(state.Account.settings || {}),
              currently_selected_organization: null,
            });
          }

          dispatch('Organizations/getAllOrganizations');
        }

        if (!state.Account.settings?.currently_selected_organization && to.name !== 'organizations-overview') {
          next({ name: 'organizations-overview' });
          return;
        }
      }

      if (to.params.slug) {
        const currentEvent = state.Events.current_event;
        if (currentEvent?.slug !== to.params.slug) {
          try {
            await dispatch('Events/getEventData', to.params.slug);
          } catch (e) {
            next({ name: 'notFound', params: { 0: to.fullPath } });
            return;
          }
        }
      }

      routerManagement.resolveRoute({ to, from, next }, store);
    } catch (e) {
      routerManagement.resolveRoute({ to, from, next }, store);
      throw e;
    }
  });
}
