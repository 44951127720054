import { parseRequestQueryParams } from '@getprotocollab/get-pal';
import { pageLimit } from '@/helpers/constants';

function actions(app) {
  return {
    async fetchGates(
      state,
      {
        global = undefined,
        organization = undefined,
        shop = undefined,
        saleEnds = undefined,
        availableForShop = undefined,
        search = undefined,
        page = 1,
        limit = pageLimit,
        ordering = undefined,
      } = {},
    ) {
      const offset = limit * (page - 1);
      const query = parseRequestQueryParams({
        is_global: global,
        organization,
        shop,
        sale_ends_gate: saleEnds,
        available_for_shop: availableForShop,
        search,
        limit,
        offset,
        ordering,
      });
      const response = await app.config.globalProperties.axios.get(`gates/${query}`);
      return response;
    },

    async fetchGate(state, { id }) {
      const response = await app.config.globalProperties.axios.get(`gates/${id}/`);
      return response;
    },

    async createGate(state, gate) {
      const response = await app.config.globalProperties.axios.post('gates/', gate);
      return response;
    },

    async updateGate(state, gate) {
      const response = await app.config.globalProperties.axios.patch(`gates/${gate.id}/`, gate);
      return response;
    },

    async deleteGate(state, { id }) {
      const response = await app.config.globalProperties.axios.delete(`gates/${id}/`);
      return response;
    },

    async uploadToS3(state, gate) {
      const field = 'local_cover';
      const image = gate.local_cover;
      const imageData = { filename: image.name, field };
      const response = await app.config.globalProperties.axios.post(`/gates/${gate.id}/request_upload/`, imageData);
      const s3Object = new FormData();

      Object.keys(response.data.fields).forEach((k) => {
        s3Object.append(k, response.data.fields[k]);
      });
      s3Object.append('Content-Type', image.type);
      s3Object.append('file', image);
      await app.config.globalProperties.axios.post(response.data.url, s3Object, {
        headers: { Authorization: '' },
      });
      return response;
    },
  };
}

export default actions;
