import { parseRequestQueryParams } from '@getprotocollab/get-pal';
import { pageLimit } from '@/helpers/constants';

function actions(app) {
  return {
    async getRefunds(_, { ticketId = undefined, paymentId = undefined, page = 1, ordering = 'created' }) {
      const offset = pageLimit * (page - 1);
      const query = parseRequestQueryParams({
        ticket_id: ticketId,
        payment_id: paymentId,
        offset,
        limit: pageLimit,
        ordering,
      });
      const { data: refunds = {} } = await app.config.globalProperties.axios.get(`psp/refunds/${query}`);
      return refunds;
    },
  };
}

export default actions;
