const CreateIndex = () => import('@/views/events/create/index.vue');
const Create = () => import('@/views/events/create/Create.vue');
const CreateStepOne = () => import('@/views/events/create/CreateStepOne.vue');
const CreateStepTwo = () => import('@/views/events/create/CreateStepTwo.vue');
const CreateStepThree = () => import('@/views/events/create/CreateStepThree.vue');

export default {
  path: 'create',
  component: CreateIndex,
  meta: { layout: 'Slim' },
  children: [
    {
      path: '',
      redirect: 'event',
    },
    {
      path: 'event',
      component: Create,
      meta: { layout: 'Slim' },
      children: [
        {
          path: '',
          name: 'event-create',
          component: CreateStepOne,
          meta: {
            layout: 'Slim',
            auth: true,
            isCreating: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('event.create'),
            },
          },
        },
        {
          path: 'step-two',
          name: 'event-stepTwo',
          component: CreateStepTwo,
          meta: {
            layout: 'Slim',
            auth: true,
            isCreating: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('event.write'),
            },
          },
          beforeEnter: (_, from, next) => {
            if (from.name === 'event-create' || from.name === 'event-stepThree') {
              next();
              return;
            }
            next({ name: 'event-create' });
          },
        },
        {
          path: 'step-three',
          name: 'event-stepThree',
          component: CreateStepThree,
          meta: {
            layout: 'Slim',
            auth: true,
            isCreating: true,
            org: true,
            permissions: {
              access: ({ getters }, _) =>
                getters['Organizations/getPermission']('shop.create') &&
                getters['Organizations/getPermission']('shop.write'),
            },
          },
          beforeEnter: (_, from, next) => {
            if (from.name === 'event-stepTwo') {
              next();
              return;
            }
            next({ name: 'event-stepTwo' });
          },
        },
      ],
    },
    {
      path: 'template',
      component: Create,
      meta: { layout: 'Slim' },
      children: [
        {
          path: '',
          name: 'template-create',
          component: CreateStepOne,
          meta: {
            layout: 'Slim',
            auth: true,
            isCreating: true,
            isTemplate: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('event.create'),
            },
          },
        },
        {
          path: 'step-two',
          name: 'template-stepTwo',
          component: CreateStepTwo,
          meta: {
            layout: 'Slim',
            auth: true,
            isCreating: true,
            isTemplate: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('event.write'),
            },
          },
          beforeEnter: (_, from, next) => {
            if (from.name === 'template-create' || from.name === 'template-stepThree') {
              next();
              return;
            }
            next({ name: 'template-create' });
          },
        },
        {
          path: 'step-three',
          name: 'template-stepThree',
          component: CreateStepThree,
          meta: {
            layout: 'Slim',
            auth: true,
            isCreating: true,
            isTemplate: true,
            org: true,
            permissions: {
              access: ({ getters }, _) =>
                getters['Organizations/getPermission']('shop.create') &&
                getters['Organizations/getPermission']('shop.write'),
            },
          },
          beforeEnter: (_, from, next) => {
            if (from.name === 'template-stepTwo') {
              next();
              return;
            }
            next({ name: 'template-stepTwo' });
          },
        },
      ],
    },
  ],
};
