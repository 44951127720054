import { parseRequestQueryParams } from '@getprotocollab/get-pal';
import { pageLimit } from '@/helpers/constants';

function actions(app) {
  return {
    getAttendeeData({ commit, rootState }, attendeeId) {
      const attendeeData = {};
      const eventId = rootState.Events.current_event.id;
      const eventSlug = rootState.Events.current_event.slug;

      const queryUserIdEventSlug = parseRequestQueryParams({
        user_id: attendeeId,
        event_slug: eventSlug,
      });

      const queryUserId = parseRequestQueryParams({
        user_id: attendeeId,
      });

      const queryUserIdEventId = parseRequestQueryParams({
        user_id: attendeeId,
        event_id: eventId,
      });

      commit('set_loading', true);
      return Promise.all([
        app.config.globalProperties.axios.get(`/attendees/${attendeeId}/`),
        app.config.globalProperties.axios.get(`/tickets/${queryUserIdEventSlug}`),
        app.config.globalProperties.axios.get(`/events/${eventSlug}/code/${queryUserId}`),
        app.config.globalProperties.axios.get(`/privileges/${queryUserIdEventId}`),
      ])
        .then((data) => {
          attendeeData.info = data[0].data;
          attendeeData.tickets = data[1].data.results;
          attendeeData.code = data[2].data.code;
          attendeeData.privileges = data[3].data.results;
          commit('set_loading', false);
          commit('set_current_attendee', attendeeData);
        })
        .catch(() => {
          commit('set_loading', false);
        });
    },
    // TODO - legacy old endpoint // still required for seating assign modal until proper fix
    getEventSeatingAttendeesBatch({ rootState }, query) {
      const { id } = rootState.Events.current_event;
      const offset = query.page * pageLimit - pageLimit;
      const privilegeType = query.privilegeType || '';
      const type = query.attendeeType;
      const q = encodeURIComponent(query.query || '');
      const status = query.status || '';
      const sort = query.field || '';
      const direction = query.order || '';
      const onlyMultiple = query.onlyMultiple || '';

      const queryParams = parseRequestQueryParams({
        event_id: id,
        query: q,
        offset,
        status,
        privilege_type: privilegeType,
        sort,
        direction,
        limit: pageLimit,
        attendee_type: type,
        multiple_privilegetypes_only: onlyMultiple,
      });

      return app.config.globalProperties.axios.get(`reports/event-attendees/${queryParams}`);
    },
    getEventAttendeesBatch({ rootState }, query) {
      const { ordering, limit, offset, search, filters } = query;
      const { slug } = rootState.Events.current_event;

      const queryParams = parseRequestQueryParams({
        ordering,
        limit,
        offset,
        search,
        ...filters.reduce(
          (acc, x) => ({
            ...acc,
            [x.key]: x?.result?.value,
          }),
          {},
        ),
      });

      return app.config.globalProperties.axios.get(`attendees/event/${slug}/${queryParams}`);
    },

    getAttendeesBatch(_, query) {
      const { ordering, limit, offset, search, filters } = query;

      const queryParams = parseRequestQueryParams({
        ordering,
        limit,
        offset,
        search,
        ...filters.reduce(
          (acc, x) => ({
            ...acc,
            [x.key]: x?.result?.value,
          }),
          {},
        ),
      });

      return app.config.globalProperties.axios.get(`attendees/${queryParams}`);
    },

    async getAttendeeDetails({ commit }, { attendeeId, eventSlug }) {
      const query = eventSlug
        ? parseRequestQueryParams({
            event_slug: eventSlug,
          })
        : '';

      const { data: attendee = {} } = await app.config.globalProperties.axios.get(`attendees/${attendeeId}/${query}`);
      commit('set_current_attendee', attendee);
    },

    async getBlockedPrivileges(
      { rootState },
      {
        categoryId = null,
        ordering = undefined,
        page = 1,
        limit = pageLimit,
        eventId = rootState.Events.current_event.id,
        type_id = undefined,
        tier_id = undefined,
        subsection = undefined,
        claimed = undefined,
      } = {},
    ) {
      const queryParams = parseRequestQueryParams({
        ordering,
        offset: page * limit - limit,
        limit,
        block_category_id: categoryId,
        event_id: eventId,
        type_id,
        subsection,
        claimed,
        tier_id,
        locked: true,
        allocated: false,
      });
      const { data } = await app.config.globalProperties.axios.get(`/privileges/${queryParams}`);
      return data;
    },

    async updatePrivilegeType(_, payload) {
      await app.config.globalProperties.axios.patch('/privileges/', payload);
    },

    async getBlockCategories(
      { rootState, commit },
      { page = 1, direction = 'asc', ordering = 'name', search = '', eventId = rootState.Events.current_event.id } = {},
    ) {
      const queryParams = parseRequestQueryParams({
        ordering: direction === 'asc' ? ordering : `-${ordering}`,
        offset: page * pageLimit - pageLimit,
        limit: pageLimit,
        search,
        event_id: eventId,
      });
      const { data } = await app.config.globalProperties.axios.get(`/block-categories/${queryParams}`);

      commit('set_block_categories', data.results);

      return data;
    },

    async anonymize(_, payload) {
      await app.config.globalProperties.axios.post(`/attendees/${payload.id}/anonymize/`, {});
    },
  };
}

export default actions;
