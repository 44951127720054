const Customers = () => import('@/views/customers/index.vue');

export default {
  path: '/customers',
  name: 'customers',
  component: Customers,
  meta: {
    auth: true,
    org: true,
    permissions: {
      access: ({ getters }, _) => getters['Organizations/getPermission']('attendee.read'),
      redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
    },
  },
};
