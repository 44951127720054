{
  "countries": {
    "selectCountry": "Selecciona el país",
    "AF": "Afganistán",
    "AX": "Islas Åland",
    "AL": "Albania",
    "DZ": "Argelia",
    "AS": "Samoa Americana",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguila",
    "AQ": "Antártida",
    "AG": "Antigua y Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaiyán",
    "BS": "Bahamas",
    "BH": "Bahrein",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarús",
    "BE": "Bélgica",
    "BZ": "Belice",
    "BJ": "Benin",
    "BM": "Bermudas",
    "BT": "Bhután",
    "BO": "Bolivia (Estado Plurinacional de)",
    "BQ": "Bonaire, San Eustaquio y Saba",
    "BA": "Bosnia y Herzegovina",
    "BW": "Botswana",
    "BV": "Isla Bouvet",
    "BR": "Brasil",
    "IO": "Territorio Británico del Océano Índico",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Cabo Verde",
    "KH": "Camboya",
    "CM": "Camerún",
    "CA": "Canadá",
    "KY": "Islas Caimán",
    "CF": "República Centroafricana",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Isla de Navidad",
    "CC": "Islas Cocos (Keeling)",
    "CO": "Colombia",
    "KM": "Comoras",
    "CG": "Congo",
    "CD": "Congo (República Democrática del)",
    "CK": "Islas Cook",
    "CR": "Costa Rica",
    "CI": "Costa de Marfil",
    "HR": "Croacia",
    "CU": "Cuba",
    "CW": "Curazao",
    "CY": "Chipre",
    "CZ": "Chequia",
    "DK": "Dinamarca",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "República Dominicana",
    "EC": "Ecuador",
    "EG": "Egipto",
    "SV": "El Salvador",
    "GQ": "Guinea Ecuatorial",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Etiopía",
    "FK": "Islas Malvinas (Falkland)",
    "FO": "Islas Feroe",
    "FJ": "Fiji",
    "FI": "Finlandia",
    "FR": "Francia",
    "GF": "Guayana Francesa",
    "PF": "Polinesia Francesa",
    "TF": "Territorios Australes Franceses",
    "GA": "Gabón",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Alemania",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Grecia",
    "GL": "Groenlandia",
    "GD": "Granada",
    "GP": "Guadalupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haití",
    "HM": "La isla Heard y las islas McDonald",
    "VA": "La Santa Sede",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungría",
    "IS": "Islandia",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Irán (República Islámica del)",
    "IQ": "Iraq",
    "IE": "Irlanda",
    "IM": "Isla de Man",
    "IL": "Israel",
    "IT": "Italia",
    "JM": "Jamaica",
    "JP": "Japón",
    "JE": "Jersey",
    "JO": "Jordania",
    "KZ": "Kazajistán",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Corea (República Popular Democrática de)",
    "KR": "Corea (República de)",
    "KW": "Kuwait",
    "KG": "Kirguistán",
    "LA": "República Democrática Popular Lao",
    "LV": "Letonia",
    "LB": "Líbano",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libia",
    "LI": "Liechtenstein",
    "LT": "Lituania",
    "LU": "Luxemburgo",
    "MO": "Macao",
    "MK": "Macedonia (la ex República Yugoslava de)",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malasia",
    "MV": "Maldivas",
    "ML": "Malí",
    "MT": "Malta",
    "MH": "Islas Marshall",
    "MQ": "Martinica",
    "MR": "Mauritania",
    "MU": "Mauricio",
    "YT": "Mayotte",
    "MX": "México",
    "FM": "Micronesia (Estados Federados de)",
    "MD": "Moldavia (República de)",
    "MC": "Mónaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Marruecos",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Países Bajos",
    "NC": "Nueva Caledonia",
    "NZ": "Nueva Zelanda",
    "NI": "Nicaragua",
    "NE": "Níger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Isla de Norfolk",
    "MP": "Islas Marianas del Norte",
    "NO": "Noruega",
    "OM": "Omán",
    "PK": "Pakistán",
    "PW": "Palau",
    "PS": "Palestina, Estado de",
    "PA": "Panamá",
    "PG": "Papúa Nueva Guinea",
    "PY": "Paraguay",
    "PE": "Perú",
    "PH": "Filipinas",
    "PN": "Pitcairn",
    "PL": "Polonia",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Reunión",
    "RO": "Rumania",
    "RU": "Federación de Rusia",
    "RW": "Rwanda",
    "BL": "San Bartolomé",
    "SH": "Santa Elena, Ascensión y Tristán da Cunha",
    "KN": "San Cristóbal y Nieves",
    "LC": "Santa Lucía",
    "MF": "San Martín (parte francesa)",
    "PM": "San Pedro y Miquelón",
    "VC": "San Vicente y las Granadinas",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Santo Tomé y Príncipe",
    "SA": "Arabia Saudita",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leona",
    "SG": "Singapur",
    "SX": "San Martín (parte holandesa)",
    "SK": "Eslovaquia",
    "SI": "Eslovenia",
    "SB": "Islas Salomón",
    "SO": "Somalia",
    "ZA": "Sudáfrica",
    "GS": "Georgia del Sur y las Islas Sándwich del Sur",
    "SS": "Sudán del Sur",
    "ES": "España",
    "LK": "Sri Lanka",
    "SD": "Sudán",
    "SR": "Surinam",
    "SJ": "Svalbard y Jan Mayen",
    "SZ": "Swazilandia",
    "SE": "Suecia",
    "CH": "Suiza",
    "SY": "República Árabe Siria",
    "TW": "Taiwán, Provincia de China",
    "TJ": "Tayikistán",
    "TZ": "Tanzanía, República Unida de",
    "TH": "Tailandia",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad y Tabago",
    "TN": "Túnez",
    "TR": "Turquía.",
    "TM": "Turkmenistán",
    "TC": "Islas Turcas y Caicos",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ucrania",
    "AE": "Emiratos Árabes Unidos",
    "GB": "Reino Unido de Gran Bretaña e Irlanda del Norte",
    "US": "Estados Unidos de América",
    "UM": "Islas Ultramarinas Menores de Estados Unidos",
    "UY": "Uruguay",
    "UZ": "Uzbekistán",
    "VU": "Vanuatu",
    "VE": "Venezuela (República Bolivariana de)",
    "VN": "Vietnam",
    "VG": "Islas Vírgenes (Británicas)",
    "VI": "Islas Vírgenes (EE.UU.)",
    "WF": "Wallis y Futuna",
    "EH": "Sahara Occidental",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  },
  "placeholders": {
    "dateTime": "dd-mm-yyyy hh:mm",
    "date": "dd-mm-yyyy",
    "time": "hh:mm",
    "search": "Busca...",
    "searchCountryCode": "Search for country code",
    "loading": "Cargando...",
    "email": "example{'@'}mail.org",
    "uploadImagePlaceholder": "Elige una imagen o suéltala aquí.",
    "dropImagePlaceholder": "Arrastra tu imagen aquí.",
    "selectOrganisationMessage": "Seleccione una organización",
    "selectGate": "Buscar o seleccionar puerta...",
    "selectRole": "Select role",
    "selectLocale": "Select language",
    "selectTicketeer": "Select ticketeer",
    "phoneNumber": "e.g. +141566573212"
  },
  "intervals": {
    "hour": "Por hora",
    "day": "Por día",
    "week": "Por semana",
    "month": "Por mes",
    "year": "Por año"
  },
  "permissions": {
    "noPermissions": "No tienes suficientes permisos",
    "contactAdministrator": "Contacta a tu administrador para obtener los permisos necesarios",
    "insufficientPermissions": "Permisos insuficientes"
  },
  "notifications": {
    "saveSuccess": "Salvado con éxito",
    "savePublishSuccess": "Guardado y publicado con éxito",
    "removeSuccess": "Eliminado con éxito",
    "saveFail": "Error al guardar",
    "removeFail": "La eliminación falló",
    "cashierCartExpired": "Your cart has expired due to inactivity. Please reload the page to start over.",
    "cartUpdate": {
      "edit": {
        "success": "The cart has been updated successfully.",
        "fail": "Failed to update the cart."
      },
      "add": {
        "success": "The ticket(s) have been added successfully.",
        "fail": "Failed to add ticket(s)."
      },
      "remove": {
        "success": "The ticket(s) have been removed successfully.",
        "fail": "Failed to remove ticket(s)."
      }
    },
    "genericError": "Error",
    "successUpdate": "Successfully updated",
    "successEmail": "Email sent",
    "successShared": "Ticket shared",
    "successDownload": "File downloaded",
    "successPassword": "Password is successfully reset",
    "copiedToClipboard": "¡Copiado en tu portapapeles!",
    "copyFailed": "Failed to copy to your clipboard",
    "invitationSent": "Invitation sent",
    "notEnoughPrivs": "Error: no puedo reservar este número de entradas, ya que no hay (más) muchas disponibles. Puedes intentar aumentar el número de entradas.",
    "unSavedAlert": {
      "title": "Tienes cambios no guardados",
      "description": "¿Estás seguro de que quieres irte sin guardar tus cambios?"
    },
    "TicketKindExists": "No puedes eliminar este grupo porque tiene tickets asociados a él",
    "TicketKindExistsInShop": "No puedes eliminar esta tienda porque tiene tickets asociados a ella",
    "anonymizeUserSuccess": "The account data has been anonymized.",
    "anonymizeUserFail": "An error occurred while removing the account, the user is probably in the process of purchasing a ticket. Please try again later.",
    "feature_flag": "This feature is available for users with 'Tester' permissions only.",
    "tasks": {
      "taskLabel": "Task:",
      "stateLabel": "State:",
      "states": {
        "processing": "Processing",
        "not_started": "Not started",
        "failed": "Failed",
        "completed": "Completed"
      },
      "event_copy": {
        "title": "Copy event",
        "taskDescription": "Copying {title}",
        "link": "Go to the new event"
      },
      "shop_copy": {
        "title": "Copy Shop",
        "taskDescription": "Copying Shop {title}"
      },
      "template_generate": {
        "title": "Generating event",
        "taskDescription": "Generating from template:",
        "link": "Go to the new event"
      },
      "template_copy": {
        "title": "Copy template",
        "taskDescription": "Copying Template {title}"
      }
    }
  },
  "navigation": {
    "home": "Página principal",
    "events": "Eventos",
    "customers": "Customers",
    "upcoming": "Futuros",
    "past": "Pasados",
    "draft": "Proyecto",
    "reports": "Informes",
    "checkIns": "Confirmaciones de entrada",
    "sales": "Ventas",
    "exports": "Exports",
    "manage": "Gestionar",
    "blocks": "Reservas",
    "general": "General",
    "eventMarketing": "Marketing",
    "eventAdvanced": "Avanzado",
    "eventBlockchain": "Blockchain",
    "resaleFees": "Resale fees",
    "scanning": "Escaneo",
    "seating": "Asientos",
    "external": "Externo(s)",
    "demographics": "Dados demográficos",
    "pools": "Categorías",
    "shops": "Tiendas",
    "prices": "Precios",
    "gates": "Puertas",
    "templates": "Plantillas",
    "schedules": "Programador",
    "sync": "Sincronizar los cambios",
    "organizations": "Organizaciones",
    "checkin": "Check-in",
    "orders": "Pedidos",
    "reservations": "Reservations"
  },
  "actions": {
    "accept": "Accept",
    "seeAll": "Ver todos",
    "ok": "OK",
    "close": "Cerrar",
    "logout": "Cierre de sesión",
    "login": "Inicio de sesión",
    "signup": "Sign up",
    "publish": "Publicar",
    "savePublish": "Guardar y publicar",
    "confirm": "Confirmar",
    "confirmAll": "Confirm all",
    "saveChanges": "Guardar los cambios",
    "invalidate": "Invalidar",
    "reset": "Restablecer",
    "export": "Exportar",
    "loadMore": "Cargar más",
    "cancel": "Cancelar",
    "search": "Busca",
    "copy": "Copiar",
    "apply": "Aplicar",
    "edit": "Editar",
    "back": "Atrás",
    "save": "Guardar",
    "delete": "Eliminar",
    "preview": "Vista previa",
    "next": "Siguiente",
    "uploadImage": "Subir imagen",
    "checkin": "Confirmación de entrada",
    "undoCheckin": "Undo check-in",
    "showAll": "Mostrar todo",
    "clear": "Borrar",
    "solve": "Asiento automático",
    "finalize": "Finalizar",
    "assign": "Asignar",
    "printTickets": "Imprimir entrada | Imprimir entradas",
    "printPrivileges": "Print privilege | Print privileges",
    "printAllPrivilegesforCustomer": "Print all tickets",
    "print": "Print",
    "block": "Reserva",
    "unblock": "Liberar",
    "addRow": "Añadir fila",
    "email": "Correo electrónico",
    "sendEmail": "Send Email",
    "resend": "Resend",
    "revoke": "Revoke",
    "editPhoneNumber": "Edit Phone Number",
    "deleteAccount": "Delete Account",
    "sellTickets": "Sell Tickets",
    "batchAction": "Acción por lotes",
    "import": "Importar",
    "browse": "Navegar",
    "selectTimezone": "Selecciona una zona horaria",
    "chooseFile": "Elige un archivo o suéltalo aquí",
    "linkEmbed": "Enlace/inserción",
    "view": "Visualizar",
    "click": "Haz clic aquí.",
    "add": "Añadir",
    "remove": "Quitar",
    "removeAll": "Remove all",
    "change": "Change",
    "activate": "Activate",
    "deactivate": "Deactivate",
    "toDashboard": "Continue to dashboard",
    "switchOrganization": "Switch organization",
    "allOrganizations": "Show from all my organisations",
    "goToEvents": "Go to my events",
    "clearFile": "Clear file",
    "download": "Download",
    "help": "Help",
    "multiNoteTicket": "Multiple notes. Click the ticket for details.",
    "multiSeatingTicket": "Multiple seatings. Click the ticket for details.",
    "createGroup": "Crear grupo",
    "select": "Select",
    "issue": "Issue",
    "issueNow": "Issue now",
    "createReservation": "Create Reservation",
    "update": "Update",
    "distributeTickets": "Distribute tickets",
    "editNotes": "Edit notes",
    "addNotes": "Add notes"
  },
  "labels": {
    "en": "English",
    "nl": "Nederlands",
    "de": "Deutsch",
    "it": "Italiano",
    "ko": "한국어",
    "es": "Español",
    "locale": "Language",
    "yes": "Yes",
    "no": "No",
    "name": "Nombre",
    "shortName": "Short name",
    "date": "Fecha",
    "time": "Tiempo",
    "id": "ID",
    "checkinOptions": {
      "all": "All",
      "blocked": "Reservados",
      "assigned": "Asignados"
    },
    "amountSpecifier": "of",
    "ticketeer": "Ticketeer",
    "currentOrg": "Current organisation",
    "events": "Evento | Eventos",
    "eventTitle": "Nombre del evento | Nombre de los eventos",
    "order": "Order | Orders",
    "reservation": "Reservation",
    "created": "Creado",
    "expiry": "Expiry",
    "expired": "Expired",
    "claimed": "Claimed",
    "open": "Open",
    "canceled": "Canceled",
    "totalValue": "Total Value",
    "paidValue": "Paid value",
    "completed": "Completed",
    "actions": "Actions",
    "payment": "Payment | Payments",
    "method": "Method | Methods",
    "orderId": "Order-ID {id}",
    "paymentId": "Payment-ID {id}",
    "template": "Plantilla | Plantillas",
    "templateTitle": "Nombre de la plantilla",
    "organization": "Organización",
    "section": "Sección",
    "enabled": "habilitado",
    "disabled": "inhabilitado",
    "state": "State",
    "success": "Success!",
    "danger": "Error",
    "credits": "Credits",
    "summary": "Summary",
    "paymentMethod": "Payment Method | Payment methods",
    "pspId": "Psp ID",
    "type": "Type",
    "left": "Left",
    "max": "Max",
    "label": "{options} and {length} more",
    "notAvailableShort": "N/A",
    "availability": "Disponibilidad",
    "privilege": "Privilege | Privileges",
    "category": "Category | Categories",
    "yourOrders": "Your orders",
    "yourCustomers": "Your customers",
    "yourShops": "Your shops",
    "contactName": "Contact name",
    "contactEmail": "Contact email",
    "contactPhone": "Contact phone",
    "email": "Email address",
    "createdOn": "Created on",
    "createdBy": "Created by",
    "yourReservations": "Your reservations",
    "reservationId": "Reservation-ID {id}",
    "invitations": "Invitation | Invitations",
    "recipient": "Recipient",
    "claimedBy": "Claimed by",
    "expirationDate": "Expiration date",
    "customMessageContent": "Custom message content",
    "dates": {
      "today": "Hoy",
      "yesterday": "Ayer",
      "last7Days": "Últimos 7 días",
      "last4Weeks": "Últimas 4 semanas",
      "thisMonth": "Este mes",
      "thisYear": "Este año",
      "custom": "Personalizado",
      "allTime": "Todo el tiempo",
      "endDate": "Fecha de fin",
      "startDate": "Fecha de inicio",
      "fromDate": "Desde fecha",
      "untilDate": "Hasta fecha",
      "years": "year | years",
      "am": "AM",
      "pm": "PM",
      "timezone": "Time zone",
      "validFrom": "Valid from",
      "validUntil": "Valid until",
      "relativeTime": "Relative time",
      "dateOptions": {
        "dateAndTime": "Fechas y horas",
        "specificDate": "Día y hora específicos",
        "whenShopOpens": "when the shop opens",
        "relativeDateEnds": "on relative time before the event ends",
        "relativeDateStarts": " on relative time before the event starts",
        "whenEventEnds": "Cuando el evento termine",
        "whenDoorsOpen": "when the doors open",
        "doorsOpenWarning": "Your event starts on {eventDateTime}. The customers' tickets QR code will become available in their app 3 hours before the doors open.",
        "immediately": "Inmediatamente",
        "absoluteTimeWarning": "Warning: Created events from a template, or copied events, will use this specific date, even when the newly created event's date is different.",
        "relativeTimeWarningStart": {
          "cta": "Enter the amount of days before the event starts and the time on that day to use this relative date.",
          "warning": "If your event starts on {eventStarts} the actual date would be the {selectedDateTime}"
        },
        "relativeTimeWarningEnd": {
          "cta": "Introduce la cantidad de días antes de que termine el evento y el horario de ese día para utilizar esta fecha relativa.",
          "warning": "If your event ends on {eventEnds} the actual date would be the {selectedDateTime}"
        },
        "relativeTimeInfo": "Introduce la cantidad de días antes de que termine el evento y el horario de ese día para utilizar esta fecha relativa.",
        "relativeTimeExample": "If your event ends on {eventEnds} the actual date would be the {datetime}.",
        "ticketRelativeTimeFromInfo": "Enter the amount of days before the event ends and the time on that day to set the moment when these tickets become available at the shop.",
        "ticketRelativeTimeToInfo": "Enter the amount of days before the event ends and the time on that day to set the moment when these tickets become unavailable at the shop.",
        "daysBeforeEventEnds": "Days before the event ends",
        "daysBeforeEventStarts": "Days before the event starts",
        "dayTime": "Time on that day",
        "never": "Never"
      }
    },
    "profile": {
      "anonymous": "Anonymous",
      "firstName": "Nombre",
      "lastName": "Apellidos",
      "phoneNumber": "Número de teléfono",
      "mobileNumber": "Mobile number",
      "email": "Correo electrónico",
      "language": "Language",
      "confirmEmail": "Confirm email",
      "zipcode": "Código postal",
      "birthdate": "Fecha de nacimiento",
      "gender": "Género",
      "ageGroups": "Grupos de edad",
      "role": "Role",
      "m": "Hombre",
      "f": "Mujer",
      "o": "Otro",
      "lastPurchase": "Última compra",
      "contact": "Información de contacto",
      "addressInformation": "Información de la dirección",
      "basicInformation": "Información básica",
      "address": "Street name & house number",
      "addressShort": "Dirección",
      "city": "City",
      "country": "Country",
      "state": "State",
      "nft_wallet_address": "NFT wallet address",
      "gusuuid": "GUS uuid",
      "publicTicketEngineUrl": "Wallet link"
    },
    "event": {
      "shortName": "Short name",
      "seated": "Asientos",
      "nonSeated": "Admisión general ",
      "livestream": "Livamán",
      "title": "Título",
      "subtitle": "Subtítulo",
      "location": "Ubicación",
      "sublocation": "Sububicación (sala)",
      "city": "Ciudad",
      "country": "País",
      "address": "Dirección",
      "datesTitle": "Fechas y horas",
      "doorsOpen": "Tiempo de apertura",
      "startDate": "La fecha en que comienza el evento",
      "daysRunning": "Duración en días",
      "when": "Hora de inicio",
      "ends": "Hora de finalización en el último día",
      "endDateTime": "Fecha y hora de finalización",
      "saleStart": "Fecha de inicio de la venta",
      "saleEnds": "Fecha de fin de la venta",
      "url": "URL del evento",
      "slug": "Slug",
      "draft": "Proyecto",
      "timezone": "La zona horaria en la que tiene lugar el evento",
      "capacity": "Capacidad"
    },
    "ticketStatus": {
      "sold": "Vendido",
      "returned": "Devuelto",
      "resold": "Revendido",
      "forsale": "En venta",
      "being-resold": "En venta y en cesta",
      "created": "Creado",
      "invalidated": "Invalidado",
      "executed": "Registrado en entrada",
      "partialCheckedIn": "Checked-in",
      "inCart": "En el carrito",
      "notForSale": "No está en venta",
      "notExecuted": "No se ha confirmado",
      "unassigned": "Sin asignar",
      "blocked": "Reservados",
      "blocks": "Reservas",
      "assigned": "Asignados",
      "external": "Externo(s)",
      "free": "Libres",
      "released": "Released",
      "transferred": "Reservation Claimed"
    },
    "orderStatus": {
      "all": "All",
      "created": "Created",
      "pending": "Pending",
      "canceled": "Canceled",
      "timeout": "Timeout",
      "completed": "Completed",
      "failed": "Failed"
    },
    "reservationStatus": {
      "label": "Estado",
      "all": "All",
      "confirmed": "Confirmed",
      "distribution_started": "Distribution started",
      "awaiting_confirmation": "Awaiting confirmation",
      "awaiting_distribution": "Awaiting distribution",
      "invalidated": "Invalidated",
      "checked_in": "Checked-in",
      "partially_checked_in": "Checked-in",
      "invited": "Invited",
      "expired": "Expired",
      "canceled": "Canceled",
      "completed": "Completed"
    },
    "reservationInvitationStatus": {
      "label": "Estado",
      "invited": "Invited",
      "claimed": "Accepted",
      "revoked": "Revoked",
      "created": "Created"
    },
    "paymentStatus": {
      "pending": "Pending",
      "paid": "Paid",
      "duplicated": "Duplicated",
      "expired": "Expired",
      "failed": "Failed",
      "canceled": "Canceled"
    },
    "refundStatus": {
      "pending": "Pending",
      "success": "Success",
      "failed": "Failed"
    },
    "orderType": {
      "normal": "Online",
      "cashier": "Cashier",
      "reservation": "Reservation",
      "claimed": "Claimed"
    },
    "seat": {
      "rows": "Fila | Filas",
      "rowsAbbreviation": "Rw",
      "sections": "Sección | Secciones",
      "sectionsAbbreviation": "Sec",
      "subsections": "Subsección | Subsecciones",
      "subsectionsAbbreviation": "Sub-s",
      "entrances": "Entrada | Entradas",
      "entrancesAbbreviation": "Ent",
      "ranks": "Categoría | Categorías",
      "tiers": "Nivel | Niveles",
      "seats": "Asiento | Asientos",
      "seatsAbbreviation": "St",
      "category": "Categoría",
      "cashierSeating": {
        "auto": "Seated automatically",
        "manual": "Seated manually",
        "not_applicable": "Not applicable"
      }
    },
    "ticketTypes": {
      "upsell": "Venta incremental",
      "access": "Entrance",
      "checked_in": "Checked-in"
    },
    "ticketType": "Entrada",
    "eventOrganizer": "Organizador del evento",
    "tickets": "Entrada | Entradas",
    "types": "Type | Types",
    "totals": "Total | Totales",
    "total": "Total",
    "from": "De",
    "until": "Hasta",
    "description": "Descripción",
    "available": "Disponible",
    "forSale": "En venta",
    "locks": "Reserva | Reservas",
    "guests": "Invitado | Invitados",
    "upsells": "Venta incremental | Ventas incrementales",
    "variants": "Variante | Variantes",
    "combiTicket": "Entrada combinada",
    "guestlist": "Lista de invitados",
    "price": "Precio",
    "free": "Libres",
    "status": "Estado",
    "lastStatus": "Last status",
    "onDate": "on {date}",
    "groups": "Grupo | Grupos",
    "notes": "Nota | Notas",
    "sales": "Ventas",
    "checkIns": "Confirmaciones de entrada",
    "seating": "Asientos",
    "privileges": "Privileges",
    "accessPrivileges": "Entrance Privilege | Entrance Privileges",
    "ownedBy": "Owned by",
    "noResults": {
      "default": "No results found...",
      "orders": "No orders found...",
      "customers": "No customers found..."
    },
    "results": "results",
    "step": "Paso {count}",
    "amount": "Cantidad",
    "amountFinance": "Amount",
    "refundedAmount": "Refunded amount",
    "amountRefunded": "Amount refunded to {currency}",
    "pricePerTicket": "Price per ticket",
    "numberOfTickets": "Number of tickets",
    "subtotal": "Subtotal",
    "paidInFiat": "Paid in {currency}",
    "paidWithCredits": "Paid with Credits",
    "refundedToCredits": "Refunded to Credits",
    "dateCreated": "Date created",
    "dateUpdated": "Last updated",
    "totalDeducted": "Total deducted",
    "referral_id": "Referral ID",
    "primary": "Primary",
    "secondary": "Secondary",
    "unknown": "Unknown",
    "note": "Nota",
    "privilegeNote": "Privilege note",
    "ticketNote": "Ticket note",
    "optional": "(opcional)",
    "mandatory": "(mandatory)",
    "localTime": "Hora local",
    "other": "Otro",
    "pools": "Categorías",
    "availableTicketsTitle": "Available tickets",
    "availableTickets": "Available",
    "allocated": "Asignado",
    "blocks": "Reservas",
    "refundType": {
      "name": "Refund method",
      "auto": "Automatic",
      "manual": "Manual",
      "credits": "Credits",
      "no_refund": "No refund"
    },
    "shops": "Tienda | Tiendas",
    "details": "Detalles",
    "priceType": "Tipo de precio",
    "priceSelect": "Selecciona un precio",
    "customPrice": "Precio personalizado",
    "globalPrice": "Precio global",
    "totalPrice": "Total price",
    "example": "(ejemplo)",
    "users": "Usuarios",
    "currency": "Moneda",
    "paidEur": "Paid with {currency}",
    "paidCredits": "Paid with Credits",
    "refundedEur": "Refunded to {currency}",
    "refundedCredits": "Refunded to Credits",
    "grossRevenue": "Gross Revenue",
    "refunded": "Refunded",
    "inCurrency": "In {currency}",
    "toCredits": "To Credits",
    "market": "Market",
    "info": "Info",
    "paidWithCreditsPartially": "Partially paid with credits",
    "mandatoryField": "mandatory field",
    "collectibles": "Collectibles"
  },
  "validation": {
    "error": {
      "minFileSize": "El tamaño del archivo debe ser más grande que {max}Kb.",
      "minValue": "El valor debe ser mayor que {min}",
      "maxValue": "El valor debe ser inferior a {max}",
      "maxDecimals": "You can use up to {precision} decimals",
      "validateImageDeminsons": "La resolución debe ser mayor que {width}x{height}",
      "minDate": "El {field} debe ser después de {minVal}",
      "maxDate": "La hora de {field} debe ser antes de {maxVal}",
      "minOffset": "El {field} debe ser antes de {maxVal}",
      "maxOffset": "El {field} debe ser después de {minVal}",
      "required": "Este campo no puede estar vacío",
      "richTextMaxLength": "No puede ser más de {maxLength} caracteres",
      "cover": "Esta imagen no tiene las dimensiones correctas",
      "maxSize": "No debe exceder {maxSize} kb de tamaño",
      "fileType": "No es el tipo de archivo correcto",
      "maxLength": "El campo no puede ser mayor que que {max}",
      "maxCharLength": "El campo no puede tener más que {max} caracteres",
      "combinedMaxLength": "Cada campo no puede tener más que {max} caracteres",
      "decimal": "Debe ser un número válido",
      "alphaNum": "Debe ser un valor alfanumérico válido",
      "alphaNumDashUnderscore": "Debe contener sólo valores alfanuméricos, subrayado y guión",
      "numeric": "Debe ser un valor numérico",
      "phoneNumber": "Este no es un número de móvil válido. (Se requieren los códigos de país, por ejemplo \"+57\" para Colombia o \"+34\" para España)",
      "sameAsNumber": "Los números de teléfono no coinciden, por favor, vuelva a escribirlos.",
      "sameAs": "El {field} introducido no coincide, vuelva a entrar.",
      "url": "Por favor, introduzca una url válida (https://ejemplo.com).",
      "email": "Please enter a valid email address",
      "integer": "Please enter a whole number",
      "minLength": "The {field} must be at least {length} characters long.",
      "nonNumeric": "The {field} cannot contain only numeric characters.",
      "wrongPass": "Please enter the correct password.",
      "phoneNumberValidation": "Always include plus sign (+) and country code!",
      "isNotUnicode": "Please make sure to use no unicode characters"
    }
  },
  "views": {
    "reports": {
      "title": "Your exports",
      "nav": {
        "exports": "Exportaciones",
        "sales": "Ventas"
      },
      "exports": {
        "tabs": {
          "revenue": "Ingresos",
          "summary": "Summary",
          "orders": "Pedidos",
          "attendees": "Asistentes",
          "blocks": "Bloques",
          "advanced": "Avanzado",
          "groups": "Grupos"
        },
        "type": {
          "group_sizes_report": "Groups",
          "revenue_report": "Revenue",
          "summary_report": "Summary",
          "orders_report": "Orders",
          "attendees_report": "Attendees",
          "blocks_report": "Blocks"
        },
        "exportHistory": {
          "title": "Your latest exports",
          "created": "Created {date}",
          "generating": "Generating export...",
          "exportGenerationSuccess": "Export successfully generated"
        },
        "selectEventsAction": "Añadir o eliminar eventos",
        "action": "Generar exportación",
        "reportsGenerationFail": "Error al generar la exportación. Vuelve a intentar...",
        "configurationError": "No se encontraron eventos que pertenezcan a su organización en la puerta seleccionada...",
        "limitError": "You can generate a report for a maximum of {max} events! In case you want to create bigger exports, please contact our support.",
        "selectedEvents": "{selectedEvents} evento seleccionado | {selectedEvents} eventos seleccionados",
        "gateInfo": "Nota: sólo los eventos pertenecientes a su organización en la puerta seleccionada serán incluidos en la exportación.",
        "selectOrg": "Seleccione la organización para crear exportación para",
        "options": {
          "tickets": "Opciones: tickets y upsells",
          "events": "Opciones: para eventos y tiendas"
        },
        "advanced": {
          "eventSales": "All Ledger Transactions",
          "secondaryMarket": "Resale Market Transactions",
          "billingSummary": "Billing Summary"
        },
        "attendees": {
          "privacy-policy": "Attendees opted-in on privacy policy (if applicable)",
          "user-privacy": "Only attendees that accepted your privacy-policy",
          "brand-privacy": "Only attendees that accepted the privacy-policy of {brand}",
          "all": "Include all attendees",
          "optional": "Include optional attendees",
          "non-owners": "Include attendees that don’t own tickets anymore (resold, invalidated)",
          "invited": "Include attendees invited by ticket-holders in a group",
          "warning": "Note: By European law you are allowed to use the data of attendees who have not accepted your privacy-policy for service necessities only. This excludes marketing."
        },
        "merge": {
          "pricetype": "Combinar el mismo tipo de precio",
          "hideItems": "Ocultar elementos con valores vacíos"
        },
        "groupBy": {
          "events": "Agrupar por evento",
          "shop": "Agrupar por tienda"
        },
        "desiredFormat": "Formato deseado",
        "format": {
          "xlsx": "Excel (xlsx)",
          "csv": "Separados por comas (csv)"
        },
        "includeEvents": "Incluye los siguientes eventos",
        "eventOptions": {
          "all-events": "All events",
          "specific-gate": "Eventos en una puerta específica",
          "specific-events": "Seleccionar eventos específicos"
        },
        "periodInclude": "Incluye dentro del periodo",
        "periodOptions": {
          "specific-period": "Período específico",
          "all-time": "Todo el tiempo"
        },
        "selectEventsModal": {
          "title": "Seleccionar eventos para incluir en la exportación",
          "yourEvents": "Tus eventos",
          "added": "Eventos añadidos",
          "placeholder": "Buscar eventos",
          "addEvent": "Añadir evento {count} | Añadir {count} eventos",
          "removeEvent": "Eliminar el evento {count} | Eliminar {count} eventos",
          "info": "Para seleccionar múltiples eventos, puede pulsar shift o control (comando en Mac) y seleccionar, o hacer clic y arrastrar."
        }
      }
    },
    "organizations": {
      "organization": {
        "title": "Organisation",
        "name": "Organisation Identity",
        "legalName": "Nombre legal (opcional)",
        "legalNameDescription": "El nombre legal oficial de su organización",
        "privacyPolicy": "Privacy policy text and/or file (optional)",
        "privacyPolicyFileDescription": "Overwrite the default text for your privacy policy opt-in",
        "privacyPolicyPlaceholder": "I want to be kept informed of events and promotions and agree with the privacy statement of {orgName}.",
        "termsConditions": "Terms & conditions text and/or file (mandatory for attendee)",
        "termsConditionsFileDescription": "Overwite the default text for your terms & conditions opt-in",
        "termsConditionsPlaceHolder": "Estoy de acuerdo con los términos y condiciones de {orgName}.",
        "localeSelectPlaceholder": "Select default language for e-mails etc.",
        "pspSelectPlaceholder": "Select a payment service provider.",
        "demo": {
          "label": "Demo mode",
          "description": "Create organisation for demo purposes only",
          "info": "A demo organisation is limited to dummy payments, and cannot be used to sell real tickets.",
          "badge": "Demo",
          "alert": "This organisation is set to demo mode. Real payments are disabled. Events created under it are only to be used for demo or testing purposes."
        },
        "miscellaneous": {
          "title": "Miscellaneous",
          "refund": "Refund to credits",
          "expiration": "Expiration period",
          "expirationInfo": "This period can be overridden based on the role."
        },
        "paymentInfo": {
          "title": "Payments",
          "psp": {
            "label": "Payment Service Provider",
            "dummyInfo": "Note: Only use for demos or testing. Real payments are not possible.",
            "selectInfo": "Note: The selected currency determines which payment service providers are available.",
            "lockedInfo": "Note: You cannot switch PSP after your first ticket has been sold.",
            "options": {
              "mollie": "Mollie",
              "stripe": "Stripe",
              "dummy": "Dummy",
              "ticketeer_payments": "PSP of {brand}"
            }
          },
          "apiKey": {
            "testApiKeyLabel": "Test API key",
            "test_key": "You don't have permission to submit TEST API credentials",
            "label_apiKey_mollie": "Live API key",
            "label_apiKey_stripe": "Secret Key",
            "label_publicKey_stripe": "Publishable key",
            "label": "API key",
            "publicKey": "Public API key",
            "addInfo": "Add the API key provided by your payment service provider. When you set the API key correctly, approval is done immediately.",
            "existsInfo": "Note: You cannot change this API key after your first ticket has been sold.",
            "error": "This API key does not seem to be valid",
            "apiErrors": {
              "invalid_api_key": "Error: Invalid API key"
            }
          },
          "methods": {
            "title": "Payment methods",
            "overrideLabel": "Override payment methods",
            "overrideInfo": "The following payment methods are set according to your PSP account you selected. If you chose to override the payment methods, changes in your PSP account won’t reflect in these settings.",
            "overrideInfoStripe": "Important: The accepted payment methods need to exactly match your Stripe enabled payment methods, otherwise payments will not work! By default everything is enabled."
          }
        },
        "fileDescription": "PDF's only with a maximum filesize of 20mb",
        "previewFileLink": "View file",
        "usersAndRoles": {
          "navTitle": "Users & roles",
          "create": "Invite user",
          "active": "Active",
          "deactivated": "Deactivated",
          "search": "Search users",
          "activateUser": "Activate",
          "pendingInvitation": "{role} pending invitation",
          "expiredInvitation": "Invitation expired",
          "inviteUserModal": {
            "edit": "Edit user",
            "pending": "Pending invitation",
            "expired": "Expired invitation",
            "invite": "Invite user",
            "info": "An email will be sent to this email address to create an account or log-in and link the organisation to this user.",
            "emailAlreadyUsed": "This email address has already been invited to the dashboard. Please use a different email address.",
            "send": "Send invitation",
            "resend": "Resend invitation",
            "removeInvitation": "Remove invitation",
            "deactivate": "Deactivate"
          },
          "switchUserStateModal": {
            "activateTitle": "Activate user",
            "deactivateTitle": "Deactivate user",
            "description": "Are you sure you want to {action} {email}?"
          }
        },
        "attendeeInfo": {
          "title": "Attendee information",
          "description": "The following is the information attendees will have to fill in when buying tickets for your events. You can select if you want the information below to be mandatory, optional or hidden. The fields marked as always mandatory cannot be edited.",
          "states": {
            "always": "Always mandatory",
            "mandatory": "Mandatory",
            "optional": "Optional",
            "hide": "Hide"
          },
          "anonymize": {
            "button": "Remove account",
            "modal": {
              "title": "Are you sure?",
              "description": "Removing the account will result in the deletion of all data and tickets. It CANNOT be rolled back."
            }
          }
        },
        "floorplans": "Planos"
      },
      "placeholder": "Buscar organizaciones",
      "create": "Crear organización",
      "modal": {
        "title": "Crear organización",
        "selectCurrency": "Seleccionar moneda"
      },
      "table": {
        "psp": "PSP",
        "edit": "Edit"
      }
    },
    "account": {
      "editTitle": "Edit account",
      "passwords": {
        "password": "Password | Passwords",
        "changePass": "Change password",
        "resetPass": "Reset password",
        "forgotPass": "Forgot password?",
        "currentPass": "Current password",
        "newPass": "New password",
        "confirmPass": "Confirm password",
        "apiErrors": {
          "weak_password": "Password can't be similar to your email, name or a weak passwords like \"admin1234\""
        },
        "passwordInfo": {
          "title": "We expect a strong password:",
          "minLength": "Minimum length of 9 characters and not just numbers",
          "similar": "Not similar to your email, or weak passwords like \"admin123\"."
        }
      },
      "login": {
        "title": "Login",
        "invalidCredentials": "Invalid credentials",
        "noAccount": "Don't have an account?",
        "username": "Username"
      },
      "signup": {
        "title": "Sign up",
        "hasAccount": "Already have an account?",
        "apiErrors": {
          "user_already_exists": "An account with this email already exists."
        }
      },
      "reset": {
        "description": "Enter your email address and we’ll send you a link to reset your password.",
        "requestedDescription": "If there is an account associated with this email address, you will receive a link to reset your password.",
        "expiredAlert": "This link has expired. You can request another password-reset.",
        "toLogin": "Go back to login"
      }
    },
    "invite": {
      "title": "Accept invitation",
      "expired": {
        "alert": "Your invitation is not valid anymore.",
        "info": "For more information or help, please contact {0}."
      },
      "noInvite": "This invite has already been accepted or doesn't exist. You can continue to go to dashboard.",
      "accept": {
        "title": "You have been invited to join: {org}",
        "inviteAccepted": "You now have access to: {org}",
        "info": "The invitation will be accepted under your current account: {email}. If you wish to accept using a different account, log in to this account first and then click the invitation link again."
      }
    },
    "attendees": {
      "attendee": {
        "edit": {
          "changeNumber": {
            "title": "Cambiar el número",
            "description": "Estás a punto de cambiar la cuenta con el número de móvil {number}",
            "inputLabel": "Nuevo número de móvil"
          },
          "confirmNumber": {
            "attendees": "participantes",
            "migrateConfirm": "Si quieres migrar, por favor confirma el nuevo número de móvil.",
            "existsDescription": "Ya hay una cuenta vinculada a este nuevo número de móvil. ¿Estás seguro de que esto es correcto?",
            "existsWithTickets": "Esta cuenta tiene eventos próximos, por lo tanto no puedes migrar. Vuelva atrás si quieres usar otro número de móvil.",
            "inputLabel": "Confirma el nuevo número de móvil"
          },
          "success": "Número de móvil actualizado con éxito",
          "error": "Error: no se pudo actualizar el número de móvil"
        }
      }
    },
    "customers": {
      "title": "Your customers",
      "startSearch": "Search for customers across all your events by entering a (partial) name, email address or phone number. Easy peasy!",
      "loading": "Customer loading...",
      "groupsLoading": "Loading groups...",
      "tooltips": {
        "privilegesDescription": "The total number of entrance privileges allocated to a customer."
      },
      "tickets": {
        "ticketsTable": {
          "emptyText": "No tickets found, check orders for more information",
          "emptyTextShort": "No tickets found",
          "resoldBy": "by {customer}"
        },
        "checkin": {
          "title": "Check-in",
          "description": "Are you sure that you want to check-in this ticket? | Are you sure that you want to check-in these {count} tickets?"
        },
        "undoCheckin": {
          "title": "Undo Check-in",
          "description": "Are you sure that you want to undo check-in this ticket? | Are you sure that you want to undo check-in these {count} tickets?"
        },
        "invalidate": {
          "steps": {
            "options": "Refund options",
            "details": "Refund details"
          },
          "refundOptions": {
            "blockTickets": "Block the ticket that will be available again | Block the tickets that will be available again",
            "tooltipText": "If you don't select this option, this ticket will be up for sale again. | If you don't select this option, the tickets will be up for sale again.",
            "title": "Refund ticket | Refund tickets",
            "description": "Select if and how you want refund the ticket you are about to invalidate. | Select if and how you want refund the tickets you are about to invalidate.",
            "options": {
              "automatic": {
                "title": "Automatic refund",
                "description": "Automatically refund the amount paid for the tickets to the attendee. Select in the next step if the attendee or the organizer will be charged the service fee."
              },
              "manual": {
                "title": "Manual refund",
                "description": "Refund manually using a external payment method and adjust the reports accordingly. Select in the next step if the attendee or the organizer will be charged the service fee."
              },
              "credits": {
                "title": "Credits",
                "description": "Refund the amount and add these funds to account of the user to be redeemed later."
              },
              "noRefund": {
                "title": "No refund",
                "description": "Only invalidate the tickets, keep the original paid amount in the reports."
              }
            }
          },
          "refundDetails": {
            "warning": "This action cannot be undone. If you wish to continue, press confirm.",
            "title": {
              "auto": "Automatic refund",
              "manual": "Manual refund",
              "credits": "Credits",
              "noRefund": "No refund"
            },
            "howToRefund": "How would you like to refund the following tickets?",
            "fullRefund": "Full refund",
            "partialRefund": "Partial refund",
            "amountToDeduct": "Amount you wish to deduct per ticket",
            "creditsExpiration": "Credits expiration date",
            "summary": {
              "title": "Summary",
              "description": "The following ticket will be invalidated. | The following tickets will be invalidated.",
              "totalRefund": "Total refund: {formatedAmount}",
              "ticketBlockedOnCategory": "The ticket will be block on category: | The tickets will be block on category:"
            }
          }
        },
        "editNotes": {
          "title": "Edit note | Edit notes",
          "multiNotesDescription": "You're editing multiple notes, this change will reflect on all the items you selected.",
          "placeholder": "Note"
        }
      },
      "block_categories": {
        "uncategorized": "Uncategorized",
        "uncategorized_short": "UNC",
        "search": "Search a category..."
      },
      "privileges": {
        "privilegesTable": {
          "emptyText": "No privileges found"
        }
      },
      "orders": {
        "ordersOverviewTitle": "Your orders"
      },
      "refunds": {
        "refundsTable": {
          "emptyText": "No refunds found"
        }
      },
      "customer": {
        "noName": "Unnamed customer",
        "noAddress": "No address",
        "userQr": {
          "action": "User Qr",
          "title": "{name} user QR",
          "description": "Scan the following QR code to check-in the customer.",
          "error": "Could not load the QR code for this customer"
        },
        "edit": {
          "changeNumber": {
            "title": "Change number",
            "description": "You are about to change the account with mobile number {number}",
            "inputLabel": "New mobile number"
          },
          "confirmNumber": {
            "migrateInfo": "This mobile number can be changed. You can optionally check if this account already exists, by going to {customers}.",
            "customers": "customers",
            "migrateConfirm": "If you want to migrate, please confirm the new mobile number.",
            "existsDescription": "There is already an account tied to this new mobile number. Are you sure this is correct?",
            "existsWithTickets": "This account has upcoming events, therefore you cannot migrate. Go back if you want to use a different mobile number.",
            "inputLabel": "Confirm the new mobile number"
          },
          "success": "Successfully updated mobile number",
          "error": "Error: Could not update mobile number"
        }
      },
      "filters": {
        "pool": "Pool",
        "shop": "Tienda",
        "checkin": {
          "name": "Checked-in status",
          "fully": "Fully",
          "partially": "Partially",
          "not": "Not"
        }
      }
    },
    "events": {
      "create": {
        "title": "Crear evento",
        "new": "Nuevo evento",
        "fromTemplate": "Elegir la plantilla"
      },
      "title": "Tus eventos",
      "titleWithOrganization": "Your events for {org}",
      "reports": {
        "title": "Tus ventas",
        "titleWithOrganization": "Your sales for {org}",
        "revenue": "Ingresos",
        "revenueBoxTitle": "Ingresos de este período",
        "ticketSoldBoxTitle": "Entradas vendidas en este período",
        "grossRevenue": "GROSS revenue",
        "invalidated": "Invalidated",
        "refunded": "Refunded",
        "upsellSoldBoxTitle": "Ventas incrementales de este período",
        "credits": {
          "title": "Credits",
          "circulating": "Circulating",
          "info": "The amount of credits you refunded which haven't expired or been used yet"
        }
      },
      "eventCreate": {
        "title": "Crear nuevo {type}",
        "eventDescription": "¡Crea un nuevo evento desde cero! Estos pasos te guiarán a añadir la configuración mínima necesaria para configurar un evento. Esto se guardará como borrador. Puede editar todos los ajustes después, excepto el tipo inicial configurado en este primer paso.",
        "templateDescription": "¡Crear una nueva plantilla desde cero! Estos pasos le guiarán a agregar la configuración mínima necesaria para configurar una plantilla. Puede editar todos los ajustes después, excepto el tipo inicial configurado en este primer paso.",
        "templateInfo": "Después de crear una plantilla, se pueden generar múltiples eventos (automáticamente) basados en esta plantilla. Todos los ajustes de esta plantilla se utilizan y actualizan para todos los eventos generados.",
        "setEventType": "Type of event",
        "selectType": {
          "label": "Seleccione el tipo de evento que desea crear"
        },
        "selectVenue": "Selecciona el lugar",
        "eventDetails": "Detalles del evento",
        "ticketManagement": "Gestión de entradas",
        "modal": {
          "title": "Vista previa del evento creado",
          "content": "Tu nuevo evento ha sido creado con éxito y guardado en tus Proyectos de Eventos.<br><br>Puedes publicar tu evento ahora mismo, o volver a la pantalla principal para hacer más modificaciones antes de eso.",
          "dashboardButton": "Volver al Dashboard",
          "publishButton": "Publicar evento"
        }
      },
      "eventCopy": {
        "action": "Copiar evento",
        "description": "Todas las configuraciones del evento serán copiadas, junto con las limitaciones.",
        "startDate": "Fecha de inicio"
      },
      "templateCopy": {
        "action": "Copiar plantilla",
        "description": "Todas las configuraciones de la plantilla serán copiadas, junto con las limitaciones."
      },
      "event": {
        "savedAsDraft": {
          "title": "El proyecto de evento fue guardado",
          "draftInfo": "Tu evento fue creado con éxito y guardado como un proyecto. Puedes acceder y editar tu evento en el dashboard, bajo tu Proyectos de eventos.",
          "previewInfo": "Puedes ver una vista previa de tu evento ahora y publicarlo más tarde, o publicarlo ahora mismo. Pulsa OK para volver al dashboard."
        },
        "demo": {
          "description": "Demo event: This is event has been created under a demo organisation. Real payments are disabled. Only use this event for demo or testing purposes."
        },
        "unlink": {
          "title": "Este evento se gestiona mediante una plantilla.",
          "description": "Los cambios en las tiendas, entradas, categorías y otros ajustes del evento sólo pueden hacerse y sincronizarse con todos los eventos a la vez en la {0} de este evento.",
          "eventTemplate": "plantilla",
          "shop": {
            "title": "Esta tienda se gestiona mediante una plantilla.",
            "description": "Los cambios en esta tienda, sus entradas y grupos sólo pueden hacerse y sincronizarse con todos los eventos a la vez en {0}.",
            "link": "la tienda de la plantilla de este evento"
          },
          "action": {
            "description": "Si sólo quieres hacer cambios en este evento {0}",
            "unlink": "desvincula de la plantilla."
          },
          "modal": {
            "title": "¿Estás seguro de que quieres desvincular este evento?",
            "description": "Cuando se desvincula este evento de su plantilla, ya no se actualizará cuando se hagan cambios en esta plantilla y se sincronice. Puedes gestionarlo completamente como un único evento. ¡Esta acción no se puede deshacer!",
            "action": {
              "unlink": "desvincular",
              "description": "Escribe {0} en el campo de abajo para confirmar"
            },
            "unlinkEvent": "Desvincular evento"
          }
        },
        "manage": {
          "placeholders": {
            "title": "e.g. Maximum Music Festival",
            "subtitle": "e.g. 20th Aniversary",
            "shortName": "e.g. MaxMusic"
          },
          "shortNameExplanation": "Will be used for SMS notifications when issuing tickets through the dashboard",
          "generalTitle": "Administrar los detalles generales del evento",
          "additionalTitle": "Administrar los detalles adicionales del evento",
          "advancedTitle": "Administrar los detalles avanzados del evento",
          "saveValidateAlert": "Algunos campos obligatorios faltan o tienen datos inválidos.<br>Comprueba si hay errores arriba.",
          "marketing": "Marketing",
          "scannerPassword": "Contraseña del escáner",
          "scannerQr": "Código QR",
          "about": {
            "label": "Acerca de tu evento",
            "description": "Describe de qué se trata tu evento, comunica pautas adicionales a tus visitantes y proporciona información general y enlaces externos.",
            "help": "Puedes añadir un estilo básico seleccionando el texto que deseas estilizar."
          },
          "endDateSwitch": "Este evento termina después de la medianoche del día siguiente",
          "endDateDescription": "Si un evento termina después de medianoche (por ejemplo, 01:00), técnicamente termina un día más tarde - temprano en la mañana. Cambia esto para comunicar que este evento termina el día anterior.",
          "templateStartDateInfo": "Utiliza la fecha especificada de un evento cuando se genera.",
          "dateErrors": {
            "required": "All date and time fields are required. Please make sure to fill in all information.",
            "minOpen": "The opening time cannot be after the start time of the event.",
            "minOpenLivestream": "The opening time for livestream should be at least 15 min before the event start."
          },
          "coverImage": {
            "label": "Foto de portada",
            "description": "Añade una foto de alta calidad, que se mostrará en diferentes recortes dependiendo de dónde se utilice en la aplicación.\nIntenta evitar el texto o los logotipos en la imagen.",
            "help": "Tipo de archivo PNG o JPG. Resolución mínima de 640x480. Máximo de 10mb en el tamaño del archivo."
          },
          "floorplanImage": {
            "label": "Mapa o plano",
            "description": "Será proporcionado por el emisor de entradas en formato eps.",
            "help": "Tipo de archivo SVG o PNG. La mejor resolución es de 375 píxeles de ancho."
          },
          "orderSuccessLink": {
            "label": "Llamada a la acción cuando se compran los billetes",
            "description": "Mostrar un mensaje corto y una llamada a la acción al comprador."
          },
          "orderSuccessEmail": {
            "label": "Marketing por correo electrónico",
            "description": "Contenido personalizado para los correos electrónicos de confirmación de entradas."
          },
          "saveAlert": {
            "title": "Guardar los cambios",
            "description": "Estás seguro de que quieres guardar los cambios que hiciste para este evento publicado? Los visitantes podrán ver los cambios directamente."
          },
          "publishAlert": {
            "title": "Hacer el evento público",
            "description": "Estás seguro de que quieres publicar este evento y hacerlo público? <br> Una vez que un evento es publicado <b>no</b> podrás volver a borrarlo. Sin embargo, las entradas sólo estarán disponibles en la fecha de inicio de venta prevista.",
            "shopsTitle": "Your event and the following shops will become public",
            "cancel": "Not now",
            "confirm": "Publish now",
            "warning": "Publishing cannot be undone",
            "shop": "Shop",
            "defaultGate": "Default Gate",
            "opens": "Opens on",
            "immediately": "Immediately",
            "shopsFailed": "Failed to load shops, please try again",
            "tryAgain": "Try again",
            "publishError": "The event could not be published. Please make sure that the payment service provider configuration of your organisation is fully setup.",
            "orgSettings": "Go to settings for: {name}"
          },
          "livestream": {
            "typeSelect": {
              "label": "Seleccione cómo desea configurar su livestream (opcional)",
              "internal": "Internacional: Usa tus propios códigos de incrustación",
              "external": "Externa: Utilice un enlace externo para redirigir a"
            },
            "videoEmbed": {
              "label": "Código de embebido de video Livestream (opcional)",
              "placeholder": "<iframe src='https://player.vimeo.com/video/123456789/' frameborder=0 allow=autoplay; fullscreen allowfullscreen></iframe>"
            },
            "chatEmbed": {
              "label": "Código incrustado de chat de Livestream (opcional)",
              "placeholder": "<iframe src='https://vimeo.com/live-chat/123456789/' frameborder='0'></iframe>"
            },
            "externalUrl": {
              "label": "URL externa de Livestream (opcional)",
              "placeholder": "https://livestream.com"
            }
          },
          "advanced": {
            "sharing": {
              "title": "Compartiendo",
              "label": "Compartir tickets entre los asistentes (opcional)",
              "strict": "Compartir vía código QR",
              "strictInfo": "Los asistentes deben reunirse en persona y escanear un código QR para compartir sus tickets entre sí",
              "easy": "Compartir a través de Link",
              "easyInfo": "Los asistentes pueden enviar un enlace para invitar a otros a unirse",
              "disabled": "No permitido",
              "disabledInfo": "Los asistentes no pueden compartir sus tickets para este evento"
            },
            "private": {
              "title": "Blockchain Visibility",
              "label": "It's possible to hide your event details on the blockchain. This includes event name, location, image and the shop URL",
              "checkbox": "Do not show event details on the blockchain"
            },
            "orderConfirmEmail": {
              "title": "Email de confirmación de pedido",
              "label": "Dirección de correo (opcional)",
              "placeholder": "ejemplo{'@'}organizador.com",
              "info": "Enviaremos un correo electrónico a esta dirección con los detalles de cada pedido realizado."
            },
            "scanLeeway": {
              "title": "margen de exploración sin conexión",
              "label": "Establecer la cantidad de tiempo en minutos",
              "info": "Los asistentes pueden introducir con un código QR que no requiere una conexión a Internet. Esta configuración define cuánto tiempo son válidos estos códigos QR sin conexión."
            }
          },
          "blockchain": {
            "publish": {
              "title": "Visibility",
              "label": "When you publish, your event & shop information will also be visible on the GET Protocol blockchain. You can (optionally) specify when that should happen:",
              "never": "Do not show event details on the blockchain",
              "public": "This event's information has already been made public on the blockchain. It is not reversible.",
              "defaultInfo": "By default this is set to the opening time of your default shop."
            },
            "contractAddress": {
              "title": "Event contract address",
              "placeholderDraft": "Your event contract address will be generated once the event is published.",
              "label": "Below is the event contract address for you to view and verify all transactions.",
              "placeholder": "Generating (refresh the page to update)"
            },
            "nft": {
              "title": "Default Collectible Artwork",
              "label": "Upload your default Collectible artwork here. This design will be used for all ticket's collectibles (unless specifically overwritten within each ticket's settings).",
              "guidelines_title": "General guide lines:",
              "line_1": "PNG, GIF or JPG file type",
              "line_2": "Portrait mode with aspect ratio of 3:4",
              "line_3": "10 MB max file size",
              "line_4": "You can <a href='{href}' download>our template</a> as a base",
              "note": "Note: If no artwork is provided, a default artwork is used by GET Protocol."
            }
          },
          "resale": {
            "title": "Resale Fees"
          },
          "previewModal": {
            "title": "Send preview email",
            "success": "Email has been sent successfully",
            "error": "An error occured sending the email"
          }
        },
        "reports": {
          "sales": {
            "turnover": "Volumen de venta",
            "capacity": "Capacidad llena",
            "ticketsSold": "Entradas vendidas",
            "capacityFilled": "Capacidad llena",
            "capacitySold": "Capacidad vendida",
            "tooltipTicketsSold": "Vendido",
            "availabilityTooltip": "Las entradas pueden compartir categorías",
            "percentageSold": "Porcentaje vendido",
            "revenue": "Ingresos",
            "grossRevenue": "Ingresos brutos",
            "graph": {
              "range": {
                "placeholder": "Seleccione un período",
                "last_day": "Últimas 24 horas",
                "last_month": "Últimos 31 días",
                "all_time": "Todo el tiempo"
              },
              "title": {
                "last_day": "Entradas vendidas en las últimas 24 horas",
                "last_month": "Entradas vendidas en los últimos 31 días",
                "all_time": "Entradas vendidas por toda la semana"
              }
            }
          },
          "demographics": {
            "avgOrderSize": "Promedio de entradas por pedido",
            "totalBuyers": "Número total de compradores",
            "locations": "Ubicaciones"
          },
          "execution": {
            "checkedInPercentage": "Porcentaje de confirmados"
          }
        },
        "attendees": {
          "filters": {
            "checkin": {
              "label": "Filtrar por confirmación",
              "partialExecuted": "Confirmación parcial",
              "allExecuted": "Confirmación completa",
              "notExecuted": "No se ha confirmado",
              "all": "Mostrar todo"
            },
            "pools": "Filtrar por categoría"
          },
          "guestlist": {
            "action": "Añadir invitados",
            "addRow": "Añadir fila",
            "guestListName": "Nombre de la lista de invitados",
            "createVariant": "Crear nuevo...",
            "selectTicket": "Seleccionar entrada",
            "selectVariant": "Seleccionar la lista de invitados",
            "optionsTitle": "Añadir a la lista de invitados gratis",
            "seating": "Seleccionar los asientos reservados para usar",
            "selectPool": "Seleccione la disponibilidad que desea utilizar",
            "confirmTitle": "Confirmar la lista de invitados"
          },
          "cashier": {
            "selectTickets": "Select tickets",
            "blockCategory": "Block category",
            "ticketsLeft": "Tickets left",
            "specifyAmount": "Please specify how many {ticketName} tickets you would like to add",
            "cart": "Cart ({count})",
            "emailField": "Send confirmation to (optional)",
            "notifyBySms": "Notify via SMS",
            "notifyByEmail": "Notify via email",
            "addTicket": "Add {ticketName} Ticket",
            "addTicketDescription": "From which <strong>availability</strong> do you want to select your tickets from?",
            "addTicketAssignment": "How would you like to assign the seats?",
            "addTicketHowMany": "How many <strong>{ticketName}</strong> tickets from <strong>{shopName}</strong> would you like to add?",
            "addToCart": "Add to cart",
            "emailTooltip": "If no email address is specified, no confirmation email will be send.",
            "ticketsLoading": "Retrieving available tickets, please wait...",
            "tickets": "Entradas",
            "issueDigitallyCheck": "Send tickets to phone?",
            "yourOrder": "Your order {orderId} for {customerName}",
            "orderCreated": "Order {orderId} created",
            "issueMore": "Issue more tickets",
            "reserveMore": "Reserve more tickets",
            "bookPayment": "Book payment",
            "distributeTickets": "Distribute tickets",
            "checkinAll": "Check all tickets in now",
            "noTicketsSelected": "No tickets selected",
            "confirmGuestInfo": {
              "confirmTitle": "Confirm Tickets",
              "confirmMainMessage": "Tickets will be linked to the provided phone number(s) and can be accessed by the attendee via the ticket wallet.<br/>Attendees will receive an e-mail confirmation with the event & ticket info.",
              "total": "Total",
              "hasEmail": "Confirmation sent to: {email}",
              "noEmail": "No confirmation sent (no email address provided)"
            },
            "actionTooltip": "Tickets can only be issued when the event has been published",
            "action": "Vender entradas",
            "optionsTitle": "Vender entradas",
            "categories": {
              "title": "Seleccionar una categoría de reserva",
              "description": "A continuación se enumeran todas las entradas sin asiento. Selecciona la categoría en la que deseas que se venda tu entrada. Si una categoría no tiene suficientes reservas disponibles, puedes editarlas {0}",
              "categoryLink": "aquí."
            },
            "payment": {
              "label": "Pago recibido por",
              "placeholder": "Método de pago"
            },
            "seatingMode": {
              "auto": "Assign seats automatically",
              "manual": "Pick seat(s) now",
              "autoseatInfo": "After event organiser finalises the seating"
            },
            "issue_now_modal": {
              "title": "What do you want to do next?",
              "choices": {
                "issue": "Issue tickets",
                "reservation": "Create reservation"
              }
            },
            "add_to_reservation_modal": {
              "title": "Add selection to reservation",
              "description": "You are about to add your selection to the reservation. Are you sure?"
            },
            "reservationForm": {
              "expiryDate": "Expiry Date",
              "details": "Details",
              "reservationName": "Reservation Name",
              "note": "Note",
              "customMessageContent": "Custom message content",
              "contactDetails": "Contact information",
              "firstName": "First Name",
              "lastName": "Last Name",
              "mobileNumber": "Mobile Number",
              "email": "Email",
              "notePlaceholder": "E.g. Team Building Event",
              "customMessageContentPlaceholder": "E.g. Please bring your ID"
            }
          },
          "blockCategories": {
            "notfound": "Categoría no encontrada...",
            "createCategory": "Crear categoría",
            "createCategoryTitle": "Crear categoría",
            "deleteTooltip": "Sólo se pueden eliminar las categorías vacías",
            "editCategory": "Editar categoría",
            "categoryName": "Nombre de la categoría",
            "categoryNamePlaceholder": "por ejemplo, Técnicos",
            "categoryShortNamePlaceholder": "ABC (max. 3)",
            "categoryShortName": "Shortcode",
            "uniqueShortNameError": "Los shortcodes deben ser únicos",
            "uniqueNameError": "Ya existe una categoría con este nombre",
            "selected": "{selected} de {total} seleccionados",
            "unblocked": "Las reservas se han eliminado con éxito",
            "moved": "Las reservas pasaron con éxito a la categoría",
            "printTickets": "Impreso / PDF",
            "movePrivs": "Mover a la categoría",
            "noCategory": "No hay categoría",
            "editNote": "Editar la nota",
            "addNote": "Add note | Add notes",
            "floorplanLabel": "Categorías de reservas",
            "addPrivs": "Añadir reservas",
            "addPrivsModal": {
              "title": "Añadir nuevas reservas a la categoría \"{category}\"",
              "selectPool": "Seleccionar categoría",
              "addSeated": "¿Quieres reservar asientos específicos? {0}",
              "addSeatedLink": "Ir al plano"
            },
            "categorySearch": "Seleccionar una categoría...",
            "details": {
              "ticket": "Nivel / entrada",
              "filterClear": "Limpiar el filtro",
              "filterTicket": {
                "empty": "Filtrar nivel / entrada",
                "selected": "Filtrar por {name}"
              },
              "filterSection": {
                "empty": "Filtrar la sección",
                "selected": "Filtrar por {name}"
              },
              "filterClaimed": {
                "empty": "Filtrar el estado",
                "selected": "Filtrar por confirmados"
              }
            },
            "list": {
              "fields": {
                "name": "Categoría"
              }
            },
            "editTicketNote": {
              "titleEdit": "Edit note(s)",
              "titleAdd": "Add note(s)",
              "multipleNotes": "You're editing multiple notes, this change will reflect on all the items you selected.",
              "placeholder": "Nota"
            },
            "unblockModal": {
              "title": "Liberar reserva(s)",
              "description": "Las reserva(s) seleccionadas serán liberadas y puestas a la venta.",
              "descriptionCheckedIn": "Tengas en cuenta: las reservas confirmadas no pueden ser liberadas, sólo serán desclasificadas."
            }
          },
          "externalCodes": {
            "title": "Entradas externas",
            "ticketInfo": {
              "ticketsImported": "Total de entradas importadas",
              "lastImport": "Última importación",
              "ticketsCheckedIn": "Total de entradas confirmadas"
            },
            "importCSV": {
              "title": "Importar entradas externas usando csv",
              "description": "Acepta y confirma las entradas externas en el escáner importando sus códigos de barras a través de un archivo csv. La estructura del archivo <strong>csv</strong> debe ser una sola columna que contenga el código de barras o la columna denominada \"código de barras\" (sin comillas).",
              "placeholder": "Elige un archivo csv o suéltalo aquí...",
              "deleteAndReplace": "Reemplaza las entradas existentes. Las entradas no confirmadas serán eliminadas.",
              "parsingError": "No se encontraron códigos de barras. Utiliza una sola columna o dale el nombre «código de barras» a la columna (sin comillas)",
              "uploadFailInvalidCSV": "El archivo proporcionado no es un csv válido",
              "uploadFailNoCodes": "El archivo proporcionado no contiene códigos",
              "uploadFail": "El archivo csv no pudo ser importado",
              "uploadSuccess": "{nbTickets} entradas importadas. Ejemplo de un código de barras importado: {barcodeExample}",
              "fileTooBig": "El archivo csv que estás intentando importar es demasiado grande. El tamaño máximo es de 6MB.",
              "emptyFile": "El archivo csv que estás intentando importar está vacío."
            }
          },
          "attendee": {
            "invalidateSuccess": "Las entradas han sido invalidadas con éxito.",
            "invalidationFail": "La invalidación ha fallado, por favor inténtalo de nuevo",
            "invalidateTicketConfirm": {
              "manual": "Manual refund",
              "auto": "Automatic refund",
              "credits": "Refund as credits",
              "noRefund": "Invalidate without refund",
              "creditsExpiration": "Expiration date",
              "creditsConfirm": "These credits will expire on {0} after which they can not be used anymore",
              "titleWithoutRefund": "Confirmar la invalidación",
              "refundType": "¿Cómo te gustaría reembolsar las siguientes entradas?",
              "partialRefundInfos": "Rellena la cantidad que deseas deducir por cada entrada",
              "deduct": "To deduct",
              "fullRefund": "Reembolso completo",
              "partialRefund": "Reembolso parcial",
              "deducted": "Deducido",
              "subtitle": "Está a punto de invalidar y reembolsar la siguiente entrada | Está a punto de invalidar y reembolsar las siguientes entradas:",
              "subtitleWithoutRefund": "Está a punto de invalidar la siguiente entrada | Está a punto de invalidar las siguientes entradas:",
              "totalAmount": "Importe total",
              "refundAmount": "Reembolso",
              "partialRefundErrorMessage": "La cantidad es demasiado alta",
              "toolTipText": "Si no seleccionas esta opción, las entradas se pondrán a la venta de nuevo.",
              "selectBlock": "Seleccionar categoría de reserva",
              "blockCheckbox": "Reservar las entradas que están disponibles de nuevo",
              "feeMessage": "Se cobrará la tarifa de servicio:",
              "summaryMessage": "The following ticket(s) will be invalidated.",
              "category": "The tickets are blocked on category {0}",
              "actionUndoneInfo": "Esta acción no se puede deshacer.",
              "extraConfirmModal": {
                "extraConfirmMessage": "El importe del reembolso es de {0}. Esta acción no puede deshacerse. ¿Estás seguro de que deseas invalidar y reembolsar las entradas seleccionadas?",
                "extraConfirmTitle": "Finalizar la invalidación y el reembolso",
                "extraFinalizeMessage": {
                  "text": "Escribe {0} en el campo de abajo para confirmar",
                  "finalize": "FINALIZAR"
                }
              }
            },
            "invalidateTicketOptions": {
              "title": "Invalidar y reembolsar las entradas",
              "missingPaymentMethod": "Selecciona un método de pago",
              "subtitle": "Seleccione si y cómo deseas procesar los reembolsos de las entradas que estás a punto de invalidar:",
              "tooltip": "La cantidad reembolsable es menor que la cantidad comprada. Esto puede deberse a reembolsos parciales realizados en el pasado o a los múltiples métodos de pago utilizados.",
              "automatic": {
                "title": "Invalidar con reembolso automático",
                "description": "Reembolsar automáticamente el importe pagado por las entradas al asistente. Selecciona en el siguiente paso si se le cobrará al participante o al organizador la tarifa de servicio."
              },
              "credits": {
                "title": "Refund as credits",
                "description": "Refund the amount and add these funds to account of the user to be redeemed later."
              },
              "manual": {
                "title": "Invalidar con reembolso manual",
                "description": "Reembolsar manualmente mediante un método de pago externo y ajustar los informes en consecuencia. Seleccionar en el siguiente paso si se le cobrará al participante o al organizador la tarifa de servicio."
              },
              "noRefund": {
                "title": "Invalidar sin reembolso",
                "description": "Sólo invalida las entradas, mantén la cantidad original pagada en los informes."
              }
            },
            "ticketBuyer": "comprador",
            "noGender": "Ningún género",
            "noAge": "Ninguna edad",
            "noZipcode": "Ningún código postal",
            "noCountry": "Ningún país",
            "noPhoneNumber": "No phone number",
            "noEmail": "Ningún correo electrónico",
            "noWalletLink": "No wallet link",
            "ticketOwner": "Tiene entradas",
            "activeGroupMember": "Group member",
            "inactiveGroupMember": "Has left group",
            "customerTickets": "Tickets",
            "customerOrders": "Orders",
            "customerPayments": "Payments",
            "customerRefunds": "Refunds",
            "groupTickets": "Entradas de grupo",
            "share": {
              "action": "Comparte las entradas",
              "label": "Comparte todas las entradas con:",
              "description": "Cuenta existente o nueva",
              "newUser": "Nueva cuenta",
              "anonymousUser": "Cuenta anónima",
              "alert": {
                "title": "Comparte las entradas",
                "description": "¿Estás seguro de que quieres compartir todos los boletos con <strong>{from} y <strong>{to}</strong>?",
                "newUserNotice": "Se creará una nueva cuenta para el nuevo miembro.",
                "userNotice": "Las entradas serán compartidas con la cuenta existente de: <strong>{name}</strong>."
              }
            },
            "remove": {
              "title": "Eliminar entrada | Eliminar entradas",
              "description": " ¿Estás seguro de que quieres borrar esta entrada? | ¿Estás seguro de que quieres borrar estas entradas?"
            },
            "checkin": {
              "title": "Check-in",
              "description": "¿Estás seguro de que quieres confirmar estas entradas?",
              "combiTickets": "Elige lo que quieres confirmar para las entradas combinadas:"
            },
            "undoCheckin": {
              "title": "Deshacer la confirmación",
              "description": "¿Estás seguro de que quieres deshacer la confirmación de estas entradas?",
              "combiTickets": "Elige qué confirmación quieres deshacer para las entradas combinadas:"
            },
            "email": {
              "title": "Enviar un correo electrónico al participante",
              "error": "Este correo electrónico no es válido",
              "sendEmails": "Envía correos electrónicos",
              "orderEmail": "Confirmación de pago",
              "seatingEmail": "Detalles de los asientos"
            },
            "convert": {
              "selectOption": "Selecciona las opciones de conversión",
              "convertTo": "Convertir en",
              "selectPool": "Seleccione la disponibilidad que desea utilizar",
              "selectKind": "Seleccionar entrada",
              "selectShop": "Select shop",
              "left": "quedan: {amount}",
              "paymentMethod": "Dinero recibido por",
              "paymentOptions": "{method} o {free}",
              "sendEmail": "Envía un correo electrónico de confirmación del cambio al cliente",
              "selectPayment": "Método de pago",
              "selectPaymentError": "Select payment method",
              "total": "Diferencia de precio total:",
              "new": "Crear nuevo",
              "confirmConversion": "Confirmar la conversión"
            },
            "ticketDetails": {
              "id": "ID",
              "price": "Price",
              "order": "Order",
              "orderDate": "Order date",
              "shop": "Shop",
              "ownedBy": "Owned by",
              "status": "Estado",
              "resoldBy": "Resold by",
              "on": "On",
              "onDate": "on {date}",
              "sellerPrice": "Seller Price",
              "buyerPrice": "Buyer Price",
              "buyerFees": "Buyer Fees",
              "sellerFee": "Seller Fees",
              "invalidatedBy": "Invalidated by",
              "refundMethod": {
                "cash": "Cash",
                "pin": "Pin",
                "digital": "Digital",
                "other": "Other"
              },
              "fees": "Fees",
              "payOut": "Payout",
              "resoldTo": "Resold to",
              "ticketId": "Ticket ID",
              "putForSaleBy": "Put for sale by",
              "privileges": "Privileges",
              "boughtBy": "Bought by",
              "boughtFrom": "Bought from",
              "note": "Note"
            }
          }
        },
        "seating": {
          "legend": "Leyenda",
          "sectionList": "Secciones",
          "editNote": "Editar notas",
          "standingSection": "Sección de pie",
          "capacity": "Capacidad",
          "overview": "Visión general",
          "notePlaceholder": "Añade una nota...",
          "notEnoughFree": "Libres insuficientes",
          "changes": "Cambios",
          "seatClaimed": "Las entradas confirmadas no pueden ser editadas",
          "categoryInfo": "Primero selecciona una categoría de la siguiente lista, si deseas asignar asientos a una categoría de reserva específica. Si no lo haces, los asientos seleccionados quedarán sin categoría.",
          "assign": {
            "title": "Asignar asientos a los participantes",
            "showAssigned": "Espectáculo asignado",
            "allTicketTypes": "Todas las categorías",
            "onlyMultipleTicketTypes": "Combinación de múltiples categorías"
          },
          "solve": {
            "title": "Asiento automático para todos los participantes",
            "description": "Con el asiento automático, todos los participantes serán colocados automáticamente en los mejores asientos disponibles para ese tamaño de grupo dentro de cada categoría. ¿Quieres agrupar a ciertos poseedores de entradas? Opcionalmente, puedes introducir un grupo de hasta 3 números de teléfono de cuenta.",
            "groupPlaceholder": "Número de teléfono",
            "addGroup": "Agregar grupo",
            "success": "Successfully solved!"
          },
          "finalize": {
            "title": "Finalizar los asientos actuales",
            "description": "¿Estás seguro? La finalización no se puede deshacer, todos los participantes recibirán la información de sus asientos a través de correo electrónico y sms.",
            "instructions": "Escribe {0} en el siguiente campo para confirmar:",
            "success": "Successfully finalized!"
          },
          "clearSuccess": "Successfully cleared!"
        },
        "scanning": {
          "actions": {
            "create": "Crear perfil",
            "edit": "Editar el perfil",
            "cannotEdit": "Este perfil no puede ser editado"
          },
          "creation": {
            "description": "Crea un perfil que, al ser cargado en la aplicación de escaneo, filtrará y sólo mostrará las entradas que utilicen las categorías especificadas."
          },
          "description": "{poolAmount} categoría puede ser escaneada con este perfil de escaneo. Escanea el siguiente código QR para iniciar sesión. | {poolAmount} categorías pueden ser escaneadas con este perfil de escaneo. Escanea el siguiente código QR para iniciar sesión.",
          "mainProfile": {
            "title": "Principal",
            "description": "Este es el perfil principal que permite el escaneo de todas las categoría. Escanea el siguiente código QR para iniciar sesión.",
            "warning": "¡Atención! Este código QR sólo permite el escaneo de este evento específico. Si quieres ser capaz de escanear todos tus eventos (futuros) {0}."
          }
        },
        "shop": {
          "deleteShopModalTitle": "Borra la tienda",
          "deleteShopMessage": "¿Estás seguro de que quieres borrar esta tienda?",
          "deleteShopTooltip": "Si deseas borrar la tienda, primero borra todas las entradas",
          "copyInfo": "Todas las entradas y el diseño de la tienda serán copiados en una nueva tienda. Haz clic en Confirmar si deseas continuar.",
          "notfound": "No se ha encontrado la tienda...",
          "title": "Gestionar entradas",
          "description": "Añade entradas o entradas combinadas a tu evento",
          "ticketModalTitle": "Crear entrada",
          "vat": "IVA",
          "baseTicket": "Entrada de base",
          "deleteHelper": "Las entradas sólo pueden ser retiradas si no se ha vendido ninguna todavía.<br>Siempre puedes elegir esconder una entrada.",
          "newTicket": "Nueva entrada",
          "price": "Precio en total",
          "tax": "IVA (incl.)",
          "breakdown": "Desglose de precios",
          "artwork": {
            "toggle": "Collectible Artwork",
            "error": "Your image could not be uploaded. Please try again later.",
            "title": "Upload your Collectible artwork for this ticket which will override any default Collectible artwork set under the event's Blockchain tab.<br><br>You can update this artwork at any point in the event lifecycle.",
            "template": "download template",
            "update": "Update art for NFT's already minted",
            "guidelines": {
              "title": "General guide lines",
              "line_1": "PNG, GIF or JPG filetype",
              "line_2": "Portrait mode with aspect ratio of 3:4",
              "line_3": "10 MB max file size",
              "line_4": "You can use <a href='{href}' download>our template</a> as a base"
            },
            "note": "Note: Unless you upload an image, your event NFT artwork will be used by default."
          },
          "availabilitySettings": "Availability settings",
          "advanced": "Configuración avanzada",
          "maxTicketsLabel": "Límite de entradas",
          "putForSale": "Pon la entrada a la venta",
          "from": "Disponible desde",
          "till": "Disponible hasta",
          "defaultShopTooltip": "Utiliza esta tienda de manera predeterminada cuando un usuario comparte un enlace a este evento.",
          "nameInfo": "Se usa sólo en el dashboard",
          "ticketLimitInfo": "Número máximo de entradas que un usuario puede comprar en esta tienda",
          "advancedEditPoolsLabel": "Establece el número de privilegios de la categoría que el usuario recibe al comprar esta entrada (máximo 20 por categoría)",
          "maxAmount": "Cantidad máxima",
          "pages": {
            "deletePageModalTitle": "Eliminar la página",
            "deletePageMessage": "Todas las entradas de esta página serán movidas a la primera página. ¿Estás seguro de que quieres borrar esta página?",
            "actions": {
              "moveToPage": "Mover a la página",
              "create": "Crear la página",
              "edit": "Editar la página"
            }
          },
          "access": {
            "title": "Enlaces y códigos de inserción para la tienda: {name}",
            "linksTitle": "Esta tienda es accesible a través de las siguientes puertas y enlaces:",
            "description": "Las puertas pueden tener múltiples tiendas en diferentes eventos entre los cuales que el usuario pueda elegir. De manera predeterminada, cada tienda tiene su propia puerta (enlace).",
            "draftAlert": "Este evento no se ha publicado todavía. La(s) puerta(s) no mostrará(n) este evento hasta que sea publicado.",
            "singleGate": "Puerta de una sola tienda"
          },
          "availability": {
            "title": "Disponibilidad",
            "description": "De manera predeterminada las entradas están disponibles de <strong>{from}</strong> hasta <strong>{ends}</strong>. La cantidad máxima que se puede pedir es <strong>{amount}</strong> entradas.",
            "immediately": "inmediatamente",
            "eventEnds": "el evento termina",
            "relative": "{days} días antes de que el evento termine, a la hora {time}",
            "absoluteStart": "de {date}",
            "startLabel": "La tienda abre",
            "endLabel": "La tienda cierra"
          },
          "accessControl": {
            "title": "Access to Shop",
            "description": "Access to the shop will be provided once a collectible out of the selected collection(s) is found in the customer's wallet. Select the OpenSea collection(s) below that can be used to grant access to the shop.",
            "linkLabel": "Link to OpenSea",
            "urlPlaceholder": "opensea.io/...",
            "collectionsListLabel": "Collections selected",
            "errors": {
              "collectionNotFound": "The collection was not found.",
              "invalidUrl": "This is not a valid OpenSea collection URL.",
              "duplicateUrl": "This collection is already added.",
              "wrongChain": "The chain where this collection is deployed is not supported. Currently, we only support Ethereum Mainnet, Polygon Mainnet and Polygon Mumbai Testnet.",
              "openseaError": "An error occurred while fetching the collection. Please try again later.",
              "invalidCollection": "This collection doesn't have an NFT contract.",
              "noCollections": "No collections selected, please add at least one collection."
            }
          },
          "empty": {
            "action": "Crear la primera entrada",
            "title": "Crear entradas"
          },
          "actions": {
            "createShop": "Crear la tienda",
            "copyShop": "Copiar la tienda",
            "newShop": "Nueva tienda",
            "editShop": "Editar la tienda",
            "createGroup": "Crear grupo",
            "editGroup": "Modificar grupo",
            "createNewPool": "Crear una categoría",
            "addTicket": "Crear entrada",
            "addRegular": "Ticket",
            "addCombi": "Entrada combinada",
            "addUpsell": "Venta incremental",
            "addGuestVariant": "Lista de invitados",
            "addGroup": "Crear grupo",
            "saveLayout": "Guarda el diseño",
            "saveDraft": "Guarda como proyecto",
            "addField": "Añade una nueva línea",
            "saveTemplate": "Guarda la plantilla"
          },
          "group": {
            "ticketPlaceholder": "Arrastra un ticket aquí para crear un grupo",
            "collapseCheckbox": "Contraer por defecto",
            "messagePlaceholder": "Mensaje de grupo para mostrar en la lista de entradas...",
            "defaultTitle": "Nuevo grupo"
          },
          "blocksHelper": {
            "title": "¿Quieres editar el número de reservas? {0}",
            "link": "Ir a las categorías de reservas"
          },
          "gates": {
            "title": "Esta tienda es accesible a través de las siguientes puertas (enlaces):",
            "description": "Las puertas pueden tener múltiples tiendas en diferentes eventos entre los cuales que el usuario pueda elegir. Por defecto, cada uno tiene su propia (única) puerta.",
            "localGate": "Esta tienda",
            "defaultGateError": "Cada tienda debe tener una puerta por defecto.\nEstablece una puerta por defecto haciendo clic en una de las estrellas.",
            "defaultTooltip": "Utiliza esta puerta por defecto cuando te conectas a esta tienda"
          }
        },
        "checkin": {
          "title": "Self check-in",
          "description": "Scan the QR code to check-in attendees",
          "waiting": "Waiting for QR code...",
          "AllClaimed": "All claimed",
          "checkin": "Check-in {amount} ticket(s)",
          "success": "{amount} ticket(s) checked in succesfully!",
          "errors": {
            "noPrivileges": "This QR code does not contains tickets",
            "wrongEvent": "The QR code belongs to a different event"
          }
        },
        "pools": {
          "title": "Categorías",
          "addNew": "Crear una categoría",
          "notFound": "Categoría no encontrada...",
          "poolSelection": "Selecciona una o varias categorías para crear una entrada. Para los productos de venta incremental, usa las relativas categorias. Si no encuentras la categoría que buscas, puedes",
          "createNewPool": "crear una nueva.",
          "guestTicket": "Esta es una entrada de invitado",
          "toolTipEdititon": "Esta categoría no puede ser editada",
          "poolModal": {
            "ticketPrivilege": "ticket-privileges",
            "privilege": "privilege",
            "entrance": "Entrance",
            "upsell-text": "Upsell",
            "create": "Crear categoría",
            "description": "A privilege pool is used to determine the total number of so called {ticketPrivileges} (or {privilege} in short), that can be issued. A privilege is of the type {Entrance} or {Upsell}. 1x capacity equals 1 privilege.",
            "update": "Editar categoría",
            "name": "Nombre",
            "validFrom": "Válido desde",
            "validTo": "Válido hasta",
            "blocks": "Reservas (restados del total)",
            "totalAmoutOfTickets": "Número total de entradas",
            "blockHelperText": "Siempre puedes editar el número de reservas y categorías más tarde.",
            "poolNameHelperText": "For instance 'VIP' or 'T-Shirt'",
            "totalCapacityHelperText": "Maximum capacity to be issued",
            "upsell": "La disponibilidad de las entradas no se ve afectada por los productos de venta incremental.",
            "deleteMessage": "¿Estás seguro de que quieres borrar esta categoría?",
            "deleteTitle": "Eliminar",
            "privilegeType": "Privilege type",
            "tellMeMore": "Tell me more",
            "listItem1": "Use to grant access to an event (or livestream)",
            "listItem2": "Total amount of tickets a user can get of this type can be limited per shop ",
            "listItem3": "Use for additional services/products (like drinks/merchandise)",
            "listItem4": "Does not count towards the users' amount of tickets limited per shop"
          },
          "details": {
            "sold": "Vendido",
            "tickets": "Entradas",
            "shop": "Tienda",
            "price": "Precio"
          }
        }
      }
    },
    "reservations": {
      "addTickets": "Add tickets",
      "ticketsCount": "Ticket ({count}) | Tickets ({count})",
      "unclaimedTicketCount": "Unclaimed ({count})",
      "claimedTicketCount": "Claimed ({count})",
      "reservationsOverviewTitle": "Your reservations",
      "draftConfirmInfo": "Publish the event to enable confirming",
      "details": {
        "editTitle": "Edit reservation details"
      },
      "cancel": {
        "cancelTitle": "Cancel reservation",
        "cancelDescription": "Are you sure you want to cancel the reservation? This cannot be undone.",
        "yesCancelReservation": "Yes, cancel reservation",
        "blockTickets": "Block tickets that are available again",
        "blockTicketsDescription": "If you don't select this option, tickets will be up for sale again"
      },
      "confirm": {
        "title": "Confirm tickets",
        "descriptions": {
          "paidConfirmAll": "Are you sure you want to confirm the sale of all tickets in this reservation? Once confirmed, you'll be able to distribute the tickets. Please specify the payment method:",
          "freeConfirmAll": "Are you sure you want to confirm the sale of all tickets in this reservation? Once confirmed, you'll be able to distribute the tickets.",
          "paidConfirmPartial": "Are you sure you want to confirm the sale of the selected tickets in this reservation? Once confirmed, you'll be able to distribute the tickets. Please specify the payment method:",
          "freeConfirmPartial": "Are you sure you want to confirm the sale of the selected in this reservation? Once confirmed, you'll be able to distribute the tickets."
        }
      },
      "release": {
        "action": "Release",
        "title": "Release tickets",
        "description": "Are you sure you want to release the selected tickets from the reservation?"
      },
      "distributionModal": {
        "title": "Distribute tickets ({count})",
        "description": "Fill the email addresses of people to whom you want to distribute the selected tickets.",
        "allToOne": "All tickets to one person",
        "invitationEmail": "Invitation email address",
        "confirmText": "Send invitations"
      },
      "distribution": {
        "title": "Distribute Tickets",
        "emails": {
          "title": "Distribute tickets to the following email addresses",
          "info": "You can also enter multiple entries separated by a space, comma, or semi-colon.",
          "duplicates": {
            "title": "Duplicates",
            "message": "The following duplicate emails were not added to the list: <strong>{list}</strong>"
          },
          "errors": {
            "exceedsMax": "{count} out of {max} people added. Remove {remove_count} entries or reduce the number of tickets to distribute per person.",
            "validation": "One or more added email addresses are not valid, please correct them!"
          },
          "entriesLeft": "{count} entries left"
        },
        "tickets": {
          "title": "Each entry will receive the following tickets",
          "ticketLeft": "ticket left | tickets left"
        },
        "send": {
          "title": "Send invitations",
          "summary": {
            "title": "Summary",
            "description": "Each invitation contains the following ticket(s):"
          },
          "extraMessage": {
            "title": "Personal message",
            "description": "If you want you can add a short extra message to the ticket invitation email."
          },
          "sendAction": "Send invitations",
          "success": "Successfully created and sent out invitations!",
          "errors": {
            "create": "Failed to create invitations!",
            "send": "Failed to send invitations"
          }
        },
        "resend": {
          "title": "Resend invitation",
          "areYouSure": "Are you sure want to resend this invite?",
          "description": "We'll send another invitation to \"{email}\"",
          "error": "Failed to send invitation!",
          "success": "Succesfully sent out invitation!"
        },
        "revoke": {
          "title": "Revoke invitation",
          "areYouSure": "Are you sure want to revoke this invite?",
          "description": "After revoking, the tickets will be available again in the reservation to distribute.",
          "error": "Failed to revoke invitation!",
          "success": "Succesfully revoked invitation!"
        }
      }
    },
    "scanning": {
      "selectOrganisation": "Selecciona una organización para poder ver la información de acceso al escaneo",
      "selectOrganisationMessage": "Seleccione una organización",
      "organisations": "Organizaciones",
      "scannerInfo": "Utiliza la información que aparece a continuación para iniciar sesión en el escáner. Ten en cuenta que esta información de inicio de sesión se comparte en toda la organización y no es específica de un evento.",
      "downloadInfo": "Descarga la aplicación GET In en tu smartphone:",
      "passwordInfo": "Utiliza la contraseña o escanea el código QR para iniciar sesión",
      "noOrganisationSelected": "Aún no has seleccionado una organización."
    },
    "navigation": {
      "eventPreview": {
        "label": "Previsualiza el proyecto de evento",
        "title": "Vista previa del evento",
        "content": "Ve tu evento y compra entradas de prueba para asegurarte de que todo está bien organizado.<br><br>Esta vista previa es exactamente como tus visitantes experimentarán el flujo, excepto por el pago que, en el modo de vista previa, utiliza un proveedor de pago de prueba para que puedas comprar entradas de prueba.<br><br>Las entradas de prueba se quedarán en tu cuenta, pero no afectarán a las ventas y estadísticas reales."
      },
      "eventUrlInfo": "Tu evento estará disponible en esta url después de ser publicado",
      "back": "Volver a todos mis eventos"
    },
    "prices": {
      "title": "Your prices",
      "addPrice": "Crear tipo de precio",
      "editPrice": "Editar el precio",
      "editOverviewDescription": "Los siguientes eventos y entradas comparten este mismo tipo de precio. Editar este tipo de precio, afectará a todos ellos. Si desea continuar haga clic en confirmar.",
      "table": {
        "name": "Tipo de precio",
        "amount": "Cantidad",
        "usedBy": "Usado por"
      },
      "priceSelect": {
        "noAvailable": "No hay tipos de precios globales disponibles para esta organización."
      }
    },
    "gates": {
      "title": "Your gates",
      "open": "Fecha de apertura",
      "close": "Fecha de cierre",
      "slug": "Slug",
      "create": "Crear la puerta",
      "gate": {
        "description": "Esta puerta es accesible cuando se abre la primera tienda <strong>{start}</strong> hasta que el último evento en esta puerta termina <strong>{ends}</strong>.",
        "descriptionPrequeue": "La cola previa está <strong>habilitada</strong>.",
        "descriptionAccess": "Los enlaces de acceso están <strong>habilitados</strong>.",
        "gateLinks": {
          "title": "Enlace e códigos de inserción de la puerta",
          "linkInfo": "Utiliza siempre este enlace cuando compartes la URL - No lo copies de la barra del navegador.",
          "link": "Enlace directo a esta puerta",
          "embed": "Códigos de inserción de esta puerta"
        },
        "accessLinks": {
          "nav": "Enlaces de acceso",
          "title": "Generar enlaces de acceso",
          "description": "Rellena la cantidad de enlaces de acceso que deseas y haz clic en Exportar como csv. Los enlaces de abajo son de <strong>ejemplo</strong>.",
          "action": "Exportar como csv"
        }
      },
      "gateSelect": {
        "noAvailable": "No hay puertas disponibles para esta organización. Puedes crear una nueva."
      },
      "shops": {
        "pastLabel": "Mostrar eventos pasados",
        "searchPlaceholder": "Buscar eventos o tiendas...",
        "nonGateShopsTitle": "Todos los eventos y tiendas",
        "gateShopsTitle": "Tiendas en esta puerta ({count})",
        "addToGate": "Añadir ({count}) tienda | Añadir ({count}) tiendas",
        "removeFromGate": "Eliminar ({count}) tienda | Eliminar ({count}) tiendas",
        "selectInfo": "Para seleccionar varias tiendas, puedes pulsar shift (mayús) o control (comando en Mac) y seleccionar, o hacer clic y arrastrar."
      },
      "modal": {
        "create": "Crear la puerta",
        "edit": "Editar la puerta",
        "optionalSlug": "Slug (opcional)",
        "nameDescription": "Este nombre sólo se utiliza en el dashboard como identificador",
        "delete": {
          "title": "Borrar la puerta",
          "description": "¿Estás seguro de que quieres borrar esta categoría? Si es así, haz clic en confirmar."
        },
        "display": {
          "title": "Pantalla",
          "description": "Selecciona cómo quieres mostrar tus eventos:",
          "eventList": "Mostrar eventos en la lista",
          "calendar": "Mostrar eventos en el calendario",
          "override": "Anular la información del evento",
          "overrideDescription": "Por defecto, esta información se establece automáticamente en base a los eventos añadidos a esta puerta."
        },
        "access": {
          "title": "Acceder",
          "accessLinks": "Requiere enlaces de acceso",
          "accessLinksDescription": "Esto hará que esta puerta sea accesible sólo para las personas con un enlace de acceso",
          "prequeue": "Habilitar la cola previa",
          "prequeueDescription": "Esto permite a los usuarios registrarse en la cola antes de que empiece la venta"
        }
      }
    },
    "templates": {
      "title": "Your templates",
      "createTemplate": "Crear una plantilla",
      "selectTemplate": "Seleccionar la plantilla",
      "noAvailable": "No hay ninguna plantilla disponible",
      "templateNotFound": "No se encontró la plantilla...",
      "status": {
        "syncing": "Sincronización...",
        "unsynced": "Fuera de sincronía",
        "synced": "Todo sincronizado"
      },
      "sync": {
        "title": "Sincronizar los cambios de la plantilla con los próximos eventos",
        "description": "Hiciste cambios en esta plantilla. Para actualizar todos los eventos usando esta plantilla, puedes sincronizar tus cambios aquí. Tengas en cuenta <b>que todos los cambios</b> realizados se aplicarán a <b>todos los próximos eventos</b> creados con esta plantilla.",
        "action": "Sincronizar los cambios",
        "syncNow": "Sincronizar ahora",
        "changesApplied": "Tus cambios serán aplicados a estos {0} próximos eventos",
        "changesApplying": "¡Todos los próximos {0} eventos están sincronizados!",
        "eventsSyncing": "Sincronización de eventos en progreso...",
        "SyncStarted": "La sincronización comenzó el {date}",
        "lastSync": "Última sincronización en {date}",
        "errors": {
          "nothingToSync": "Esta plantilla ya está sincronizada",
          "beingSynced": "Esta plantilla ya está siendo sincronizada"
        },
        "warningModal": {
          "description": "Todos los cambios en esta plantilla serán sincronizados con todos los próximos eventos gestionados por esta plantilla. Hay algunas reglas que pueden aplicarse para evitar cualquier error:",
          "lowerCapacity": "Si has reducido la capacidad de las categorías pero el/los evento(s) ya ha(n) vendido más entradas que la capacidad reducida, entonces el tamaño de la categoría para ese evento específico se fijará en lo que se vende actualmente. Por ejemplo: la capacidad cambió de 100 a 50 - el evento ya ha vendido 60 - el tamaño de la categoría para ese evento específico se fijará en 60.",
          "removeTickets": "Cuando hayas eliminado las entradas que ya se han vendido para eventos específicos, esas entradas no se eliminarán, sino que se ocultarán (en las tiendas).",
          "removeShop": "Cuando hayas eliminado una tienda pero los eventos específicos todavía tienen entradas en esa tienda específica, entonces esa tienda no será eliminada de ese evento, sino cerrada en su lugar."
        }
      },
      "templateAlert": "¡Tengas en cuenta! Si deseas hacer cambios, todos los eventos relacionados con esta plantilla se actualizarán también.",
      "createFromTemplate": {
        "title": "Crear evento desde la plantilla",
        "createInfo": "Todos los ajustes de la plantilla se copiarán en este evento.",
        "templateInfo": {
          "text": "Puedes crear una nueva plantilla {link}.",
          "link": "aquí"
        },
        "timeInfo": "Hora local: {timezone}, {time}. La hora del evento se configura desde la plantilla.",
        "errors": {
          "templateBeingSynced": "No puedes crear un evento desde esta plantilla porque está siendo sincronizada.",
          "templateNotSynced": "No puedes crear un evento desde esta plantilla porque no está sincronizado."
        }
      }
    },
    "schedules": {
      "title": "Your schedules",
      "create": {
        "title": "Crear el programa"
      },
      "schedulePatterns": {
        "description": "Las siguientes plantillas se utilizarán para generar múltiples eventos para el período que especifique.",
        "action": "Añadir plantilla",
        "days": "Días",
        "exceptions": "Excepciones",
        "modal": {
          "addTemplate": "Selecciona la plantilla a ser añadida al programa",
          "edit": "Editar el patrón de programación",
          "pattern": "¿En qué días de la semana ocurren los eventos?",
          "exception": "Añadir la fecha de excepción (opcional)",
          "addException": "Add exception"
        }
      },
      "entries": {
        "startDate": "Fecha de inicio",
        "endDate": "Fecha de finalización",
        "description": "Generar nuevos eventos a partir de las plantillas especificando el primer y último día. Las fechas de excepción serán omitidas.",
        "generate": "Generar eventos",
        "syncWarning": "¡Los eventos no pueden ser generados porque una o más plantillas están desincronizadas! Sincroniza tus plantillas primero antes de generar nuevos eventos.",
        "newItemStatus": "Se generarán los eventos <strong>{patternName}</strong>",
        "confirmDescription": "Se generarán y publicarán los siguientes eventos:",
        "confirmContinue": "Si desea continuar haga clic en confirmar.",
        "rangeAlert": "Sólo puedes generar un máximo de 1 año de una vez. La fecha final debe ser antes de {maxDate}.",
        "countAlert": "Estás a punto de crear {count} nuevos eventos de una vez. ¿Estás seguro?",
        "tooltip": {
          "existing": "Existente",
          "generate": "Para generar"
        }
      }
    },
    "cashier": {
      "inDraft": "This event is not published yet",
      "inDraftInfo": "You can only create reservations in draft events. Issuing tickets directly through the cashier or confirming/distributing reserved tickets can only be done once the event is published. If you want to get full access to the features, {0}.",
      "inDraftInfoCallToAction": "publish this event",
      "filters": {
        "available": "Available",
        "blocks": "Blocks",
        "fromBlocks": "From blocks",
        "price": "Price",
        "type": "Type",
        "ticket": "Ticket",
        "upsell": "Upsell",
        "shop": "Shop",
        "pool": "Pool",
        "lockedShop": "You can only select tickets from {shopName} shop. To select a different shop you will have to empty your cart first.",
        "lockedShopReservation": "You can only select tickets from {shopName} shop. to select a different shop you will have to create additional reservation."
      },
      "abortAction": {
        "title": "Cancel order",
        "description": "Are you sure you want to cancel this order? All tickets in the cart will be removed and you will not be able to undo this action.",
        "confirm": "Yes, cancel this order"
      },
      "removeTickets": {
        "title": "Remove {ticketName} tickets",
        "description": "How many {ticketName} tickets do you want to remove?",
        "max": "max"
      },
      "removeSeatedTickets": "Which tickets do you want to remove?",
      "removeAll": {
        "title": "Remove all",
        "description": "Do you want to remove all tickets from the cart?",
        "confirm": "Remove all"
      },
      "removeOne": {
        "title": "Remove ticket",
        "description": "Are you sure you want to remove this ticket?",
        "confirm": "Remove ticket"
      },
      "confirmationModal": {
        "titleOrder": "Create order",
        "titleReservation": "Create reservation",
        "description": {
          "detailsOnly": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will not receive any confirmation.",
          "detailsAndSMS": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS.",
          "detailsAndEmail": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive an email confirmation with the event & ticket information.",
          "detailsSMSAndEmail": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS and an email confirmation with the event & ticket information.",
          "onlyEmail": "Tickets are not linked to the customers account because no phone number was provided. If you want to give the customer the tickets, you need to print the order. The customer will not receive any confirmation.",
          "onlySMS": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS.",
          "onlyNumber": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will not receive any confirmation."
        },
        "notifySMS": "Notify via SMS",
        "notifyEmail": "Notify via email",
        "reservation": {
          "name": "Reservation name",
          "note": "Note",
          "expiry": "Expiry"
        }
      },
      "complete": "Complete order"
    }
  },
  "errors": {
    "not-found": {
      "title": "Can’t access this page",
      "description": "You either do not have permission to view this page or it simply does not exist.",
      "contactSupport": "If you believe this to be an error, contact the organisation you trying to access, or {0} for help.",
      "link": "contact support"
    }
  },
  "filter": {
    "filterBy": "Filter by {name}",
    "operators": {
      "e": "Equals to",
      "lte": "Less than or equal to",
      "gte": "Greater than or equal to",
      "lt": "Less than",
      "gt": "Greater than"
    },
    "timeOperators": {
      "last": "Is in the last",
      "equal": "Is equal to",
      "between": "Is between",
      "after": "Is after",
      "onafter": "Is on or after",
      "before": "Is before",
      "onbefore": "Is before or on"
    },
    "times": {
      "days": "Days",
      "weeks": "Weeks",
      "months": "Months",
      "years": "Years"
    },
    "aria": {
      "open": "Open filter menu",
      "close": "Close filter menu",
      "remove": "Remove filter",
      "add": "Add {name} filter",
      "openAdd": "Open add filter menu",
      "clearAll": "Clear all filters"
    },
    "addFilter": "Add filter",
    "clearAll": "Clear all",
    "select": "Select",
    "selectAll": "Select all",
    "between": "And"
  },
  "resaleFeeConfig": {
    "sellerTitle": "Seller fees",
    "buyerTitle": "Buyer fees",
    "fixedTitle": "Fixed fees",
    "fixedLabel": "Fixed fee per ticket",
    "percTitle": "Percent fees",
    "percLabel": "Percent fee per ticket",
    "totalLabel": "Total fee",
    "getFeeLabel": "Base - GET International",
    "partnerFeeLabel": "Ticketeer fee",
    "override": {
      "label": "Ticketeer fee override",
      "confirm": {
        "title": "Override Ticketeer default fees",
        "description": "Are you sure you want to override the resale market fees for this organization? Any changes to the resale market fee plans will only apply for newly published events. Events that are currently on sale will retain the current fee configuration."
      }
    }
  }
}
