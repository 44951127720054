const Customer = () => import('@/views/customers/Customer.vue');

export default {
  path: '/customers/:id',
  name: 'customer',
  component: Customer,
  meta: {
    auth: true,
    org: true,
    permissions: {
      access: ({ getters }, _) => getters['Organizations/getPermission']('attendee.read'),
      redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
    },
  },
};
