export default class RouteManagement {
  constructor(router) {
    this._router = router;
  }

  /**
   * Gets a route by its name
   * routes can have nested children
   * @param {*} name route trying to find
   * @param {*} currentRoute current route that's being searched
   * @param {*} children current route's children
   */
  _getRoute({ name }, currentRoute, children) {
    let result = null;

    if (currentRoute.name === name) {
      return currentRoute;
    }
    for (let i = 0; i <= children.length; i += 1) {
      const r = children[i];

      if (r) {
        result = this._getRoute({ name }, r, r.children || []);
      }

      if (result) return result;
    }
    return null;
  }

  /**
   * Checks if a given route is accessible
   * @param {*} name name of the route to access
   * @param {*} store current store
   * @param {*} to route object trying to access
   */
  _hasAccess(store, to) {
    const { routes } = this._router.options;
    const route = this._getRoute({ name: to.name }, {}, routes);
    const notFound = { name: 'notFound', params: { 0: to.path } };

    if (!route) return { access: false, redirect: notFound };

    if (route.meta?.permissions) {
      const access = route.meta.permissions.access(store, to);
      const redirect = route.meta.permissions.redirect ? route.meta.permissions.redirect(store, to) : notFound;
      return {
        access,
        redirect,
      };
    }

    return { access: true };
  }

  /**
   * Run every time route changes.
   * Continues with the passed route or redirects depending on the access
   * @param {*} route  route params
   * @param {*} store current store
   */
  resolveRoute({ to, next }, store) {
    const { access, redirect } = this._hasAccess(store, to);
    if (access) {
      next();
    } else {
      const query = { ...to.query, ...redirect.query };
      const params = { ...to.params, ...redirect.params };
      next({ name: redirect.name, params, query });
    }
  }
}
