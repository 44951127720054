import { RESIZE_PREFIX, IMAGE_SIZES } from '@/helpers/constants';
import { i18n } from '@/lang';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { Validation } from '@vuelidate/core';

function getPrivilegeSummary(privileges) {
  return privileges.reduce((acc, p) => {
    const pSummary = acc.find((info) => info.type === p.type);
    if (pSummary) {
      pSummary.total += 1;
      pSummary.checked_in += p.claimed ? 1 : 0;
      return acc;
    }
    acc.push({
      type: p.type,
      total: 1,
      checked_in: p.claimed ? 1 : 0,
    });
    return acc;
  }, []);
}

function getTicketsPerKind(tickets) {
  return tickets.reduce((acc, t) => {
    const tk = acc.find((kind) => kind.id === t.kind);
    if (tk) {
      tk.count += 1;
      tk.privileges = tk.privileges.concat(t.privileges);
      return acc;
    }
    acc.push({
      id: t.kind,
      name: t.kind_name,
      count: 1,
      guest: t.guest,
      upsell: t.upsell,
      privileges: t.privileges,
    });
    return acc;
  }, []);
}

function groupBy(arr, keyFunc) {
  return arr.reduce((acc, item) => {
    const val = keyFunc(item);
    acc[val] = acc[val] || [];
    acc[val].push(item);
    return acc;
  }, {});
}

function getColorIndex(index) {
  const COLOR_LENGTH = 12; // TODO: Change to styles.colorTiersLength (fails test now)
  return index % COLOR_LENGTH;
}

function idsToColorindexMap(ids, start = 0) {
  const result = {};
  ids.sort((a, b) => a - b);
  ids.forEach((id, index) => {
    result[id] = getColorIndex(start + index);
  });
  return result;
}

function getCoordinates(element) {
  const box = element.getBoundingClientRect();
  const boxWidth = element.offsetWidth;

  return {
    top: box.top + window.pageYOffset,
    left: box.left + window.pageXOffset + boxWidth / 2,
  };
}

function generateDownload(data, fileType, fileName) {
  return new Promise<void>((resolve) => {
    const csvData = new Blob([data], { type: `${fileType};charset=utf-8;` });

    // @ts-expect-error  msSaveBlob only exists on IE11 & Edge
    if (navigator.msSaveBlob) {
      // @ts-expect-error  msSaveBlob only exists on IE11 & Edge
      navigator.msSaveBlob(csvData, fileName);
      resolve();
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(csvData);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    // allow save popup to get necessary data before removing link
    setTimeout(() => {
      document.body.removeChild(link);
    }, 1000);

    resolve();
  });
}

function stripHTMLTags(input: string): string {
  if (!input) return '';
  const inputConvertedSpaces = input.replace(/&nbsp;/gi, ' ');
  return inputConvertedSpaces.replace(/<[^>]+>/gi, '');
}

function printWindow(iframe, tries = 1) {
  setTimeout(() => {
    if (tries >= 5 || iframe.document.readyState === 'complete') {
      iframe.window.print();
    } else {
      tries += 1;
      printWindow(window, tries);
    }
  }, 1000);
}

function printHtml(content) {
  const frameName = 'printFrame';
  let printFrame = window.frames[frameName];
  if (!printFrame) {
    const frameEl = document.createElement('iframe');
    frameEl.style.display = 'none';
    frameEl.setAttribute('name', frameName);
    document.body.appendChild(frameEl);
    printFrame = window.frames[frameName];
  }
  // get css, use full path in link tags and add to iframe
  const head: HTMLElement = document.getElementsByTagName('head')[0].cloneNode(true) as HTMLElement;
  const rootUrl = document.location.origin;
  const links = head.getElementsByTagName('link');
  [...links].forEach((link) => {
    link.href = link.href.startsWith('/') ? `${rootUrl}${link.href}` : link.href;
  });
  printFrame.document.head.innerHTML = head.innerHTML;
  printFrame.document.body.innerHTML = content;
  printWindow(printFrame);
  return true;
}

function sortByName(arr) {
  return arr.slice().sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
}

// filterURLQueryParams the following values from query params:
// emptry strings, undefined, null, default values and array values with only empty strings
// deep equality between defaults is done with JSON.stringify so order of the array/objects will need to match
type queryParams = Record<string, undefined | null | number | string | Array<string | number>>;
export function filterURLQueryParams(query: queryParams, defaults: queryParams = {}): queryParams {
  return Object.entries(query)
    .filter(
      ([key, value]) =>
        // we use JSON.stringify for deep equality between arrays
        JSON.stringify(value) !== JSON.stringify(defaults[key]) &&
        value !== '' &&
        value !== undefined &&
        value !== null &&
        (Array.isArray(value) ? value.some((v) => v !== '') : true),
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}); // convert back to key:value object
}

// Helper function for pushing into router search query
// context: Object(Vue component)
// filter: Object  - query
// params: Object - additional parameters that needed to be in the next route context
export function makeNewQuery(context, filter, params) {
  const newQuery = { ...context.$route.query };

  Object.assign(newQuery, filter);

  context.$router.push({
    name: context.$route.name,
    query: filterURLQueryParams(newQuery),
    params,
  });
}

function slugify(string) {
  const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');
  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with ‘and’
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple — with single -
    .replace(/^-+/, ''); // Trim — from start of text .replace(/-+$/, '') // Trim — from end of text
}

function getClosestImageSize(size) {
  return IMAGE_SIZES.reduce((prev, curr) => (Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev));
}

function getResizedImage(imageUrl, size) {
  if (size < 0) return imageUrl;

  try {
    const width = getClosestImageSize(size);
    const url = new URL(imageUrl);
    url.pathname = `${RESIZE_PREFIX}${width}${url.pathname}`;
    return url.href;
  } catch (error) {
    return imageUrl; // log the error but return the original image
  }
}

async function getAllPages(axios, url) {
  let results = [];
  while (url) {
    // eslint-disable-next-line no-await-in-loop
    const response = await axios.get(url);
    results = results.concat(response.data.results);
    url = response.data.next;
  }
  return results;
}

function isMultiDay(event) {
  const actualEnds = event.ends_on_previous_day ? moment(event.ends).subtract(1, 'day') : moment(event.ends);
  return !moment.tz(event.when, event.local_timezone).isSame(actualEnds, 'day');
}

function eventEnds(ends, localTimezone, endsOnPreviousDay) {
  return endsOnPreviousDay ? moment.tz(ends, localTimezone).subtract(1, 'days').format() : ends;
}

export function handleApiNotFound({ error, router, routeName, path }) {
  if (!router || !routeName) throw error;
  if (error && error.response.status === 404) {
    router.replace({ name: routeName, params: { 0: path } });
  }
  throw error;
}

export function convertToPercent(value) {
  const elements = value.split(/\.|,/);
  const decimals = elements[1] && elements[1].length >= 2 ? elements[1].length - 2 : 0;
  return (value * 100).toFixed(decimals);
}

export function convertToDecimals(value) {
  const elements = value.split(/\.|,/);
  const integer = (elements[0] / 100).toFixed(2);
  return elements[1] ? integer + elements[1] : integer;
}

export function getFullName(firstName, lastName) {
  if (!firstName && !lastName) return i18n.global.t('views.customers.customer.noName');
  return [firstName, lastName].join(' ').trim();
}
export function getGroupedPrivileges(privilegesIds, privilegeTypes) {
  const sections = {
    access: { amount: 0 },
    upsell: { amount: 0 },
  };
  privilegesIds?.forEach((id) => {
    const privilegeType = privilegeTypes.find((pt) => pt.id === id);
    if (privilegeType) {
      if (privilegeType.upsell) {
        sections.upsell.amount += 1;
      } else {
        sections.access.amount += 1;
      }
    }
  });

  return sections;
}
export function getAvailableTicketsAmount(ticketKind) {
  const ticketsForSale = ticketKind.tickets_for_sale ? ticketKind.tickets_for_sale : ticketKind.pool_size;
  const ticketsSold = ticketKind.sold ? ticketKind.sold : 0;
  const availableAmount =
    ticketKind.max_amount || ticketKind.max_amount === 0
      ? Math.min(ticketsForSale, ticketKind.max_amount - ticketsSold)
      : ticketsForSale;
  return availableAmount < 0 ? 0 : availableAmount;
}

export function getFiltersFromUrlQuery(query: { [key: string]: string }) {
  const filtersFromQuery = Object.entries(query).reduce((acc, [key, value]) => {
    if (key.startsWith('filter-')) {
      const parsedFilter = JSON.parse(value);
      return {
        ...acc,
        [key]: { key: key.replace('filter-', ''), result: parsedFilter },
      };
    }
    return acc;
  }, {});
  return filtersFromQuery;
}

export function shortenString({ string, maxLength = 40 }) {
  if (string.length <= maxLength) return string;
  const startLength = Math.floor((maxLength - 3) / 2);
  const endLength = Math.ceil((maxLength - 3) / 2);
  return `${string.slice(0, startLength)}...${string.slice(-endLength)}`;
}

export function generateUUID() {
  // @ts-expect-error  This was written before typescript got implemented and unsure if it's actually wrong so leaving it as is
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

const getFirstErrorKey = (v: Validation) => {
  if (!v.$error) return null;

  const validations = Object.entries(v).filter((item) => item[0].charAt(0) !== '$');

  const error = validations.find((val) => {
    if (typeof val[1] === 'object' && val[1] !== null) {
      return val[1].$invalid;
    }

    return val[1] === false;
  });

  if (!error) return null;

  return `validation.error.${error[0]}` || '';
};

export function validationErrorKeyToString(validator, options) {
  if (!validator) return '';

  if (typeof validator === 'string') {
    return i18n.global.t(validator, options);
  }

  if (validator) {
    const validatorKey = getFirstErrorKey(validator);

    if (validatorKey) {
      return i18n.global.t(validatorKey, options);
    }
  }

  return null;
}

export default {
  getPrivilegeSummary,
  getTicketsPerKind,
  idsToColorindexMap,
  getCoordinates,
  generateDownload,
  stripHTMLTags,
  printHtml,
  sortByName,
  slugify,
  getResizedImage,
  getAllPages,
  filterURLQueryParams,
  isMultiDay,
  groupBy,
  eventEnds,
  makeNewQuery,
  getGroupedPrivileges,
  getAvailableTicketsAmount,
  shortenString,
  generateUUID,
  validationErrorKeyToString,
};
