import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { getBrowserLanguages } from '@/helpers/locale';

export default {
  install: (app) => {
    const browserLocales = getBrowserLanguages().map((l) => (l.toLowerCase() === 'en-us' ? 'en' : l));

    moment.locale(browserLocales);

    app.config.globalProperties.$moment = moment;
    app.provide('moment', moment);
  },
};
