import { createRouter, createWebHistory } from 'vue-router';

import Events from '@/router/events';
import Organizations from '@/router/organizations';
import Schedules from '@/router/schedules';
import Auth from '@/router/auth';
import Scanning from '@/router/scanning';
import Customers from '@/router/customers';
import Customer from '@/router/customers/customer';
import Reports from '@/router/reports';
import Gates from '@/router/gates';
import Prices from '@/router/prices';
import Templates from '@/router/templates';

const Invitations = () => import('@/views/invitations/index.vue');
const Invite = () => import('@/views/invitations/Invite.vue');
const Reset = () => import('@/views/reset/index.vue');
const RequestReset = () => import('@/views/reset/RequestReset.vue');
const PasswordReset = () => import('@/views/reset/PasswordReset.vue');

const NotFound = () => import('@/components/NotFound.vue');

const config = {
  hashbang: false,
  mode: 'history',
  history: createWebHistory(),
  linkActiveClass: 'is-parent-active',
  linkExactActiveClass: 'is-active',

  scrollBehavior(to, from, savedPosition) {
    // savedPosition is only available for popstate navigations
    if (savedPosition) return savedPosition;
    // if the returned position is falsy, retain current scroll position
    // will retain current scroll position.
    if (to.meta.savePosition) return {};
    // scroll to anchor by returning the selector
    if (to.hash) {
      return { selector: to.hash };
    }
    // scroll to top by default
    return { top: 0, left: 0 };
  },

  routes: [
    Events,
    Organizations,
    Schedules,
    Auth,
    Scanning,
    Customers,
    Customer,
    Reports,
    Gates,
    Prices,
    Templates,
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: NotFound,
      meta: { auth: true, org: true },
    },
    {
      path: '',
      redirect: '/events/upcoming',
      meta: { layout: 'Empty' },
    },
    {
      path: '/reset',
      component: Reset,
      children: [
        {
          path: '',
          name: 'request-reset',
          component: RequestReset,
          meta: { layout: 'Empty' },
        },
        {
          path: ':uuid',
          name: 'password-reset',
          component: PasswordReset,
          meta: { layout: 'Empty' },
        },
      ],
    },
    {
      path: '/invitations',
      component: Invitations,
      children: [
        {
          path: '',
          redirect: 'no-invite',
          meta: {
            layout: 'Empty',
            auth: true,
          },
        },
        {
          path: ':uuid',
          component: Invite,
          name: 'invitations',
          meta: {
            layout: 'Empty',
            auth: true,
          },
        },
      ],
    },
  ],
};

const router = createRouter(config);

export default router;
