import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  organizations: [],
  current_organization: null,
  current_event_organization: null,
  already_seen_orgs: [],
  ticketeers: [],
};

export default (app) => ({
  namespaced: true,
  state,
  getters,
  actions: actions(app),
  mutations,
});
