import { createApp, h } from 'vue';
import VueAxios from 'vue-axios';
import VueClickAway from 'vue3-click-away';
import GETPRETTUI from '@getprotocollab/get-prettui';
import '@getprotocollab/get-prettui/styles.css';
// NEEDS TO BE AFTER GETPRETTUI STYLES
import '@/assets/styles/main.scss';

import * as Sentry from '@sentry/vue';

import momentPlugin from '@/plugins/vue-moment';

import App from '@/App.vue';
import router from '@/router';
import RouterManager from '@/plugins/router-manager';
import store from '@/store';

import { i18n, supportedLocales } from '@/lang';

// API has to be imported before auth
import api from '@/api';
import AuthPlugin from '@/plugins/auth';
import sharedStorage from '@/plugins/sharedStorage';
import { getQueryLocale, getPreferredSupportedLang, setLang } from '@/helpers/locale';

import { BrowserTracing } from '@sentry/browser';
import VueDragscroll from 'vue-dragscroll';
import { Chart } from 'chart.js';
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

import GTM from './plugins/GtmManager';

// // The plugin is added globally.
// // In order to avoid affecting other charts, we need to disable showing it by default
Chart.register(ChartJsPluginDataLabels);
const pluginDefaults = Chart.defaults.get('plugins');
pluginDefaults.datalabels.display = false;
Chart.defaults.set('plugins', { ...pluginDefaults });

const app = createApp({
  created() {
    // on initial load we set the locale in the following order:
    // localstorage -> querystring -> supportedBrowserLang (can be i18n.fallbackLocale)
    // this can later be overwritten when the user profile is loaded
    // all changes in the profile ar also set on localstorage so only the first load
    // on a new browser (for this user) might have the wrong locale
    const supportedBrowserLang = getPreferredSupportedLang(supportedLocales, this.$i18n.fallbackLocale);
    const queryLocale = getQueryLocale(window.location.href);
    const storageLocale = this.$storage.getItem('locale');
    const locale = setLang(this.$i18n, storageLocale || queryLocale || supportedBrowserLang);

    this.$storage.setItem('locale', locale);
  },
  globalProperties: {
    $http: api,
    $storage: sharedStorage,
    $auth: {},
    $routerManager: {},
    $moment: momentPlugin,
    $gtm: {},
    $i18n: i18n,
  },
  render: () => h(App),
});

app
  .use(i18n)
  .use(router)
  .use(store(app))
  .use(VueAxios, api as any)
  .use(GETPRETTUI)
  .use(momentPlugin)
  .use(VueClickAway)
  .use(VueDragscroll)
  .use(GTM, {
    router,
    store: app.config.globalProperties.$store,
    options: {
      debug: process.env.NODE_ENV === 'development',
    },
  })
  .use(sharedStorage, {
    storageFactories: [() => localStorage, () => sessionStorage],
  })
  .use(AuthPlugin, { router, storage: app.config.globalProperties.$storage, axios: api })
  .use(RouterManager, { router, store: app.config.globalProperties.$store, auth: app.config.globalProperties.$auth });

const APP_URL = process.env.VITE_APP_DASHBOARD_URL || 'http://localhost:8080';

if (process.env.VITE_APP_SENTRY_DSN) {
  try {
    Sentry.init({
      app,
      dsn: process.env.VITE_APP_SENTRY_DSN,
      environment: process.env.VITE_APP_ENV,
      release: process.env.VITE_APP_SENTRY_RELEASE,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      allowUrls: [APP_URL],
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Our own additions
        // thrown by google catpcha
        'Non-Error promise rejection captured with value: Timeout (h)',
        // thrown by microsoft outlook scan
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:4',
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      beforeSend(event, hint) {
        // Ignore ChunkLoadError
        if (event?.exception?.type === 'ChunkLoadError') return null;

        if (hint && hint.originalException instanceof Event) {
          event.extra.isTrusted = hint.originalException.isTrusted;
          event.extra.detail = hint.originalException.detail;
          event.extra.type = hint.originalException.type;
          event.extra.message = hint.originalException.message;
        }
        return event;
      },

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  } catch (e) {
    // failing to initialize sentry shouldn't kill the application
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

const vm = app.mount('#app');
export default app;
export { vm };
