const OrderDetail = () => import('@/views/events/event/eventCustomers/eventCustomer/orders/order/index.vue');
const TicketsTable = () => import('@/views/events/event/eventCustomers/eventCustomer/orders/order/Tickets.vue');
const PaymentsTable = () => import('@/views/events/event/eventCustomers/eventCustomer/orders/order/payments/index.vue');

const meta = {
  auth: true,
  org: true,
  permissions: {
    access: ({ state, getters }, _) => {
      const { current_event } = state.Events;
      return current_event && !current_event.is_template && getters['Events/getPermission']('attendee.read');
    },
    redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
  },
};

export default {
  path: 'orders/:orderId',
  name: 'event-customer-order',
  component: OrderDetail,
  redirect: { name: 'event-customer-order-tickets' },
  children: [
    {
      path: 'tickets',
      name: 'event-customer-order-tickets',
      component: TicketsTable,
      meta,
    },
    {
      path: 'payments',
      name: 'event-customer-order-payments',
      component: PaymentsTable,
      meta,
    },
  ],
};
