const Reports = () => import('@/views/reports//index.vue');
const Exports = () => import('@/views/reports/exports/index.vue');
const Export = () => import('@/components/exports/Export.vue');

export default {
  path: '/reports',
  name: 'reports-overview',
  component: Reports,
  redirect: '/reports/exports',
  children: [
    {
      path: 'exports',
      name: 'global-exports',
      component: Exports,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ getters }, _) =>
            getters['Organizations/getPermission']('reports.summary.export') ||
            getters['Organizations/getPermission']('reports.revenue.export') ||
            getters['Organizations/getPermission']('reports.attendees.export') ||
            getters['Organizations/getPermission']('reports.blocks.export') ||
            getters['Organizations/getPermission']('reports.groups.export') ||
            getters['Organizations/getPermission']('reports.orders.export'),
        },
      },
      children: [
        {
          path: 'summary',
          name: 'global-export-summary',
          component: Export,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('reports.summary.export'),
            },
          },
        },
        {
          path: 'revenue',
          name: 'global-export-revenue',
          component: Export,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('reports.revenue.export'),
            },
          },
        },
        {
          path: 'blocks',
          name: 'global-export-blocks',
          component: Export,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('reports.blocks.export'),
            },
          },
        },
        {
          path: 'groups',
          name: 'global-export-groups',
          component: Export,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('reports.groups.export'),
            },
          },
        },
        {
          path: 'attendees',
          name: 'global-export-attendees',
          component: Export,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('reports.attendees.export'),
            },
          },
        },
        {
          path: 'orders',
          name: 'global-export-orders',
          component: Export,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('reports.orders.export'),
            },
          },
        },
      ],
    },
  ],
};
