const BlockCategories = () => import('@/views/events/event/eventPools/blocks/index.vue');
const CategoriesOverview = () => import('@/views/events/event/eventPools/blocks/CategoriesOverview.vue');
const BlockCategory = () => import('@/views/events/event/eventPools/blocks/category/index.vue');
const EventPools = () => import('@/views/events/event/eventPools/index.vue');
const PoolDetail = () => import('@/views/events/event/eventPools/PoolDetail.vue');
const PoolOverview = () => import('@/views/events/event/eventPools/PoolOverview.vue');

export default {
  path: 'pools',
  component: EventPools,
  meta: { auth: true, org: true },
  children: [
    {
      path: '',
      name: 'pools',
      component: PoolOverview,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ getters }, _) => getters['Events/getPermission']('ticket.privilegetype.read'),
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'blocks',
      component: BlockCategories,
      children: [
        {
          path: '',
          name: 'blocks',
          component: CategoriesOverview,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Events/getPermission']('ticket.blockcategory.read'),
              redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
            },
          },
        },
        {
          path: ':id',
          name: 'category-details',
          component: BlockCategory,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Events/getPermission']('ticket.blockcategory.read'),
              redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
            },
          },
        },
      ],
    },
    {
      path: ':id',
      name: 'pool-detail',
      component: PoolDetail,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ getters }, _) => getters['Events/getPermission']('ticket.privilegetype.read'),
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
  ],
};
