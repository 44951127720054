import actions from './actions';
import mutations from './mutations';

const state = {
  draft_tickets: [],
  current_tkinds: [],
  global_prices: [],
};

export default (app) => ({
  namespaced: true,
  state,
  mutations,
  actions: actions(app),
});
