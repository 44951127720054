import actions from './actions';
import mutations from './mutations';

const state = {
  self: null,
  settings: {},
};

export default (app) => ({
  namespaced: true,
  state,
  actions: actions(app),
  mutations,
});
