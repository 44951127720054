import axios, { AxiosResponse } from 'axios';

export const stableVersion = '2.3';

export const getApiBaseUrl = function (version = stableVersion) {
  return `${process.env.VITE_APP_ENTRAILS_API_URL}v${version}/`;
};

const api = axios.create({
  baseURL: getApiBaseUrl(),
});

api.interceptors.response.use(
  (response) => {
    if (response?.data.status === 'error') {
      const error = new Error() as { response: AxiosResponse } & Error;
      error.response = response;

      return Promise.reject(error);
    }

    return response;
  },
  (error) => Promise.reject(error),
);

export default api;
