import { parseRequestQueryParams } from '@getprotocollab/get-pal';
import { pageLimit } from '@/helpers/constants';

function actions(app) {
  return {
    async getOrders(
      _,
      {
        owner_id,
        event_slug,
        page = 1,
        ordering,
        state = [],
        search,
        phonenumber,
        time,
        end,
        start,
        market,
        type,
        name,
        shop,
        pool,
      },
    ) {
      const offset = pageLimit * (page - 1);
      const query = parseRequestQueryParams({
        owner_id,
        event_slug,
        offset,
        limit: pageLimit,
        name,
        phonenumber,
        ordering,
        search,
        market,
        time,
        end,
        start,
        type,
        privilege_type_id: pool,
      });

      if (typeof state === 'string') {
        state = state.split(',');
      }

      if (typeof shop === 'string') {
        shop = shop.split(',');
      }

      const stateParams = state ? `&state=${state.join(',')}` : '';
      const shopParams = shop ? `&shop_id=${shop.join(',')}` : '';

      const { data: orders = {} } = await app.config.globalProperties.axios.get(
        `orders/${query}${stateParams}${shopParams}`,
      );
      return orders;
    },

    async getOrderDetails(_, { orderId }) {
      const { data: order = {} } = await app.config.globalProperties.axios.get(`orders/${orderId}/`);
      return order;
    },
  };
}

export default actions;
