import Auth from '@/views/auth/index.vue';
import Login from '@/views/auth/Login.vue';
import SignUp from '@/views/auth/SignUp.vue';

export default {
  path: '/login',
  component: Auth,
  children: [
    {
      path: '',
      name: 'login',
      component: Login,
      meta: {
        layout: 'Empty',
      },
    },
    {
      path: 'signup',
      name: 'signup',
      component: SignUp,
      meta: {
        layout: 'Empty',
      },
    },
  ],
};
