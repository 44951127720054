export default {
  store_ticket_sales(state, data) {
    state.current_tickets_sales = data;
  },
  store_event_sales(state, data) {
    state.current_event_sales = data;
  },
  store_event_checkins(state, data) {
    state.current_event_checkins = data;
  },
  store_event_privtypes(state, data) {
    state.current_event_ptypes = data;
  },
  set_current_event(state, event) {
    state.current_event = event;
  },
  clear_current_event(state) {
    state.current_event = null;
    state.current_event_ptypes = [];
    state.current_event_tiers = [];
    state.current_event_sales = {};
    state.current_tickets_sales = {};
    state.current_event = null;
  },
  set_edit_event(state, event) {
    state.edit_event = event;
  },
  set_publish_state_event(state, status) {
    state.current_event.publish_state = status;
  },
  store_event_tiers(state, tiers) {
    state.current_event_tiers = tiers.map((t) => ({ ...t }));
  },
};
