export default {
  set_current_shop(state, shop) {
    state.current_shop = shop;
  },
  set_draft_shop(state, draftShop) {
    state.draft_shop = draftShop;
  },
  set_event_shops(state, shops) {
    state.shops = shops;
  },
  clear_current_shop(state) {
    state.current_shop = {};
  },
  clear_draft_shop(state) {
    state.draft_shop = {};
  },
  clear_shops(state) {
    state.shops = [];
  },
};
