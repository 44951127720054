const Prices = () => import('@/views/prices/index.vue');
const PriceOverview = () => import('@/views/prices/PricesOverview.vue');

export default {
  path: '/prices',
  component: Prices,
  children: [
    {
      path: '',
      name: 'prices-overview',
      component: PriceOverview,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ getters }, _) => getters['Organizations/getPermission']('ticket.price.read'),
        },
      },
    },
  ],
};
