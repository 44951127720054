import { parseRequestQueryParams } from '@getprotocollab/get-pal';
import helpers from '@/helpers';
import { pageLimit, PRIVILEGE_TYPES } from '@/helpers/constants';

function actions(app) {
  return {
    async getCashierTicketKinds(
      _,
      {
        eventSlug,
        limit,
        page,
        ordering,
        shop,
        pool,
        block_category,
        total_available_e,
        total_available_lte,
        total_available_gte,
        total_available_lt,
        total_available_gt,
        type,
        available_pool_e,
        available_pool_lte,
        available_pool_gte,
        available_pool_lt,
        available_pool_gt,
        price_object_id,
      },
    ) {
      const offset = limit * (page - 1);
      const shopParams = shop ? `&shop_id=${shop}` : '';
      const privilegeTypeParams = pool ? `&privilege_type_id=${pool}` : '';
      const priceObjectIds = price_object_id ? `&price_object_id=${price_object_id}` : '';
      let upsell;
      if (type) {
        upsell = type === PRIVILEGE_TYPES.UPSELL;
      }
      const query = parseRequestQueryParams({
        event_slug: eventSlug,
        upsell,
        offset,
        limit,
        ordering,
        block_category,
        total_available_e,
        total_available_lte,
        total_available_gte,
        total_available_lt,
        total_available_gt,
        available_pool_e,
        available_pool_lte,
        available_pool_gte,
        available_pool_lt,
        available_pool_gt,
      });
      const ticketKinds = await app.config.globalProperties.axios.get(
        `cashier/ticket-kinds/${query}${shopParams}${privilegeTypeParams}${priceObjectIds}`,
      );
      return ticketKinds.data;
    },

    async getTicketKinds(_, { eventId, eventSlug, pool, shop } = {}) {
      const query = parseRequestQueryParams({
        event_id: eventId,
        privilege_type_id: pool,
        shop_id: shop,
        event_slug: eventSlug,
      });
      const ticketKinds = await helpers.getAllPages(app.config.globalProperties.axios, `ticket-kinds/${query}`);
      return ticketKinds;
    },

    async getTicketKindCounters(_, { ticketKindId } = {}) {
      const ticketKinds = await app.config.globalProperties.axios.get(
        `cashier/ticket-kinds/${ticketKindId}/availabilities`,
      );
      return ticketKinds.data;
    },

    async addTicket(_, ticket) {
      const response = await app.config.globalProperties.axios.post('ticket-kinds/create/', {
        ...ticket,
      });
      return response.data.ticketkind;
    },

    async updateNFTImage(_, { id, nft_image }) {
      const response = await app.config.globalProperties.axios.post(`ticket-kinds/${id}/request_upload/`, {
        filename: nft_image.name,
        field: 'nft_image',
      });

      const s3Object = new FormData();

      Object.keys(response.data.fields).forEach((k) => {
        s3Object.append(k, response.data.fields[k]);
      });
      s3Object.append('Content-Type', nft_image.type);
      s3Object.append('file', nft_image);

      await app.config.globalProperties.axios.post(response.data.url, s3Object, {
        headers: { Authorization: '' },
      });

      return response;
    },

    async updateRegularTicket(_, ticket) {
      const ticketResponse = await app.config.globalProperties.axios.put(`ticket-kinds/${ticket.id}/`, ticket);
      return ticketResponse.data;
    },

    async removeTicket(_, id) {
      await app.config.globalProperties.axios.delete(`ticket-kinds/${id}/`);
    },

    getTicketGroups(state, { attendee, eventSlug } = {}) {
      const query = parseRequestQueryParams({
        attendee,
        event_slug: eventSlug,
      });
      return app.config.globalProperties.axios.get(`ticketgroups/${query}`);
    },

    async getPrices(
      state,
      {
        global = true,
        name = undefined,
        organization = undefined,
        search = undefined,
        page = 1,
        ordering = undefined,
        eventSlug = undefined,
      } = {},
    ) {
      const offset = (page - 1) * pageLimit;
      const query = parseRequestQueryParams({
        name,
        is_global: global,
        event_slug: eventSlug,
        organization,
        search,
        limit: pageLimit,
        offset,
        ordering,
      });
      const { data } = await app.config.globalProperties.axios.get(`prices/${query}`);
      state.commit('store_global_prices', data.results);
      return data;
    },

    async getPrice(_, id) {
      const { data: price } = await app.config.globalProperties.axios.get(`prices/${id}/`);
      return price;
    },

    async createPrice(_, data) {
      const { data: price } = await app.config.globalProperties.axios.post('prices/', data);
      return price;
    },

    async updatePrice(_, data) {
      const { data: price } = await app.config.globalProperties.axios.patch(`prices/${data.id}/`, data);
      return price;
    },
  };
}

export default actions;
