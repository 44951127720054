const Scanning = () => import('@/views/scanning/index.vue');

export default {
  path: '/scanning',
  name: 'organization-scanning',
  component: Scanning,
  meta: {
    auth: true,
    org: true,
    permissions: {
      access: ({ getters }) => getters['Organizations/getPermission']('organization.organization.scan'),
    },
  },
};
