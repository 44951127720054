import { createI18n } from 'vue-i18n';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/de';
import 'moment/dist/locale/it';
import 'moment/dist/locale/ko';
import 'moment/dist/locale/es';
import en from './locales/en/main.json';
import nl from './locales/nl/main.json';
import de from './locales/de/main.json';
import it from './locales/it/main.json';
import ko from './locales/ko/main.json';
import es from './locales/es/main.json';

const locale = 'en';
const fallbackLocale = 'en';
const messages = {
  en,
  nl,
  de,
  it,
  es,
  ko,
};

const i18n = createI18n({
  locale,
  fallbackLocale,
  messages,
  allowMixedLocale: true,
  warnHtmlInMessage: 'off',
});

// Order of locales defines preference
const supportedLocales = ['en', 'nl', 'de', 'it', 'es', 'ko'];

export { i18n, supportedLocales };
