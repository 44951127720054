import EventReports from '@/router/events/event/reports';
import EventCustomers from '@/router/events/event/customers';
import Seating from '@/router/events/event/seating';
import Manage from '@/router/events/event/manage';
import Pools from '@/router/events/event/pools';
import Cashier from '@/router/events/event/cashier';
import EventOrderDetails from '@/router/events/event/orderdetails';
import Reservations from '@/router/events/event/reservations';

const Sync = () => import('@/views/templates/template/Sync.vue');
const ShopOverview = () => import('@/views/events/event/shops/ShopOverview.vue');
const Shops = () => import('@/views/events/event/shops/index.vue');
const Event = () => import('@/views/events/event/index.vue');
const ScanningProfile = () => import('@/views/events/event/eventScanning/index.vue');
const Checkin = () => import('@/views/events/event/eventCheckin.vue');
const OrdersOverview = () => import('@/views/events/event/eventOrders/OrdersOverview.vue');
const OrdersWrapper = () => import('@/views/events/event/eventOrders/index.vue');
const PaymentDetail = () => import('@/views/events/event/eventCustomers/eventCustomer/orders/order/Payment.vue');
export default {
  path: ':slug',
  component: Event,
  redirect: { name: 'event-reports' },
  children: [
    EventReports,
    EventCustomers,
    Seating,
    Manage,
    Pools,
    Cashier,
    {
      path: 'scanning',
      name: 'event-scanning',
      component: ScanningProfile,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ getters }, _) => getters['Events/getPermission']('event.event.scan'),
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'checkin',
      name: 'event-checkin',
      component: Checkin,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ getters }, _) => getters['Events/getPermission']('event.event.dashboard_scan'),
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'sync',
      name: 'sync',
      component: Sync,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            return current_event && current_event.is_template && getters['Events/getPermission']('event.write');
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'shops',
      name: 'shops',
      component: Shops,
      meta: { auth: true, org: true },
      children: [
        {
          path: ':id',
          name: 'shop',
          component: ShopOverview,
          meta: { auth: true, org: true },
        },
      ],
    },
    {
      path: 'orders',
      component: OrdersWrapper,
      children: [
        {
          path: '',
          name: 'event-orders-overview',
          component: OrdersOverview,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ state, getters }, _) => {
                const { current_event } = state.Events;
                return current_event && !current_event.is_template && getters['Events/getPermission']('attendee.read');
              },
              redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
            },
          },
        },
        EventOrderDetails,
        {
          path: ':orderId/payments/:paymentId',
          name: 'event-order-payment',
          component: PaymentDetail,
          meta: {
            auth: true,
            org: true,
          },
        },
      ],
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            return (
              current_event &&
              !current_event.is_template &&
              getters['Events/getPermission']('attendee.read') &&
              getters['Events/getPermission']('order.read')
            );
          },
          redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    Reservations,
  ],
};
