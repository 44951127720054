import { createStore } from 'vuex';
import Seating from './modules/seating';
import Events from './modules/events';
import Organizations from './modules/organizations';
import Attendees from './modules/attendees';
import Tickets from './modules/tickets';
import Shops from './modules/shops';
import Gates from './modules/gates';
import Account from './modules/account';
import Notifications from './modules/notifications';
import Orders from './modules/orders';
import Payments from './modules/payments';
import Refunds from './modules/refunds';
import Cashier from './modules/cashier';

export default (app) =>
  createStore({
    state() {
      return {
        loading: false,
        isVisible: true,
      };
    },
    modules: {
      Seating: Seating(app),
      Events: Events(app),
      Organizations: Organizations(app),
      Attendees: Attendees(app),
      Tickets: Tickets(app),
      Shops: Shops(app),
      Gates: Gates(app),
      Account: Account(app),
      Notifications,
      Orders: Orders(app),
      Payments: Payments(app),
      Refunds: Refunds(app),
      Cashier: Cashier(app),
    },
    mutations: {
      set_loading(state, isLoading) {
        state.loading = isLoading;
      },
      set_visibility(state, isVisible) {
        state.isVisible = isVisible;
      },
    },
    getters: {
      hasFeatureFlagPermission: (state, getters, rootState, rootGetters) =>
        !!rootGetters['Events/getPermission']('feature.flag') ||
        !!rootGetters['Organizations/getPermission']('feature.flag') ||
        !!rootGetters['Organizations/getTicketeersPermission']('feature.flag'),
    },
    actions: {
      clearContextData({ commit }) {
        commit('Organizations/clear_current_event_organization', { root: true });
        commit('Events/clear_current_event', { root: true });
        commit('Tickets/clear_current_ticketkinds', { root: true });
        commit('Shops/clear_current_shop', { root: true });
        commit('Shops/clear_shops', { root: true });
      },
    },
  });
