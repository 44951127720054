function mutations(app) {
  return {
    setCashierCart(state, cart) {
      state.cart = cart;
      state.cart.isExpired = false;
    },
    updateCart(state, cart) {
      state.cart = { ...state.cart, ...cart };
    },
    clearCart(state) {
      state.cart = {};
      app.config.globalProperties.$storage?.removeItem('cashier_cart_uuid');
    },
    setIssueInfo(state, data) {
      state.issueInfo = { ...state.issueInfo, ...data };
    },
    setReservationInfo(state, data) {
      state.reservationInfo = { ...state.reservationInfo, ...data };
    },
    setCashierOrder(state, data) {
      state.order = data;
    },
    clearCashierOrder(state) {
      state.order = null;
      state.issueInfo = {
        issueDigitally: true,
        paymentMethod: '',
        phone: '',
        email: '',
        firstName: '',
        lastName: '',
        sendSms: true,
        sendEmail: true,
        hasErrors: false,
        existingCustomer: false,
      };
      state.reservationInfo = {
        ownerFirstName: '',
        ownerLastName: '',
        ownerPhone: '',
        ownerEmail: '',
        reservationExpired: undefined,
        reservationName: '',
        reservationNote: '',
        hasErrors: false,
      };
    },
    setCartExpired(state) {
      state.cart = {};
      state.cart.isExpired = true;
      app.config.globalProperties.$storage.removeItem('cashier_cart_uuid');
    },
  };
}

export default mutations;
