import Customer from '@/router/events/event/customers/customer';

const EventCustomers = () => import('@/views/events/event/eventCustomers/index.vue');
const CustomersOverview = () => import('@/components/customers/CustomersOverview.vue');

export default {
  path: 'customers',
  component: EventCustomers,
  children: [
    {
      path: '',
      name: 'event-customers',
      component: CustomersOverview,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            return current_event && !current_event.is_template && getters['Events/getPermission']('attendee.read');
          },
          redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    Customer,
  ],
};
