import OrderDetail from '@/router/events/event/customers/customer/order';
import PaymentDetail from '@/router/events/event/customers/customer/payment';
import CustomerDetail from '@/router/events/event/customers/customer/details';

const CustomerProfile = () => import('@/views/events/event/eventCustomers/eventCustomer/index.vue');

export default {
  path: ':id(\\d+)',
  component: CustomerProfile,
  children: [CustomerDetail, OrderDetail, PaymentDetail],
};
