const CustomerDetails = () => import('@/views/events/event/eventCustomers/eventCustomer/CustomerDetails.vue');
const TicketsTable = () => import('@/views/events/event/eventCustomers/eventCustomer/Tickets.vue');
const OrdersTable = () => import('@/views/events/event/eventCustomers/eventCustomer/orders/index.vue');

const meta = {
  auth: true,
  org: true,
  permissions: {
    access: ({ state, getters }, _) => {
      const { current_event } = state.Events;
      return current_event && !current_event.is_template && getters['Events/getPermission']('attendee.read');
    },
    redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
  },
};

export default {
  path: '',
  name: 'event-customer',
  component: CustomerDetails,
  redirect: { name: 'event-customer-tickets' },
  children: [
    {
      path: 'tickets',
      name: 'event-customer-tickets',
      component: TicketsTable,
      meta,
    },
    {
      path: 'orders',
      name: 'event-customer-orders',
      component: OrdersTable,
      meta,
    },
  ],
};
