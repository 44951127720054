export default {
  update_blocks_count(state, data) {
    state.current_blocks_count = data;
  },
  set_current_attendee(state, attendee) {
    state.current_attendee = attendee;
  },
  clear_current_attendee(state) {
    state.current_attendee = null;
  },
  set_blocked_privileges(state, privileges) {
    state.blocked_privileges = privileges;
  },
  set_block_categories(state, categories) {
    state.blocked_categories = categories;
  },
  clear_blocked_privileges(state) {
    state.blocked_privileges = [];
  },
  clear_block_categories(state) {
    state.blocked_categories = [];
  },
  set_loading(state, loading) {
    state.attendees_loading = loading;
  },
  update_blocked_privilege(state, privilege) {
    const storedPriv = state.blocked_privileges.find((priv) => priv.id === privilege.id);
    Object.assign(storedPriv, privilege);
  },
};
