import { Router, RouteLocationNormalized } from 'vue-router';

export type GtmOptions = {
  whitelist: string[];
  blacklist: string[];
  policies?: Record<string, (...args: any[]) => any>;
};

type GtmPluginConfig = {
  options?: { debug?: boolean };
  store?: any;
  router?: Router;
  ignoredViews?: string[];
};

function loadGtmScript(gtmId: string, options: GtmOptions, dataLayerName: string) {
  if (!gtmId) {
    throw new Error('Cannot load GTM plugin: ID not set.');
  }

  window[dataLayerName] = [];

  // Inject policies in dataLayer
  Object.entries(options?.policies || {}).forEach(([policy, handler]) =>
    window[dataLayerName]!.push(['policy', policy, handler]),
  );

  // Add additional whitelist/blacklist
  if (options?.whitelist) {
    window[dataLayerName].push({
      'gtm.whitelist': options.whitelist,
    });
  }

  if (options?.blacklist) {
    window[dataLayerName].push({
      'gtm.blacklist': options.blacklist,
    });
  }

  // Add additional info in dataLayer
  window[dataLayerName].push({
    environment: process.env.NODE_ENV,
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
  });

  // Inject GTM script with dayaLayerName
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}&l=${dataLayerName}`;
  document.body.appendChild(script);
}

export class GtmManager {
  debug: boolean;

  gtmList: Set<string>;

  dataLayerList: Set<string>;

  constructor({ debug = false as boolean }) {
    this.debug = debug;
    this.gtmList = new Set();
    this.dataLayerList = new Set();
  }

  log(...args) {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log('GTM:', ...args);
    }
  }

  enable(gtmId: string, options: GtmOptions, dataLayerName = 'dataLayer') {
    if (this.gtmList.has(gtmId)) {
      this.log(`${gtmId}: Already enabled`);
      return;
    }

    try {
      loadGtmScript(gtmId, options, dataLayerName);
    } catch (e: any) {
      this.log(e.message);
      return;
    }

    this.gtmList.add(gtmId);
    this.dataLayerList.add(dataLayerName);

    this.log(`${gtmId} enabled. dataLayer: ${dataLayerName}`);
  }

  track(data) {
    if (!this.gtmList.size) {
      this.log('Disabled. Tried to push:', data);
      return;
    }

    this.dataLayerList.forEach((dataLayer) => {
      this.log(`Sending to ${dataLayer}`, data);
      if (window[dataLayer]) window[dataLayer].push(data);
    });
  }
}

/**
 * GTM Router guard.
 */
export const gtmRouterGuard = (gtm: GtmManager, config: GtmPluginConfig, to: RouteLocationNormalized) => {
  const { ignoredViews } = config;
  /* The above code is checking to see if the view name is null or if the view name is in the
    ignoredViews array. If either of these conditions are true, then the code will return and not run
    the rest of the code. */
  if (!to.name || (ignoredViews && ignoredViews.indexOf(to.name as string) !== -1)) {
    return;
  }

  const event: Record<string, any> = {
    event: 'PageView',
    pagePath: to.fullPath,
    pageRoute: to.name,
  };

  gtm.track(event);
};

export default {
  install: (app, config: GtmPluginConfig) => {
    const gtm = new GtmManager(config.options || {});

    app.config.globalProperties.$gtm = gtm;

    // Init GTMs without blacklist
    const options = {
      whitelist: ['google', 'sandboxedScripts', 'customScripts'],
      blacklist: [],
    } as GtmOptions;

    // Init Whitelabel GTM
    if (process.env.VITE_APP_GTM_ID) {
      gtm.enable(process.env.VITE_APP_GTM_ID, options);
    }

    // Init Whitelabel GTM
    if (process.env.VITE_APP_WL_GTM_ID) {
      gtm.enable(process.env.VITE_APP_WL_GTM_ID, options);
    }

    gtm.track({ event: 'Init', referrer: document.referrer, href: document.location.href });

    if (config.router) {
      config.router.afterEach((to) => gtmRouterGuard(gtm, config, to));
    }
  },
};
