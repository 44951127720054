export interface Storage {
  setItem(key: string, value: string): void;
  getItem(key: string): string | null;
  removeItem(key: string): void;
}

const getStorage = (sf: () => Storage): Storage | null => {
  try {
    return sf();
  } catch (e) {
    return null;
  }
};

const checkStorage = (s: Storage): boolean => {
  try {
    const key = 'test';
    s.setItem(key, key);
    if (s.getItem(key) !== key) {
      return false;
    }
    s.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export class SharedStorage {
  private prefix: string;

  private storages: Storage[];

  constructor(prefix: string, storageFactories: Array<() => Storage>) {
    this.prefix = prefix || '';
    this.storages = storageFactories
      .map(getStorage)
      .filter((s): s is Storage => !!s)
      .filter(checkStorage);
  }

  private toInternalKey(k: string): string {
    return `${this.prefix}${k}`;
  }

  private removePrefix(k: string): string {
    return k.substring(this.prefix.length);
  }

  setItem(k: string, v: unknown): void {
    const value = JSON.stringify(v);
    const key = this.toInternalKey(k);
    this.storages.forEach((s) => s.setItem(key, value));
  }

  getItem(k: string, defaultValue: unknown = null): unknown {
    const key = this.toInternalKey(k);
    for (let i = 0; i < this.storages.length; i += 1) {
      const v = this.storages[i].getItem(key);
      if (v) {
        return JSON.parse(v);
      }
    }
    return defaultValue;
  }

  removeItem(k: string): void {
    const key = this.toInternalKey(k);
    this.storages.forEach((s) => s.removeItem(key));
  }

  keys(): Set<string> {
    return this.storages.reduce((acc, s) => {
      Object.keys(s as any)
        .filter((k) => k.startsWith(this.prefix))
        .forEach((k) => acc.add(this.removePrefix(k)));
      return acc;
    }, new Set<string>());
  }

  sync(): void {
    this.keys().forEach((k) => this.setItem(k, this.getItem(k)));
  }
}

export default {
  install: (
    app: any,
    {
      storageFactories,
      prefix = 'getprotocol-',
    }: {
      storageFactories: Array<() => Storage>;
      prefix?: string;
    },
  ): void => {
    const sharedStorage = new SharedStorage(prefix, storageFactories);

    app.config.globalProperties.$storage = sharedStorage;
    app.provide('storage', sharedStorage);

    sharedStorage.sync();
  },
};
