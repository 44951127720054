<template>
  <section class="section view view-auth">
    <div class="logo">
      <img :src="`${BASE_PATH}images/logo-name.svg`" :alt="`${BRAND}`" />
    </div>
    <router-view />
  </section>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    data() {
      return {
        BASE_PATH: process.env.VITE_APP_BASE_PATH || '/',
        BRAND: process.env.VITE_APP_BRAND,
      };
    },
  });
</script>

<style lang="scss" scoped>
  .view-auth {
    width: 100%;
    min-height: 100vh;
    background: rgb(20, 37, 52);
    background: radial-gradient(circle, rgba(20, 37, 52, 1) 0%, rgba(9, 25, 34, 1) 70%, rgba(9, 21, 26, 1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .logo {
    flex-shrink: 0;
    padding: 1rem;
    display: block;
    margin-bottom: 2rem;

    img {
      width: 100%;
      height: auto;
    }

    @media (min-height: 38rem) {
      margin-top: -100px;
    }
  }
</style>
