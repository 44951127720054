const EventSeating = () => import('@/views/events/event/eventSeating/index.vue');
const OverviewFooter = () => import('@/views/events/event/eventSeating/footer/index.vue');
const BlockFooter = () => import('@/views/events/event/eventSeating/footer/BlockFooter.vue');

export default {
  path: 'seating',
  component: EventSeating,
  children: [
    {
      path: '',
      name: 'seating-overview',
      component: OverviewFooter,
      meta: {
        layout: 'Full',
        auth: true,
        org: true,
        permissions: {
          access: ({ getters, state }, _) => {
            const { current_event } = state.Events;
            return getters['Events/getPermission']('seating.read') && current_event?.seating;
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'block',
      name: 'block',
      component: BlockFooter,
      meta: {
        layout: 'Full',
        auth: true,
        org: true,
        permissions: {
          access: ({ getters }, _) =>
            getters['Events/getPermission']('ticket.blockcategory.read') &&
            getters['Events/getPermission']('ticket.blockcategory.write') &&
            getters['Events/getPermission']('seating.batch.write'),
          redirect: (_, to) => ({ name: 'seating-overview', params: { ...to.params } }),
        },
      },
    },
  ],
};
