const Organizations = () => import('@/views/organizations/index.vue');
const OrganizationsOverview = () => import('@/views/organizations/OrganizationsOverview.vue');
const OrganizationDetail = () => import('@/views/organizations/organization/OrganizationDetail.vue');
const UsersAndRoles = () => import('@/views/organizations/organization/users/index.vue');
const PaymentInformation = () => import('@/views/organizations/organization/PaymentInformation.vue');
const Organization = () => import('@/views/organizations/organization/index.vue');
const AttendeeInformation = () => import('@/views/organizations/organization/AttendeeInformation.vue');
const Miscellaneous = () => import('@/views/organizations/organization/Miscellaneous.vue');
const ResaleFees = () => import('@/views/organizations/organization/ResaleFees.vue');

export default {
  path: '/organizations',
  component: Organizations,
  children: [
    {
      path: '',
      name: 'organizations-overview',
      component: OrganizationsOverview,
      meta: {
        auth: true,
        org: true,
      },
    },
    {
      path: ':id',
      component: Organization,
      redirect: ':id/details',
      children: [
        {
          path: 'details',
          name: 'organization-details',
          component: OrganizationDetail,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('organization.organization.write'),
            },
          },
        },
        {
          path: 'payment-information',
          name: 'payment-information',
          component: PaymentInformation,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('organization.organization.write'),
            },
          },
        },
        {
          path: 'users-and-roles',
          name: 'users-and-roles',
          component: UsersAndRoles,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('organization.organization.write'),
            },
          },
        },
        {
          path: 'attendee-information',
          name: 'attendee-information',
          component: AttendeeInformation,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) => getters['Organizations/getPermission']('organization.organization.write'),
            },
          },
        },
        {
          path: 'miscellaneous',
          name: 'miscellaneous',
          component: Miscellaneous,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ getters }, _) =>
                getters['Organizations/getPermission']('organization.organization.credits_refund'),
            },
          },
        },
        {
          path: 'resale',
          name: 'resale-fees',
          component: ResaleFees,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: ({ state, getters }) =>
                getters['Organizations/getTicketeerPermission'](
                  state.Organizations.current_organization.ticketeer,
                  'feature.flag',
                ),
            },
          },
        },
      ],
    },
  ],
};
