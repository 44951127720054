const EventsTable = () => import('@/components/EventsTable.vue');
const EventsOverview = () => import('@/views/events/overview/index.vue');

export default {
  path: '',
  name: 'events-overview',
  component: EventsOverview,
  redirect: '/events/upcoming',
  children: [
    {
      path: 'upcoming',
      name: 'upcoming',
      component: EventsTable,
      meta: {
        auth: true,
        org: true,
        savePosition: true,
      },
    },
    {
      path: 'past',
      name: 'past',
      component: EventsTable,
      meta: { auth: true, org: true, savePosition: true },
    },
    {
      path: 'draft',
      name: 'draft',
      component: EventsTable,
      meta: { auth: true, org: true, savePosition: true },
    },
  ],
};
