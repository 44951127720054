import helpers from '@/helpers';

export default {
  getPermission: (state) => (permission) => {
    if (!state.current_event) return null;
    return state.current_event.permissions.find((perm) => perm === permission);
  },
  ptIdsToColorIndexMap: (state, getters) => {
    const ids = state.current_event_ptypes.filter((p) => !p.tier).map((p) => p.id);
    const result = helpers.idsToColorindexMap(ids, state.current_event_tiers.length);
    state.current_event_ptypes
      .filter((p) => p.tier)
      .forEach((p) => {
        result[p.id] = getters.tiersToColorIndexMap[p.tier.name];
      });
    return result;
  },
  tiersToColorIndexMap: (state) => {
    const tierIds = state.current_event_tiers.map((tier) => tier.name);
    return helpers.idsToColorindexMap(tierIds);
  },
  seatedPTypesObject: (state) =>
    state.current_event_ptypes.reduce((acc, pt) => {
      if (!pt.is_seated) return acc;
      acc[pt.id] = pt;
      return acc;
    }, {}),
  seatedPTypesArray: (state) => state.current_event_ptypes.filter((pt) => pt.is_seated),
  tiersByPType: (state) =>
    state.current_event_tiers.reduce((tiersByPType, { privilege_types, name, id }) => {
      privilege_types.forEach((pType) => {
        tiersByPType[pType] = { name, id };
      });
      return tiersByPType;
    }, {}),
  getCurrency: (state) => {
    if (!state.current_event || !state.current_event.currency) return { code: 'EUR', decimals: 2 };
    return state.current_event.currency;
  },
};
