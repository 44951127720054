export default {
  store_organizations(state, data) {
    state.organizations = data;
  },
  set_ticketeers(state, ticketeers) {
    state.ticketeers = ticketeers;
  },
  set_draft_organization(state, orga) {
    state.draft_organization = orga;
  },
  clear_draft_organization(state) {
    state.draft_organization = null;
  },
  set_current_organization(state, orga) {
    state.current_organization = orga;
  },
  set_current_event_organization(state, orga) {
    state.current_event_organization = orga;
  },
  clear_current_event_organization(state) {
    state.current_event_organization = null;
  },
  set_already_seen_orgs(state, orgs) {
    state.already_seen_orgs = orgs;
  },
};
