import Exports from '@/router/events/event/reports/Exports';

const EventSales = () => import('@/views/events/event/reports/sales/index.vue');
const EventExecution = () => import('@/views/events/event/reports/execution/index.vue');
const EventDemographics = () => import('@/views/events/event/reports/demographics/index.vue');
const EventReports = () => import('@/views/events/event/reports/index.vue');

export default {
  path: 'reports',
  name: 'event-reports',
  redirect: {
    name: 'sales',
  },
  component: EventReports,
  children: [
    Exports,
    {
      path: 'sales',
      name: 'sales',
      component: EventSales,
      meta: {
        auth: true,
        org: true,
        savePosition: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            const hasPermission =
              getters['Events/getPermission']('reports.ticketsales.read') ||
              getters['Events/getPermission']('reports.eventsales.read');
            return current_event && !current_event.is_template && hasPermission;
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'execution',
      name: 'execution',
      component: EventExecution,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            const hasPermission = getters['Events/getPermission']('reports.checkins.read');
            return current_event && !current_event.is_template && hasPermission;
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'demographics',
      name: 'demographics',
      component: EventDemographics,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            const hasPermission = getters['Events/getPermission']('reports.demographics.read');
            return current_event && !current_event.is_template && hasPermission;
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
  ],
};
