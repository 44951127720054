import { setLang } from '@/helpers/locale';
import { i18n } from '@/lang';
import { AUTH_APP } from '@/helpers/constants';

function actions(app) {
  return {
    async getSelf({ commit, dispatch }) {
      const { data } = await app.config.globalProperties.axios.get('users/self/');
      commit('store_self', { ...data });
      await dispatch('getSettings');
      const locale = app.config.globalProperties.$storage.getItem('locale');
      if (!locale || data.locale !== locale) {
        await dispatch('setLocale', data.locale);
      }
    },
    async setLocale({ commit, state }, lang) {
      const { self } = state;
      const locale = setLang(i18n.global, lang);
      app.config.globalProperties.$storage.setItem('locale', locale);

      if (self && self.id && self?.locale !== locale) {
        const response = await app.config.globalProperties.axios.put(`/users/${self.id}/`, { locale });
        commit('store_self', { ...response.data });
      }
    },
    async getSettings({ commit, state }) {
      const userId = state.self.id;
      if (!userId) return;
      const localSettings = app.config.globalProperties.$storage.getItem(`user-settings-${userId}`);
      let response;
      let settings = {};
      if (localSettings) {
        settings = localSettings;
      }
      try {
        response = await app.config.globalProperties.axios.get('users/dashboard-preferences/');
        settings = { ...settings, ...response?.data };
      } catch (e) {
        if (e.response.status !== 404) {
          throw e;
        }
        if (e.response.status === 404) return;
      } finally {
        commit('set_user_settings', settings);
      }
    },
    async createSettings({ commit }, settings) {
      const { data } = await app.config.globalProperties.axios.post('users/dashboard-preferences/', settings);
      commit('set_user_settings', data);
    },
    async saveSettings({ commit, state, dispatch }, payload) {
      const userId = state.self.id;
      if (!userId) return;
      let response;
      try {
        response = await app.config.globalProperties.axios.patch('users/dashboard-preferences/', payload);
        commit('set_user_settings', { ...state.settings, ...response.data });
      } catch (e) {
        if (e.response.status !== 404) {
          throw e;
        }
        await dispatch('createSettings', payload);
      }
    },
    saveLocalSettings({ commit, state }, settings) {
      const userId = state.self.id;
      if (!userId) return;
      const userSettings = app.config.globalProperties.$storage.getItem(`user-settings-${userId}`);
      const localSettings = { ...userSettings, ...settings };
      app.config.globalProperties.$storage.setItem(`user-settings-${userId}`, localSettings);
      commit('set_user_settings', { ...state.settings, ...localSettings });
    },
    async updateUser({ dispatch }, user) {
      await app.config.globalProperties.axios.patch(`/users/${user.id}/`, user);
      await dispatch('getSelf');
    },
    async signUp(_, user = {}) {
      const { data = {} } = await app.config.globalProperties.axios.post('users/signup/', {
        ...user,
        app: AUTH_APP,
      });
      app.config.globalProperties.$auth.authenticate(data.jwt_token, data.refresh_token);
      return data;
    },
    async login(_, creds) {
      const {
        data: { refresh_token, jwt_token },
      } = await app.config.globalProperties.axios.post('api-token-auth/', { ...creds, app: AUTH_APP });
      app.config.globalProperties.$auth.authenticate(jwt_token, refresh_token);
    },
    updatePassword(_, creds) {
      return app.config.globalProperties.axios.post('users/reset-pass/', creds);
    },
    resetPassword(_, { uuid, password }) {
      return app.config.globalProperties.axios.post(`users/reset/${uuid}/`, { password });
    },
    requestReset(_, { email, ticketeer }) {
      return app.config.globalProperties.axios.post('users/reset/', { email, ticketeer });
    },
    getUsStates() {
      return app.config.globalProperties.axios.get('choices/us-states/');
    },

    async fetchCurrencies() {
      const response = await app.config.globalProperties.axios.get('choices/currencies/');
      return response.data;
    },
    async fetchPspCurrencySupport() {
      const response = await app.config.globalProperties.axios.get('choices/currencies/psp-support/');
      return response.data;
    },
    async fetchTimezones() {
      const response = await app.config.globalProperties.axios.get('choices/timezones/');
      return response.data.map(([tz]) => tz);
    },
    async fetchLocales() {
      const response = await app.config.globalProperties.axios.get('choices/available-locales/');
      return response.data;
    },
  };
}

export default actions;
