import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  current_shop: null,
  draft_shop: {},
  shops: [],
};

export default (app) => ({
  namespaced: true,
  state,
  actions: actions(app),
  mutations,
  getters,
});
