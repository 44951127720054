const Schedules = () => import('@/views/schedules/index.vue');
const SchedulesOverview = () => import('@/views/schedules/SchedulesOverview.vue');
const ScheduleDetails = () => import('@/views/schedules/ScheduleDetails.vue');

export default {
  path: '/schedules',
  component: Schedules,
  children: [
    {
      path: '',
      name: 'schedules-overview',
      component: SchedulesOverview,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ getters }, _) => getters['Organizations/getPermission']('schedule.read'),
        },
      },
    },
    {
      path: ':id',
      name: 'schedule-details',
      component: ScheduleDetails,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ getters }, _) =>
            getters['Organizations/getPermission']('schedule.read') &&
            getters['Organizations/getPermission']('schedule.write'),
        },
      },
    },
  ],
};
