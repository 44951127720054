import { parseRequestQueryParams } from '@getprotocollab/get-pal';
import helpers from '@/helpers';

function actions(app) {
  return {
    async getShopData({ commit }, id) {
      const shopResponse = await app.config.globalProperties.axios.get(`shops/${id}/`);
      commit('set_current_shop', shopResponse.data);
    },
    async getShops(
      { commit },
      {
        eventSlug = undefined,
        gate = undefined,
        excludeGate = undefined,
        organization = undefined,
        end = undefined,
        limit = undefined,
        page = 1,
        search = undefined,
        skipStore = false,
      } = {},
    ) {
      const offset = page && limit ? limit * (page - 1) : undefined;
      const query = parseRequestQueryParams({
        event_slug: eventSlug,
        gate,
        organization,
        ends__gte: end,
        limit,
        offset,
        exclude_gate: excludeGate,
        search,
      });
      const url = `shops/${query}`;
      if (limit) {
        const response = await app.config.globalProperties.axios.get(url);
        if (!skipStore) {
          commit('set_event_shops', response.data.results);
        }
        return response;
      }

      const shops = await helpers.getAllPages(app.config.globalProperties.axios, url);
      if (!skipStore) {
        commit('set_event_shops', shops);
      }
      return shops;
    },
    async deleteShop({ commit }, id) {
      await app.config.globalProperties.axios.delete(`shops/${id}/`);
      commit('clear_current_shop');
    },
    async createShop({ commit }, shop) {
      const shopResponse = await app.config.globalProperties.axios.post('shops/', shop);
      commit('set_current_shop', shopResponse.data);
      return shopResponse;
    },
    async copyShop(_, { shopId }) {
      const response = await app.config.globalProperties.axios.post(`shops/${shopId}/copy/`, {});
      return response;
    },
    async updateCurrentShop({ commit }, shop) {
      const response = await app.config.globalProperties.axios.patch(`/shops/${shop.id}/`, shop);
      commit('set_current_shop', response.data);
      return response;
    },
  };
}

export default actions;
