export default {
  // SETUP
  set_seating(state, seating) {
    state.seating = seating;
  },
  set_loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  set_mode(state, mode) {
    state.mode = mode;
  },
  reset_draftSeating(state) {
    state.draftSeating.blocked = [];
    state.draftSeating.unblocked = [];
    state.draftSeating.categorised = [];
    state.draftSeating.pendingAssignments = [];
    state.draftSeating.category = {};
  },

  store_draftSeating_pTypes(state, initialPTypes) {
    state.draftSeating.pTypes = initialPTypes;
  },
  store_category(state, category) {
    state.draftSeating.category = { ...category };
  },
  set_unblock_mode(state, unblock) {
    state.unblockMode = unblock;
  },
  set_deselect_mode(state, value) {
    state.deselectMode = value;
  },

  // BLOCK - UNBLOCK - CATEGORY
  draft_block(state, seats) {
    const blockCategory = state.draftSeating.category;
    seats.forEach((seat) => {
      const index = state.draftSeating.unblocked.findIndex((id) => id === seat.id);
      const catIndex = state.draftSeating.categorised.findIndex((s) => s.seatId === seat.id);

      if (index !== -1) {
        state.draftSeating.unblocked.splice(index, 1);
        seat.inDraft = false;
      } else {
        state.draftSeating.blocked.push(seat.id);
        seat.inDraft = true;
      }

      const seatCategory = seat.initCategory || {};
      const seatCat = { seatId: seat.id, catId: blockCategory.id };

      if (catIndex === -1 && seatCategory.id !== blockCategory.id) {
        state.draftSeating.categorised.push({ ...seatCat });
      } else if (seatCategory.id === blockCategory.id) {
        state.draftSeating.categorised.splice(catIndex, 1);
      } else {
        state.draftSeating.categorised.splice(catIndex, 1, { ...seatCat });
      }

      state.draftSeating.pTypes[seat.rank_ptype_id].blocksChange += 1;

      seat.blocked = true;
      seat.block_category = { ...blockCategory };
    });
  },

  draft_unblock(state, seats) {
    seats.forEach((seat) => {
      const index = state.draftSeating.blocked.findIndex((id) => id === seat.id);
      const catIndex = state.draftSeating.categorised.findIndex((s) => s.seatId === seat.id);
      if (index !== -1) {
        state.draftSeating.blocked.splice(index, 1);
        seat.inDraft = false;
      } else {
        state.draftSeating.unblocked.push(seat.id);
        seat.inDraft = true;
      }

      if (catIndex === -1 && seat.initCategory !== null) {
        state.draftSeating.categorised.push({ seatId: seat.id, catId: null });
      } else if (seat.initCategory !== null) {
        state.draftSeating.categorised.splice(catIndex, 1);
      }

      state.draftSeating.pTypes[seat.rank_ptype_id].blocksChange -= 1;
      seat.blocked = false;
      seat.block_category = null;
    });
  },

  // ASSIGN - UNASSIGN
  initialize_assign(state, assignments) {
    state.draftSeating.pendingAssignments = assignments;
  },
  assignSeat(state, seat) {
    const pendingAssignment = state.draftSeating.pendingAssignments.find(
      (a) => a.typeId === seat.rank_ptype_id && a.newSeatId === null,
    );
    if (!pendingAssignment) {
      return;
    }
    pendingAssignment.newSeatId = seat.id;
    seat.user_id = pendingAssignment.userId;
    seat.inDraft = pendingAssignment.newSeatId !== pendingAssignment.seatId;
  },
  unassign_seat(state, seat) {
    const pendingAssignment = state.draftSeating.pendingAssignments.find(
      (a) => a.typeId === seat.rank_ptype_id && a.newSeatId === seat.id,
    );
    if (!pendingAssignment) {
      return;
    }
    pendingAssignment.newSeatId = null;
    seat.inDraft = seat.id === pendingAssignment.seatId;
    seat.user_id = null;
    seat.blocked = false;
  },
};
