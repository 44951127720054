import { v4 as uuidv4 } from 'uuid';
import { generateUUID } from '@/helpers';

function actions(app) {
  return {
    async getCashierCart({ commit }, slug) {
      let uuid;
      const uuidFromStorage = app.config.globalProperties.$storage.getItem('cashier_cart_uuid');
      if (uuidFromStorage) {
        uuid = uuidFromStorage;
      } else {
        uuid = uuidv4();
        app.config.globalProperties.$storage.setItem('cashier_cart_uuid', uuid);
      }
      const response = await app.config.globalProperties.axios.get(`cashier/cart/${slug}/${uuid}`);
      commit('setCashierCart', { uuid, ...response.data.cart });
    },

    async updateCashierCart({ state, commit }, { slug, ticketKindsList, shopId }) {
      const tickets = ticketKindsList.map((ticket) => {
        const ticketScheme = {
          key: ticket.key,
          ticketkind: ticket.ticketkind,
          amount: ticket.amount,
          pool: ticket.pool,
          discount: ticket.discount || undefined,
          block_category: ticket.block_category || undefined,
          seats: ticket.seats,
        };
        return ticketScheme;
      });
      const payload = {
        shop_id: shopId,
        event_slug: slug,
        tickets,
      };
      const response = await app.config.globalProperties.axios.put(`cashier/cart/${slug}/${state.cart.uuid}/`, payload);
      commit('updateCart', response.data.cart);
    },
    async createCashierOrder({ state, commit }, slug) {
      const username = state.issueInfo.username ? state.issueInfo.username : `+${generateUUID()}`;

      const payload = {
        event_slug: slug,
        payment_method: state.issueInfo.paymentMethod,
        email: state.issueInfo.email,
        issue_digital: state.issueInfo.issueDigitally,
        username,
        send_email: state.issueInfo.sendEmail,
        send_sms: state.issueInfo.sendSms,
        first_name: state.issueInfo.firstName,
        last_name: state.issueInfo.lastName,
      };

      const { data } = await app.config.globalProperties.axios.post(
        `cashier/cart/${slug}/${state.cart.uuid}/checkout/`,
        payload,
      );
      commit('setCashierOrder', { ...data });
      return data;
    },
    async deleteCashierCart({ state }, slug) {
      await app.config.globalProperties.axios.delete(`cashier/cart/${slug}/${state.cart.uuid}`);
    },
  };
}

export default actions;
