import clone from 'lodash/clone';

export default {
  store_draft_ticketkinds(state, data) {
    state.draft_tickets = clone(data);
  },
  store_current_ticketkinds(state, data) {
    state.current_tkinds = data;
  },
  set_ticketkind(state, ticket) {
    state.current_tkinds = state.current_tkinds.map((tk) => {
      if (tk.id !== ticket.id) {
        return tk;
      }
      return ticket;
    });
  },
  clear_current_ticketkinds(state) {
    state.current_tkinds = [];
  },
  store_global_prices(state, data) {
    state.global_prices = data;
  },
};
