import mutations from './mutations';

const state = {
  pending_tasks: {},
};

export default {
  namespaced: true,
  state,
  mutations,
};
