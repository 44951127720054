import { parseRequestQueryParams } from '@getprotocollab/get-pal';
import { pageLimit } from '@/helpers/constants';

function actions(app) {
  return {
    async getPayments(_, { orderId, page = 1, ordering = 'created' }) {
      const offset = pageLimit * (page - 1);
      const query = parseRequestQueryParams({
        order_id: orderId,
        offset,
        limit: pageLimit,
        ordering,
      });
      const { data: payments = {} } = await app.config.globalProperties.axios.get(`psp/payments/${query}`);
      return payments;
    },

    async getPaymentDetails(_, { paymentId }) {
      const { data: payment = {} } = await app.config.globalProperties.axios.get(`psp/payments/${paymentId}/`);
      return payment;
    },
  };
}

export default actions;
