import isEqual from 'lodash/isEqual';

import clone from 'lodash/clone';

function editModule(app) {
  return {
    namespaced: true,
    state: {
      edit_event: null,
    },
    mutations: {
      set_edit_event(state, event) {
        state.edit_event = event;
      },
      clear_edit_event(state) {
        state.edit_event = null;
      },
    },
    actions: {
      initializeEditEvent(store, event) {
        return new Promise((resolve, _) => {
          const eventClone = clone(event);
          store.commit('set_edit_event', eventClone);
          resolve();
        });
      },
      async uploadToS3(_, { eventId, field, image }) {
        const imageData = { filename: image.name, field };
        const response = await app.config.globalProperties.axios.post(`/events/${eventId}/request_upload/`, imageData);
        const s3Object = new FormData();

        Object.keys(response.data.fields).forEach((k) => {
          s3Object.append(k, response.data.fields[k]);
        });
        s3Object.append('Content-Type', image.type);
        s3Object.append('file', image);
        await app.config.globalProperties.axios.post(response.data.url, s3Object, {
          headers: { Authorization: '' },
        });
        return { response, field };
      },
    },
    getters: {
      isEventNotEditted: (state, getters, rootState) => () => {
        if (!state.edit_event || !rootState.Events.current_event) {
          return true;
        }
        return isEqual(state.edit_event, rootState.Events.current_event);
      },
    },
  };
}

export default editModule;
