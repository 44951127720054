<template>
  <GFormGroup
    labelFor="name"
    :label="label"
    :state="hasError ? false : null"
    :invalid-feedback="
      validationErrorKeyToString(validator, {
        field: $tc('views.account.passwords.password', 2),
        length: MIN_PASSWORD_LENGTH,
      })
    "
    data-test-password
  >
    <GInputGroup :size="size">
      <GInput
        :ref="name"
        :placeholder="placeholder"
        class="password-input"
        :name="name"
        :type="show ? 'text' : 'password'"
        :state="hasError ? false : null"
        :value="value"
        :autocomplete="autocomplete"
        :tabindex="tabindex"
        @input="handleInput"
      />

      <template v-slot:append>
        <button type="button" class="password-show" @mousedown="show = true" @mouseup="show = false">
          <Icon :type="show ? 'hidden' : 'view'" size="24px" />
        </button>
      </template>
    </GInputGroup>

    <p v-if="weakPass" class="help text-danger" data-test-weak-password>
      {{ $t('views.account.passwords.apiErrors.weak_password') }}
    </p>
    <div v-if="!hasError && showInfo" class="password-info" data-test-password-info>
      <p class="help text-secondary">
        {{ $t('views.account.passwords.passwordInfo.title') }}
      </p>
      <p class="help text-secondary">- {{ $t('views.account.passwords.passwordInfo.minLength') }}</p>
      <p class="help text-secondary">- {{ $t('views.account.passwords.passwordInfo.similar') }}</p>
    </div>
  </GFormGroup>
</template>

<script lang="ts">
  import { validationErrorKeyToString } from '@/helpers';
  import { MIN_PASSWORD_LENGTH } from '@/helpers/constants';
  import Icon from '@/components/Icon.vue';
  import { defineComponent } from 'vue';

  export default defineComponent({
    components: {
      Icon,
    },
    props: {
      name: { type: String, required: true },
      value: { type: String, required: true },
      placeholder: { type: String, required: true },
      label: { type: String, default: '' },
      validator: { type: Object, required: true },
      weakPass: { type: Boolean, default: false },
      showInfo: { type: Boolean, default: false },
      size: { type: String as () => 'sm' | 'md' | 'lg' | undefined, default: '' },
      autocomplete: { type: String, default: 'off' },
      tabindex: { type: String, default: undefined },
    },
    emits: ['input'],
    data(): {
      show: boolean;
      MIN_PASSWORD_LENGTH: number;
    } {
      return {
        show: false,
        MIN_PASSWORD_LENGTH,
      };
    },
    computed: {
      hasError(): boolean {
        return this.validator.$error || this.weakPass;
      },
    },
    methods: {
      validationErrorKeyToString,
      handleInput(value: string): void {
        this.$emit('input', value);
      },
    },
  });
</script>
<style lang="scss" scoped>
  .password-input {
    border-right: none;
  }

  .password-show {
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    height: full;
    width: 3rem;
    display: grid;
    place-content: center;
    border: 1px solid $color-gray-300;
    border-left: none;
    background: $color-white;
  }

  .password-info {
    padding: 0.5rem;
    line-height: 1.2;
  }
</style>
