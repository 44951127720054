import { parseRequestQueryParams } from '@getprotocollab/get-pal';
import helpers from '@/helpers';
import { pageLimit } from '@/helpers/constants';

function actions(app) {
  return {
    async getAllOrganizations({ commit }) {
      const orgs = await helpers.getAllPages(app.config.globalProperties.axios, 'organizations/');
      const sortedOrganizations = helpers.sortByName(orgs);
      commit('store_organizations', sortedOrganizations);
      return orgs;
    },
    async getTicketeers({ commit }) {
      const ticketeers = await helpers.getAllPages(app.config.globalProperties.axios, 'ticketeers/');
      commit('set_ticketeers', ticketeers);
    },
    async createOrganization({ commit }, organization) {
      const response = await app.config.globalProperties.axios.post('organizations/', organization);
      commit('set_current_organization', response.data);
      return response;
    },
    async getOrganization({ commit }, { id }) {
      const response = await app.config.globalProperties.axios.get(`organizations/${id}/`);
      commit('set_current_organization', response.data);
      return response;
    },
    async getEventOrganization({ commit }, { id }) {
      const response = await app.config.globalProperties.axios.get(`organizations/${id}/`);
      commit('set_current_event_organization', response.data);
      return response;
    },
    async updateOrganization({ commit }, organization) {
      const response = await app.config.globalProperties.axios.patch(`organizations/${organization.id}/`, organization);
      commit('set_current_organization', response.data);
      return response;
    },
    saveLastSeenOrgs({ commit, dispatch }, payload) {
      const {
        userId,
        organization: { id, name },
      } = payload;
      const userPreferences = app.config.globalProperties.$storage.getItem(`user-settings-${userId}`);
      let lastSeenOrganizationList = userPreferences?.lastSeenOrganizationList || [];
      if (!userPreferences) {
        lastSeenOrganizationList = [{ name, id }];
      } else {
        const index = lastSeenOrganizationList.findIndex((org) => id === org.id);
        if (index === -1) {
          lastSeenOrganizationList.unshift({ name, id });
        }
        if (lastSeenOrganizationList.length > 3) lastSeenOrganizationList.pop();
      }
      app.config.globalProperties.$storage.setItem(`user-settings-${userId}`, {
        lastSeenOrganizationList,
      });
      dispatch('Account/saveSettings', { currently_selected_organization: id }, { root: true });
      commit('set_already_seen_orgs', lastSeenOrganizationList);
    },
    async initLastSeenOrganization({ dispatch, commit, rootState }, userId) {
      const userPreferences = app.config.globalProperties.$storage.getItem(`user-settings-${userId}`);
      const lastSeenOrganization = rootState.Account.settings.currently_selected_organization;
      const lastSeenOrganizationList = userPreferences?.lastSeenOrganizationList;
      if (lastSeenOrganizationList) {
        commit('set_already_seen_orgs', lastSeenOrganizationList);
      }
      if (lastSeenOrganization) {
        await dispatch('getOrganization', { id: lastSeenOrganization });
      }
    },
    async uploadToS3(store, { orgaId, field, file }) {
      const fileData = { filename: file.name, field };
      const response = await app.config.globalProperties.axios.post(
        `/organizations/${orgaId}/request_upload/`,
        fileData,
      );
      const s3Object = new FormData();

      Object.keys(response.data.fields).forEach((k) => {
        s3Object.append(k, response.data.fields[k]);
      });
      s3Object.append('Content-Type', file.type);
      s3Object.append('file', file);
      await app.config.globalProperties.axios.post(response.data.url, s3Object, {
        headers: { Authorization: '' },
      });
      return { response, field };
    },
    async getOrganizations(
      { _commit },
      {
        global = true,
        name = undefined,
        users = undefined,
        psp = undefined,
        currency = undefined,
        search = undefined,
        page = 1,
        ordering = undefined,
      } = {},
    ) {
      const offset = (page - 1) * pageLimit;
      const query = parseRequestQueryParams({
        name,
        users,
        currency,
        psp,
        is_global: global,
        search,
        limit: pageLimit,
        offset,
        ordering,
      });
      const { data } = await app.config.globalProperties.axios.get(`organizations/${query}`);
      return data;
    },

    fetchPspMethods(_, { id } = {}) {
      return app.config.globalProperties.axios.get(`organizations/${id}/psp-methods/`);
    },
    fetchPspChoices(_, { id } = {}) {
      return app.config.globalProperties.axios.get(`organizations/${id}/psp-choices/`);
    },

    setApiKey(_, { id, key, publicKey } = {}) {
      return app.config.globalProperties.axios.post(`organizations/${id}/set-api-key/`, {
        api_key: key,
        public_api_key: publicKey,
      });
    },
  };
}

export default actions;
