const PaymentDetail = () => import('@/views/events/event/eventCustomers/eventCustomer/orders/order/Payment.vue');

const meta = {
  auth: true,
  org: true,
  permissions: {
    access: ({ state, getters }, _) => {
      const { current_event } = state.Events;
      return current_event && !current_event.is_template && getters['Events/getPermission']('attendee.read');
    },
    redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
  },
};

export default {
  path: 'orders/:orderId/payments/:paymentId',
  name: 'event-customer-order-payment',
  component: PaymentDetail,
  meta,
};
