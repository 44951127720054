import actions from './actions';
import mutations from './mutations';

const state = {
  attendees_loading: false,
  current_blocks_count: null,
  current_attendee: null,
  blocked_privileges: [],
};

export default (app) => ({
  namespaced: true,
  state,
  actions: actions(app),
  mutations,
});
