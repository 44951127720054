const EventManage = () => import('@/views/events/event/manage/index.vue');
const EventGeneral = () => import('@/views/events/event/manage/General.vue');
const EventMarketing = () => import('@/views/events/event/manage/Marketing.vue');
const EventAdvanced = () => import('@/views/events/event/manage/Advanced.vue');
const EventBlockchain = () => import('@/views/events/event/manage/Blockchain.vue');
const EventResale = () => import('@/views/events/event/manage/Resale.vue');
const EventLivestream = () => import('@/views/events/event/manage/Livestream.vue');

export default {
  path: 'manage',
  component: EventManage,
  children: [
    {
      path: '',
      name: 'general',
      component: EventGeneral,
      meta: {
        layout: 'Slim',
        auth: true,
        org: true,
      },
    },
    {
      path: 'livestream',
      name: 'livestream',
      component: EventLivestream,
      meta: {
        layout: 'Slim',
        auth: true,
        org: true,
      },
    },
    {
      path: 'marketing',
      name: 'event-marketing',
      component: EventMarketing,
      meta: {
        layout: 'Slim',
        auth: true,
        org: true,
      },
    },
    {
      path: 'advanced',
      name: 'event-advanced',
      component: EventAdvanced,
      meta: {
        layout: 'Slim',
        auth: true,
        org: true,
      },
    },
    {
      path: 'blockchain',
      name: 'event-blockchain',
      component: EventBlockchain,
      meta: {
        layout: 'Slim',
        auth: true,
        org: true,
      },
    },
    {
      path: 'resale',
      name: 'event-resale',
      component: EventResale,
      meta: {
        layout: 'Slim',
        auth: true,
        org: true,
        permissions: {
          access: ({ getters }) => {
            const organization = getters['Organizations/currentEventOrganization'];
            const permission = getters['Events/getPermission']('feature.flag');
            return organization?.secondary_market === 'stripe_split_market' && permission;
          },
          redirect: (_, to) => ({ name: 'event-notFound', params: { 0: to.fullPath } }),
        },
      },
    },
  ],
};
