<template>
  <div class="g-form-box-wrapper signup-box">
    <div class="signup-header">
      <h2 class="signup-title">
        {{ $t('views.account.signup.title') }}
      </h2>
    </div>
    <GForm class="form" @submit.prevent="trySignUp">
      <div class="name-inputs">
        <GFormGroup
          labelFor="first-name"
          :label="$t('labels.profile.firstName')"
          class="first-name-input"
          :state="v$.firstName.$error ? false : null"
          :invalid-feedback="validationErrorKeyToString(v$.firstName, { max: 64 }) || 'labels.en'"
          size="lg"
          data-test-first-name
        >
          <GInput
            ref="first-name"
            v-model="firstName"
            name="first-name"
            size="lg"
            :state="v$.firstName.$error ? false : null"
          />
        </GFormGroup>

        <GFormGroup
          labelFor="last-name"
          class="last-name-input"
          :label="$t('labels.profile.lastName')"
          :state="v$.lastName.$error ? false : null"
          :invalid-feedback="validationErrorKeyToString(v$.lastName, { max: 64 }) || 'labels.en'"
          size="lg"
          data-test-last-name
        >
          <GInput
            ref="last-name"
            v-model="lastName"
            name="last-name"
            size="lg"
            :state="v$.lastName.$error ? false : null"
          />
        </GFormGroup>
      </div>

      <GFormGroup
        labelFor="email"
        :label="$t('labels.profile.email')"
        :state="emailError ? false : null"
        :invalid-feedback="validationErrorKeyToString(v$.email, { max: 64 }) || 'labels.en'"
        size="lg"
        data-test-email
      >
        <GInput
          ref="email"
          v-model="email"
          name="email"
          :placeholder="$t('placeholders.email')"
          size="lg"
          :state="emailError ? false : null"
        />
        <p v-if="accountExists" class="help text-danger" data-test-exists-error>
          {{ $t('views.account.signup.apiErrors.user_already_exists') }}
        </p>
      </GFormGroup>

      <PasswordInput
        :label="$tc('views.account.passwords.password')"
        :placeholder="$tc('views.account.passwords.password')"
        :validator="v$.password"
        :value="password"
        :weakPass="weakPassword"
        size="lg"
        name="password"
        @input="handlePasswordInput"
      />

      <PasswordInput
        :label="$tc('views.account.passwords.confirmPass')"
        :placeholder="$tc('views.account.passwords.password')"
        :validator="v$.confirmPass"
        :value="confirmPass"
        :showInfo="true"
        size="lg"
        name="confirm-password"
        @input="(val) => (confirmPass = val)"
      />

      <div class="signup-footer">
        <p class="has-account-text">
          {{ $t('views.account.signup.hasAccount') }}
          <router-link class="login-link" :to="{ name: 'login', query: { ...$route.query } }">
            {{ $t('actions.login') }}
          </router-link>
        </p>
        <GButton type="submit" size="lg" variant="primary" :disabled="isLoading || v$.$error" @submit="trySignUp">
          {{ $t('actions.signup') }}
        </GButton>
      </div>
    </GForm>
  </div>
</template>

<script lang="ts">
  import { useVuelidate } from '@vuelidate/core';
  import { required, maxLength, sameAs, minLength, not, numeric, email } from '@vuelidate/validators';
  import { validationErrorKeyToString } from '@/helpers';
  import { API_ERRORS, MIN_PASSWORD_LENGTH } from '@/helpers/constants';
  import PasswordInput from '@/components/PasswordInput.vue';
  import { defineComponent, inject } from 'vue';
  import { toasterKey } from '@getprotocollab/get-prettui';

  type Data = {
    isLoading: boolean;
    firstName: string;
    lastName: string;
    email: string;
    accountExists: boolean;
    password: string;
    weakPassword: boolean;
    confirmPass: string;
    invite: string | null;
    minPassLength: number;
  };

  export default defineComponent({
    components: {
      PasswordInput,
    },
    beforeRouteEnter(to, from, next) {
      if (!to.query?.invite) {
        next({ name: 'login' });
      }
      next();
    },
    setup() {
      const toaster = inject(toasterKey, {
        addToast: () => {},
      });
      return { v$: useVuelidate(), toaster };
    },
    data(): Data {
      return {
        isLoading: false,
        firstName: '',
        lastName: '',
        email: '',
        accountExists: false,
        password: '',
        weakPassword: false,
        confirmPass: '',
        invite: null,
        minPassLength: MIN_PASSWORD_LENGTH,
      };
    },
    validations() {
      return {
        email: {
          maxLength: maxLength(64),
          email,
          required,
        },
        password: {
          required,
          minLength: minLength(MIN_PASSWORD_LENGTH),
          nonNumeric: not(numeric),
        },
        confirmPass: {
          sameAs: sameAs(this.password),
          required,
        },
        firstName: {
          required,
          maxLength: maxLength(64),
        },
        lastName: {
          required,
          maxLength: maxLength(64),
        },
      };
    },
    computed: {
      emailError() {
        return this.v$.email.$error || this.accountExists;
      },
      passwordError() {
        return this.v$.password.$error || this.weakPassword;
      },
    },
    created() {
      this.initInvite(this.$route.query);
    },
    methods: {
      validationErrorKeyToString,
      handlePasswordInput(val) {
        this.password = val;
        this.weakPassword = false;
      },
      async trySignUp() {
        this.v$.$touch();
        if (this.v$.$error) return;
        this.isLoading = true;
        try {
          const user = await this.$store.dispatch('Account/signUp', {
            email: this.email,
            password: this.password,
            invitation: this.invite,
          });

          await this.$store.dispatch('Account/updateUser', {
            ...user,
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
          });
        } catch (e) {
          const { error } = e.response?.data || {};
          if (error) {
            this.accountExists = error === API_ERRORS.USER_ALREADY_EXISTS;
            this.weakPassword = error === API_ERRORS.WEAK_PASSWORD;
            return;
          }
          this.toaster?.addToast({
            message: this.$t('notifications.genericError')?.toString(),
            variant: 'danger',
            title: this.$t('labels.danger').toString(),
            dismissible: true,
          });
          throw e;
        } finally {
          this.isLoading = false;
        }
        if (this.invite) {
          this.$router.push({
            name: 'invitations',
            params: {
              uuid: this.invite,
              newAccount: true,
            },
          });
        } else {
          this.$router.push({ name: 'upcoming' });
        }
      },
      initInvite({ invite, email: mail }) {
        this.invite = invite;
        this.email = mail;
      },
    },
  });
</script>

<style lang="scss" scoped>
  .signup-box {
    width: 100%;
    max-width: 26rem;
  }

  .name-inputs {
    display: flex;
  }

  .first-name-input {
    margin-right: 1rem;
    flex: 1;
  }

  .last-name-input {
    flex: 1;
  }

  .signup-header {
    padding: 1rem;
    background: $color-gray-100;
    border-bottom: 1px solid $color-border;
  }

  .signup-title {
    margin: 0;
    font-size: $size-large;
  }

  .signup-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
  }

  .form {
    padding: 1rem;
  }

  .has-account-text {
    color: $color-primary;
  }

  .login-link {
    text-decoration: underline;
    font-weight: $weight-semibold;
  }
</style>
