import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import Edit from './editModule';

const state = {
  current_event_sales: {},
  current_event_ptypes: [],
  current_tickets_sales: {},
  current_event_checkins: [],
  current_event: null,
  current_event_tiers: [],
};

export default (app) => ({
  namespaced: true,
  state() {
    return { ...state };
  },
  actions: actions(app),
  mutations,
  getters,
  modules: {
    Edit: Edit(app),
  },
});
