const GatesOverview = () => import('@/views/gates/GatesOverview.vue');
const Gates = () => import('@/views/gates/index.vue');
const GateManagement = () => import('@/views/gates/GateManagement.vue');

export default {
  path: '/gates',
  component: Gates,
  children: [
    {
      path: '',
      name: 'gates-overview',
      component: GatesOverview,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ getters }, _) => getters['Organizations/getPermission']('gate.write'),
        },
      },
    },
    {
      name: 'gate-management',
      path: ':id',
      component: GateManagement,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: ({ getters }, _) => getters['Organizations/getPermission']('gate.write'),
        },
      },
    },
  ],
};
