<template>
  <GToaster>
    <div>
      <TaskToast v-for="task in tasks" :id="`task-toast-${task.uuid}`" :key="task.uuid" :task="task" />

      <EnvironmentBar v-if="barEnv !== 'production'" :env="barEnv" />

      <component :is="layout">
        <router-view />
      </component>
    </div>
  </GToaster>
</template>

<script lang="ts">
  import { defineAsyncComponent, defineComponent } from 'vue';
  import { mapState } from 'vuex';

  const Default = defineAsyncComponent(() => import('@/layouts/Default.vue'));
  const Empty = defineAsyncComponent(() => import('@/layouts/Empty.vue'));
  const Full = defineAsyncComponent(() => import('@/layouts/Full.vue'));
  const Slim = defineAsyncComponent(() => import('@/layouts/Slim.vue'));
  const TaskToast = defineAsyncComponent(() => import('@/components/notifications/TaskToast.vue'));
  const EnvironmentBar = defineAsyncComponent(() => import('@/components/EnvironmentBar.vue'));

  export default defineComponent({
    name: 'App',
    components: {
      Default,
      Empty,
      Full,
      Slim,
      TaskToast,
      EnvironmentBar,
    },
    computed: {
      ...mapState({
        tasks: (state: any) => Object.values(state.Notifications.pending_tasks) as any[],
      }),
      layout() {
        const layout = this.$route.meta.layout ? this.$route.meta.layout : 'Default';
        switch (layout) {
          case 'Default':
            return Default;
          case 'Empty':
            return Empty;
          case 'Full':
            return Full;
          case 'Slim':
            return Slim;
          default:
            return Default;
        }
      },
      barEnv() {
        return process.env.VITE_APP_ENV;
      },
    },
  });
</script>

<style lang="scss" scoped>
  #app {
    width: 100%;
    height: 100%;
    min-height: 100%;
  }
</style>
